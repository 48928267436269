<template>
    <div class="w-100 h-100">
        <div class="mainbox">
            <div class="acco flex">
                <img src="@/assets/img/sfenz.png" alt="" srcset="">
                <span>实名认证</span>
            </div>
            <div class="reg">
                <span>根据《网络安全法》的要求，部分服务需要实名认证之后才可以使用。融合CDN依法保护用户信息，请查阅<a href="https://upload.znidc.net/files/ysxy.html"
                        target="_blank">《隐私政策》</a>
                    如果你在认证过程中遇到任何问题，可以随时联系我们。</span>

            </div>
            <div>
                <span class="jb">{{ userInfo.isReal == 0 ? '开始认证' : '已认证' }}</span>
                <el-divider></el-divider>
                <div>
                    <div class="flex essen">
                        <label class="labe">请选择认证类型</label>
                        <div class=" usid">
                            <el-button :class="peri ? 'pers' : ''" @click="peri = true"
                                v-if="userInfo.isReal == 1 && userInfo.type == 0 || userInfo.isReal == 0">
                                <div class="flex">
                                    <img src="@/assets/img/ugrzx.png" alt="" srcset="" class="btnic">
                                    <span class="gr">个人认证</span>

                                </div>
                            </el-button>
                            <el-button :class="!peri ? 'pers' : ''" @click="peri = false">
                                <div class="flex">
                                    <img src="@/assets/img/qyrz.png" alt="" srcset="" class="btnic">
                                    <span class="gr">企业或组织机构认证</span>
                                </div>
                            </el-button>
                            <div class="font-12 color-999 mg-tp-10">
                                <label>个人认证完成后还可以变更为企业认证，企业认证完成后不能变更。</label>
                            </div>
                        </div>

                    </div>
                    <div v-if="peri">
                        <div class="flex essen">
                            <div class="flex">
                                <label class="labe">姓名</label>
                                <el-input v-model="peicate.name" placeholder="请输入姓名" v-if="userInfo.isReal == 0"></el-input>
                                <span v-else>{{ userInfo.nickname }}</span>
                            </div>
                        </div>
                        <div class="flex essen">
                            <div class="flex">
                                <label class="labe">手机号码</label>
                                <el-input v-model="peicate.mobile" placeholder="请输入手机号码" @change="mobiChange"
                                    v-if="userInfo.isReal == 0"></el-input>
                                <span v-else>{{ userInfo.mobile }}</span>
                            </div>
                        </div>
                        <div class="flex essen">
                            <div class="flex">
                                <label class="labe">身份证号码</label>
                                <el-input v-model="peicate.idCard" placeholder="请输入身份证号码"
                                    v-if="userInfo.isReal == 0"></el-input>
                                <span v-else>{{ userInfo.idCard }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!peri">
                        <div class="flex essen">
                            <div class="flex" v-if="userInfo.enterpriseName">
                                <label class="labe">企业名称</label>
                                <span >{{ userInfo.enterpriseName }}</span>
                            </div>
                        </div>
                        <div class="flex essen" v-if="userInfo.enterpriseName">
                            <div class="flex">
                                <label class="labe">法人</label>
                                <span>{{ userInfo.legalPerson }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex essen">
                        <label class="labe">请选择认证方式</label>
                        <!-- //个人认证 -->
                        <div class=" usid" v-if="peri">
                            <el-button :class="peri ? 'pers' : ''">
                                <div class="flex">
                                    <img src="@/assets/img/sfenz.png" alt="" srcset="" class="sfzimg">
                                    <span class="gr">身份证人工审核</span>
                                </div>
                            </el-button>
                            <div class="font-12 color-999 mg-tp-10">

                                <label>- 身份证人工审核：上传手持身份证正反面的照片，等待我们进行人工审核。</label>
                            </div>
                            <div class="flex">
                                <div class="uplod">
                                    <el-upload class="avatar-uploader" action="https://admin.znidc.net/wxapi/upload/file"
                                        :show-file-list="false" :on-success="uploadFileFront" accept=".jpg,.png">
                                        <img v-if="peicate.FrontidCardImg" :src="peicate.FrontidCardImg" class="avatar">
                                        <div v-else class="uplbox">
                                            <div class="uplhnimg">
                                                <img src="@/assets/img/upsc.png" alt="" srcset="">
                                            </div>

                                        </div>
                                        <p class="fron">身份证正面</p>
                                    </el-upload>

                                </div>
                                <div class="uplod">
                                    <el-upload class="avatar-uploader" action="https://admin.znidc.net/wxapi/upload/file"
                                        :show-file-list="false" :on-success="uploadFileRevard" accept=".jpg,.png">
                                        <img v-if="peicate.idCardImg" :src="peicate.idCardImg" class="avatar">
                                        <div v-else class="uplbox">
                                            <div class="uplhnimg">
                                                <img src="@/assets/img/upsc.png" alt="" srcset="">
                                            </div>

                                        </div>
                                        <p class="fron">身份证反面</p>
                                    </el-upload>

                                </div>

                            </div>

                            <div class="Btn">
                                <el-button type="primary" :disabled="userInfo.isReal == 1 && userInfo.type == 0"
                                    @click="phoneVerification">提交</el-button>
                            </div>
                        </div>
                        <!-- //企业认证 -->
                        <div class=" usid" v-if="!peri">
                            <el-button :class="!peri ? 'pers' : ''">
                                <div class="flex">
                                    <img src="@/assets/img/yyzz.png" alt="" srcset="" class="btnic">
                                    <span class="gr">营业执照人工审核</span>
                                </div>
                            </el-button>
                            <div class="font-12 color-999 mg-tp-10">

                                <label>- 营业执照人工审核：上传营业执照，等待我们进行人工审核。</label>
                            </div>
                            <div class="flex">
                                <div class="uplod">
                                    <el-upload class="avatar-uploader" action="https://admin.znidc.net/wxapi/upload/file"
                                        :show-file-list="false" :on-success="uploadFilebizLicense" accept=".jpg,.png" :disabled="userInfo.isReal == 1 && userInfo.type == 1">
                                        <img v-if="enterprise.bizLicense" :src="enterprise.bizLicense" class="avatar">
                                        <div v-else class="uplbox">
                                            <div class="uplhnimg">
                                                <img src="@/assets/img/upsc.png" alt="" srcset="">
                                            </div>

                                        </div>
                                        <p class="fron">营业执照</p>
                                    </el-upload>

                                </div>
                            </div>

                            <div class="Btn">
                                <el-button type="primary" :disabled="userInfo.isReal == 1 && userInfo.type == 1"
                                    @click="bizLicenseOCR">提交</el-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
  
<script>
export default {

    props: {
        msg: String
    },
    data() {
        return {
            //企业认证对象
            enterprise: {
                bizLicense: "",
            },

            //个人认证对象
            peicate: {
                mobile: "",
                name: "",
                idCard: "",
                idCardImg: "",
                FrontidCardImg: "",
            },
            peri: true,//个人认证还是企业认证
            input: "",
            userInfo: {},
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.member()
        if (this.userInfo.type == 1 && this.userInfo.isReal == 1) {
            this.peri = false
        }
    },
    methods: {
        mobiChange() {
            let regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;// 11位手机
            if (!regs.test(this.peicate.mobile)) {
                this.$message({
                    message: "手机号填写错误",
                    type: "error"
                })
                this.peicate.mobile = ""
            }
        },
        uploadFilebizLicense(files) {
            this.enterprise.bizLicense = files.data
        },
        uploadFileFront(files) {

            this.peicate.FrontidCardImg = files.data
        },
        uploadFileRevard(files) {
            this.peicate.idCardImg = files.data
        },
        //重新获取用户信息
        member() {
            this.$apis.member({
                mid: this.userInfo.id
            }).then((res) => {
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                this.userInfo = res.data
                if (res.data.isReal == 1 && res.data.type == 0) {
                    this.peicate.FrontidCardImg = res.data.checkImg.split(',')[0]
                    this.peicate.idCardImg = res.data.checkImg.split(',')[1]
                } else if (res.data.isReal == 1 && res.data.type == 1) {
                    this.enterprise.bizLicense = res.data.checkImg
                }
            })
        },
        //企业认证
        bizLicenseOCR() {
            if (this.enterprise.bizLicense) {
                this.$apis.bizLicenseOCR({
                    mid: this.userInfo.id,
                    bizLicense: this.enterprise.bizLicense
                }).then((res) => {
                  
                    if (res.code == 0) {
                        this.member()
                        this.$message({
                            message: "企业认证成功",
                            type: "success"
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error"
                        })
                    }

                })
            } else {
                this.$message({
                    message: "请填写完整",
                    type: "success"
                })
            }

        },
        phoneVerification() {
            let idCarImg = []
            idCarImg.push(this.peicate.FrontidCardImg, this.peicate.idCardImg)

            if (!this.peicate.name) {
                this.$message({
                    message: "姓名为空",
                    type: "error"
                })
                return
            }
            if (!this.peicate.mobile) {
                this.$message({
                    message: "手机号为空",
                    type: "error"
                })
                return
            }

            if (!this.peicate.idCard) {
                this.$message({
                    message: "身份证号为空",
                    type: "error"
                })
                return
            }
            if (idCarImg.length < 2) {
                this.$message({
                    message: "身份证照片错误",
                    type: "error"
                })
                return
            }
            this.$apis.phoneVerification({
                mid: this.userInfo.id,
                mobile: this.peicate.mobile,
                name: this.peicate.name,
                idCard: this.peicate.idCard,
                idCardImg: idCarImg.join(',')
            }).then((res) => {
                if (res.code == 0) {
                    this.member()
                    this.$message({
                        message: "个人认证成功",
                        type: "success"
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error"
                    })
                }

            })


        },
        handleAvatarSuccess() {

        },
        beforeAvatarUpload() {

        },
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
    text-decoration: none;
}

.pers {
    background: url('../../../assets/img/xzl.png') no-repeat right top;
    background-size: 20px 20px;
    border-color: #007bff !important;
}

.fron {

    color: rgba(16, 16, 16, 1) !important;
    font-size: 14px !important;
    text-align: center;
}

.Btn {
    text-align: right;

    /deep/.el-button--primary {

        width: 138px;
        height: 41px;
        border-radius: 4px;
        background-color: rgba(125, 189, 247, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        font-family: Microsoft Yahei;
        border: none;
        margin-top: 114px;
    }
}

/deep/.el-input__inner {
    width: 200px;
    height: 30px;
    margin-left: 48px;
}

.uplod {
    width: 167px;
    height: 100px;
    margin-top: 20px;
    margin-right: 11px;

    .uplbox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .uplhnimg {
            width: 60px;
            height: 60px;
            line-height: 70px;
            background-color: rgba(0, 0, 0, 0.4);
            text-align: center;
            border-radius: 50%;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 167px;
    height: 100px;
        // display: block;
        // background-color: red;
    }

}

/deep/.el-upload {
    background-color: #E8E8E8;
    width: 167px;
    height: 100px;
}

/deep/.el-button--default {
    padding-left: 10px;
    padding-right: 10px;

    .btnic {
        width: 15px;
        height: 15px;
    }

    .sfzimg {
        width: 25px;
        height: 15px;
    }

    .gr {
        font-size: 15px !important;
        margin-left: 5px;
    }
}

p {
    margin: 0;
}

.el-divider {
    margin: 10px 0;
}

.mainbox {
    //  height: 373px !important;
    background-color: #fff;
    padding: 10px 17px 22px 17px;
    margin: 0 0 232px 0;
}

.jb {
    font-size: 15px;
    color: #333333;
}

.essen {
    display: flex;
    align-items: inherit;
    margin-bottom: 20px;

    .labe {
        font-size: 14px;
        color: #666;
        // margin-right: 120px;
        width: 170px;
        margin-top: 10Px;
    }

    .usid {
        span {
            color: rgba(102, 102, 102, 1);
            font-size: 12px;
        }

        p {
            color: #A1A1A1;
            margin-top: 6px;
        }
    }
}

.reg {
    font-size: 12px;
    color: #666;
    margin: 35px 0 50px 0;
    width: 639px;

    a {
        color: #0056b3;
    }
}

.acco {
    img {
        width: 35px;
        height: 20px;
    }

    span {
        margin: 0;
        font-size: 20px;
        margin-left: 12px;
    }
}

// /deep/.el-submenu__title:hover {
//   background-color: #E6F7FF  !important;
//   color: #4BA1FD !important;

// }
.lgo {
    width: 126px;
    height: 28px;
    margin-right: 10px;
}</style>
  