<template>
  <div class="w-100 h-100">

    <div class="recar">
      <div class="reacrmain">
      </div>
    </div>
    <div class="puricate">
      <div class="dawer">
        <div>
          <div>
            <el-form :model="ruleForm" class="eForm">
              <el-form-item label="域名" prop="name">
                <!-- <el-input v-model="ruleForm.name"></el-input> -->
                <small class="charact">{{ MemberDomain.domain }}</small>
              </el-form-item>
              <el-form-item label="CNAME" prop="region">
                <div>
                  <small class="charact">{{ MemberDomain.cname }}</small>

                  <p>此域名是加速域名CNAME到CDN节点上的地址，直接访问此域名则无法获取正确资源信息</p>
                </div>
              </el-form-item>
              <el-form-item label="创建时间" prop="region">
                <small class="charact">{{ MemberDomain.createTime }}</small>
              </el-form-item>
              <el-form-item label="加速类型" prop="region">
                <div>
                  <small class="charact">{{ MemberDomain.productCode == '001' ?
                    '静态加速' : MemberDomain.productCode == '003' ?
                      '下载加速' : MemberDomain.productCode == '004'
                        ? '视频点播加速' : MemberDomain.productCode == '008'
                          ? 'CDN加速' : MemberDomain.productCode == '006' ?
                            '全站加速' : MemberDomain.productCode == '007' ? '安全加速' : '下载加速闲时' }}</small>
                </div>
              </el-form-item>
              <el-form-item label="加速范围">
                <el-radio-group v-model="ruleForm.areaScope">
                  <el-radio label=1 :disabled="examine">中国内地</el-radio>
                  <el-radio label=2 :disabled="true">全球(不含中国内地)</el-radio>
                  <el-radio label=3 :disabled="true">全球</el-radio>
                </el-radio-group>
                <p class="mg-tp-10">加速范围包含中国内地时，加速域名请先完成在中国大陆的ICP备案，同时完成公安网备案服务</p>
                <p class="mg-tp-10">您当前尚未开通CDN加速境外服务，如果有需要请联系客户经理开通CDN加速-全球(不含中国内地)服务</p>
              </el-form-item>
              <!-- <el-form-item label="IPv6访问">
                  <el-switch v-model="ruleForm.delivsw"></el-switch>
                  <small class="charact mg-lt-10">IPv6访问</small>
                  <p class="mg-tp-10">开启后，客户端将可以通过IPv6访问CDN。开启后，你的源站也可能收到IPv6地址的回源请求，如源站程序涉及IP地址处理，请确保兼容IPv6地址</p>
                </el-form-item> -->
            </el-form>
            <!-- <div class="Btn">

              <el-button type="primary" class="cancel" @click="updateMemberDomain" v-if="!examine">确认</el-button>

            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    msg: String,
    dovervilist: Object,
    examine:Boolean
  },
  data() {
    return {
      ruleForm: {
        name: '',
        delivsw: false,
        areaScope: '',
      },
      userInfo: {},
      MemberDomain: {},

    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getMemberDomain()
  },
  methods: {
    getMemberDomain() {
      this.$apis.getMemberDomain({
        id: this.dovervilist.id
      }).then((res) => {
        this.MemberDomain = res.data
        this.ruleForm.areaScope = String(res.data.areaScope)
      })
    },
    updateMemberDomain() {
      this.$apis.updateMemberDomain({
        id:  this.dovervilist.id,
        areaScope:this.ruleForm.areaScope,
        productCode:this.MemberDomain.productCode,
        domain:this.MemberDomain.domain
      }).then((res) => {
         if(res.code==0) {
          this.$message({
            type:"success",
            message:res.msg
          })
         } else {
          this.$message({
            type:"error",
            message:res.msg
          })
         }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Btn {
  text-align: right;

  .cancel {
    padding: 10px 15px;
    text-align: right;
  }
}


/deep/.eForm {
  margin-top: 20px;

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item__label {
    text-align: left;
    width: 80px;
  }

  a {
    color: #4249F1;
  }


  .el-radio-group {
    display: flex;
    align-items: center;
    margin-top: 13px;

    .el-radio__label {
      font-size: 12Px;
    }

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .charact {
    color: #7C7C7D;
    font-size: 14px;
    font-weight: bold;
  }

  p {
    line-height: 10px !important;
  }

  .el-input__inner {
    width: 300px;
  }

  .el-form-item {}
}

.puricate {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13Px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22Px;

      .unit {
        font-size: 14Px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {}

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16Px;
    }

    span {
      font-size: 13Px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16Px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15Px;
    color: #333333;
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
    padding: 15px;
    margin-bottom: 20px;
  }

  /deep/.el-drawer__body {


    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15Px;
      color: #666666;
      font-weight: bold;
    }



    .pitcon {
      border-color: #007bff !important;
      background: url('../../../../../assets/img/xzl.png') no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22Px;

          label {
            font-size: 14Px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13Px;

        }
      }

      .left {
        p {
          font-size: 20Px;
          color: #666666;
        }
      }
    }
  }
}
</style>
