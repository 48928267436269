const apiArr = [
	// {
	//   name: "test", // 接口调用方法名
	//   path: '/api/test', // 后端路径
	//   method: "POST", // 默认POST
	//   headers: {"token": 123}, // 自定义单个接口的请求头
	//   desc: "登录接口", // 接口描述
	// }
	{
	  name: "getKaptchaImage",
	  path: 'wxapi/getKaptchaImage',
	  method: "GET",
	  desc: "获取图形验证码",
	},
	{
		name: "sendRegisterVerifyCode",
		path: 'wxapi/sendRegisterVerifyCode',
		method: "POST",
		desc: "获取验证码",
	  },
	  {
		name: "memberRegister",
		path: 'wxapi/memberRegister',
		method: "POST",
		desc: "注册账号",
	  },
	  {
		name: "memberLogin",
		path: 'wxapi/memberLogin',
		method: "POST",
		desc: "登录",
	  },
	  {
		name: "sendPassWordVerifyCode",
		path: 'wxapi/sendPassWordVerifyCode',
		method: "POST",
		desc: "忘记密码获取验证码",
	  },
	  {
		name: "forgetPassword",
		path: 'wxapi/forgetPassword',
		method: "POST",
		desc: "忘记密码",
	  },
	  {
		name: "closePop",
		path: 'wxapi/closePop',
		method: "POST",
		desc: "关闭提示弹框",
	  },
	  {
		name: "member",
		path: 'wxapi/member',
		method: "GET",
		desc: "重新获取用户信息",
	  },
	  {
		name: "homeProductService",
		path: 'wxapi/homeProductService',
		method: "GET",
		desc: "首页",
	  },
	  {
		name: "getNoticeRedList",
		path: 'wxapi/getNoticeRedList',
		method: "GET",
		desc: "消息公告",
	  },
	  {
		name: "memberEdit",
		path: 'wxapi/memberEdit',
		method: "POST",
		desc: "修改名称",
	  },
	  {
		name: "phoneVerification",
		path: 'wxapi/phoneVerification',
		method: "POST",
		desc: "个人认证",
	  },
	  {
		name: "bizLicenseOCR",
		path: 'wxapi/bizLicenseOCR',
		method: "POST",
		desc: "企业认证",
	  },
	  {
		name: "getSubscribeSetting",
		path: 'wxapi/getSubscribeSetting',
		method: "GET",
		desc: "订阅通知",
	  },
	  {
		name: "modifySubscribeSetting",
		path: 'wxapi/modifySubscribeSetting',
		method: "POST",
		desc: "修改订阅通知",
	  },
	  {
		name: "resetPassword",
		path: 'wxapi/resetPassword',
		method: "POST",
		desc: "重置密码",
	  },
	  {
		name: "costCentre",
		path: 'wxapi/costCentre',
		method: "GET",
		desc: "费用中心首页",
	  },
	  {
		name: "wxRecharge",
		path: 'wxapi/wxRecharge',
		method: "POST",
		desc: "费用中心支付",
	  },
	  {
		name: "getBalanceTotal",
		path: 'wxapi/getBalanceTotal',
		method: "GET",
		desc: "费用充值",
	  },
	  {
		name: "offLineRecharge",
		path: 'wxapi/offLineRecharge',
		method: "POST",
		desc: "费用中心线下汇款",
	  },
	  {
		name: "redNoticeRed",
		path: 'wxapi/redNoticeRed',
		method: "POST",
		desc: "费用中确认消息",
	  },
	  {
		name: "getBalanceLog",
		path: 'wxapi/getBalanceLog',
		method: "GET",
		desc: "费用中心收支明细",
	  },
	  {
		name: "getExpenseBill",
		path: 'wxapi/getExpenseBill',
		method: "GET",
		desc: "费用中心费用账单",
	  },
	  {
		name: "getOrderListByMid",
		path: 'wxapi/getOrderListByMid',
		method: "GET",
		desc: "费用中心订单管理",
	  },
	  {
		name: "refundOrder",
		path: 'wxapi/refundOrder',
		method: "POST",
		desc: "费用中心订单申请退款",
	  },
	  {
		name: "cancelOrder",
		path: 'wxapi/cancelOrder',
		method: "POST",
		desc: "费用中心订单取消订单",
	  },
	  {
		name: "getOrderById",
		path: 'wxapi/getOrderById',
		method: "GET",
		desc: "费用中心订单详情",
	  },
	  {
		name: "wxPayOrder",
		path: 'wxapi/wxPayOrder',
		method: "POST",
		desc: "费用中心订单详情支付",
	  },
	  {
		name: "getInvoiceListByMid",
		path: 'wxapi/getInvoiceListByMid',
		method: "GET",
		desc: "费用中心发票管理",
	  },
	  {
		name: "getInvoiceHead",
		path: 'wxapi/getInvoiceHead',
		method: "GET",
		desc: "费用中心发票抬头",
	  },
	  {
		name: "getInvoiceType",
		path: 'wxapi/getInvoiceType',
		method: "GET",
		desc: "费用中心发票类型字典",
	  },
	  {
		name: "addInvoiceHead",
		path: 'wxapi/addInvoiceHead',
		method: "POST",
		desc: "费用中心添加发票抬头",
	  },
	  {
		name: "addInvoiceAddress",
		path: 'wxapi/addInvoiceAddress',
		method: "POST",
		desc: "费用中心添加寄送地址",
	  },
	  {
		name: "updateInvoiceAddress",
		path: 'wxapi/updateInvoiceAddress',
		method: "POST",
		desc: "费用中心添加寄送地址修改",
	  },
	  {
		name: "updateInvoiceHead",
		path: 'wxapi/updateInvoiceHead',
		method: "POST",
		desc: "费用中心添加发票抬头修改",
	  },
	  {
		name: "noInvoiceOrderList",
		path: 'wxapi/noInvoiceOrderList',
		method: "GET",
		desc: "费用中心未开票订单",
	  },
	  {
		name: "addInvoice",
		path: 'wxapi/addInvoice',
		method: "POST",
		desc: "费用中心新增开票",
	  },
	  {
		name: "getRechargeConfig",
		path: 'wxapi/getRechargeConfig',
		method: "GET",
		desc: "费用中心新增开票",
	  },
	  {
		name: "getCdnOverview",
		path: 'wxapi/getCdnOverview',
		method: "GET",
		desc: "融合CDN",
	  },
	  {
		name: "getCdnOverviewFlow",
		path: 'wxapi/getCdnOverviewFlow',
		method: "GET",
		desc: "融合CDN",
	  },
	  {
		name: "getMemberDomainPage",
		path: 'wxapi/getMemberDomainPage',
		method: "GET",
		desc: "融合CDN域名管理",
	  },
	  {
		name: "removeDomain",
		path: 'wxapi/removeDomain',
		method: "POST",
		desc: "删除域名管理列表",
	  },
	  {
		name: "verifyDomain",
		path: 'wxapi/verifyDomain',
		method: "POST",
		desc: "域名验证",
	  },
	  {
		name: "verifyDomainDns",
		path: 'wxapi/verifyDomainDns',
		method: "POST",
		desc: "域名验证",
	  },
	  {
		name: "getVerifyContent",
		path: 'wxapi/getVerifyContent',
		method: "POST",
		desc: "域名记录",
	  },
	  {
		name: "addMemberDomain",
		path: 'wxapi/addMemberDomain',
		method: "POST",
		headers:{
			'Content-Type':'application/json'
		},
		desc: "融合CDN添加域名",
	  },
	  {
		name: "getMemberDomain",
		path: 'wxapi/getMemberDomain',
		method: "GET",
		desc: "基本设置",
	  },
	  {
		name: "updateMemberDomain",
		path: 'wxapi/updateMemberDomain',
		method: "POST",
		desc: "基本设置保存",
	  },
	  {
		name: "getMemberDomainDetail",
		path: 'wxapi/getMemberDomainDetail',
		method: "GET",
		desc: "回源设置设置",
	  },
	  {
		name: "getCdnPackageList",
		path: 'wxapi/getCdnPackageList',
		method: "GET",
		desc: "查询证书",
	  },
	  {
		name: "getCdnPackagePage",
		path: 'wxapi/getCdnPackagePage',
		method: "GET",
		desc: "证书管理表格",
	  },
	  {
		name: "addCdnPackage",
		path: 'wxapi/addCdnPackage',
		method: "POST",
		desc: "增加证书管理",
	  },
	  {
		name: "removeCdnPackage",
		path: 'wxapi/removeCdnPackage',
		method: "POST",
		desc: "证书管理删除",
	  },
	  {
		name: "getDefaultCacheByDomain",
		path: 'wxapi/getDefaultCacheByDomain',
		method: "GET",
		desc: "查询默认缓存",
	  },
	  {
		name: "addDefaultCache",
		path: 'wxapi/addDefaultCache',
		method: "POST",
		headers:{
			'Content-Type':'application/json'
		},
		desc: "添加默认缓存",
	  },
	  {
		name: "getDefaultCacheById",
		path: 'wxapi/getDefaultCacheById',
		method: "GET",
	
		desc: "根据缓存ID查询缓存设置",
	  },
	  {
		name: "updateDefaultCache",
		path: 'wxapi/updateDefaultCache',
		method: "POST",
		headers:{
			'Content-Type':'application/json'
		},
		desc: "修改默认缓存",
	  },
	  {
		name: "removeDefaultCache",
		path: 'wxapi/removeDefaultCache',
		method: "POST",
		desc: "删除默认缓存",
	  },
	  {
		name: "increUpdateMemberDomain",
		path: 'wxapi/increUpdateMemberDomain',
		method: "POST",
		headers:{
			'Content-Type':'application/json'
		},
		desc: "回源设置设置",
	  },
	  {
		name: "addRefresh",
		path: 'wxapi/addRefresh',
		method: "POST",
		desc: "刷新预热",
	  },
	  {
		name: "getRefreshList",
		path: 'wxapi/getRefreshList',
		method: "GET",
		desc: "刷新预热列表",
	  },
	  {
		name: "getFlowStatistics",
		path: 'wxapi/getFlowStatistics',
		method: "GET",
		desc: "统计分析带宽流量",
	  },
	  {
		name: "missBandwidthData",
		path: 'wxapi/missBandwidthData',
		method: "GET",
		desc: "统计分析回源设置",
	  },
	  {
		name: "flowRateDataByDomain",
		path: 'wxapi/flowRateDataByDomain',
		method: "GET",
		desc: "统计分析请求率",
	  },
	  {
		name: "httpStatusCodeData",
		path: 'wxapi/httpStatusCodeData',
		method: "GET",
		desc: "状态码",
	  },
	  {
		name: "analysisPv",
		path: 'wxapi/analysisPv',
		method: "GET",
		desc: "PVUV",
	  },
	  {
		name: "analysisTopUrl",
		path: 'wxapi/analysisTopUrl',
		method: "GET",
		desc: "热门URL",
	  },
	  {
		name: "analysisTopReferer",
		path: 'wxapi/analysisTopReferer',
		method: "GET",
		desc: "热门Referer",
	  },
	  {
		name: "analysisTopIp",
		path: 'wxapi/analysisTopIp',
		method: "GET",
		desc: "topIP",
	  },
	  {
		name: "requestMumData",
		path: 'wxapi/requestMumData',
		method: "GET",
		desc: "统计分析请求数",
	  },
	  {
		name: "getMemberDomainList",
		path: 'wxapi/getMemberDomainList',
		method: "GET",
		desc: "域名列表",
	  },
	  {
		name: "stopDomain",
		path: 'wxapi/stopDomain',
		method: "POST",
		desc: "停用和启用域名",
	  },
	  {
		name: "logBsstimeFilesl",
		path: 'wxapi/logBsstimeFilesl',
		method: "GET",
		desc: "日志下载",
	  },
	  {
		name: "getPackageByMid",
		path: 'wxapi/getPackageByMid',
		method: "GET",
		desc: "资源包管理下载",
	  },
	  {
		name: "getResourcePackageList",
		path: 'wxapi/getResourcePackageList',
		method: "GET",
		desc: "资源包管理购买",
	  },
	  {
		name: "createOrder",
		path: 'wxapi/createOrder',
		method: "POST",
		desc: "资源包管理购买确认订单",
	  },
	  {
		name: "getOrderById",
		path: 'wxapi/getOrderById',
		method: "GET",
		desc: "资源包管理详情",
	  },
	  {
		name: "yuPayOrder",
		path: 'wxapi/yuPayOrder',
		method: "POST",
		desc: "余额支付",
	  },
	  {
		name: "wxPayOrder",
		path: 'wxapi/wxPayOrder',
		method: "POST",
		desc: "资源包管理支付",
	  },
	  {
		name: "getSslPackageByMid",
		path: 'wxapi/getSslPackageByMid',
		method: "GET",
		desc: "SSL证书列表",
	  },
	  {
		name: "getSslPackage",
		path: 'wxapi/getSslPackage',
		method: "GET",
		desc: "SSL证书购买",
	  },
	  {
		name: "getSslTypeList",
		path: 'wxapi/getSslTypeList',
		method: "GET",
		desc: "SSL证书默认",
	  },
	  {
		name: "getCheckSslPackage",
		path: 'wxapi/getCheckSslPackage',
		method: "GET",
		desc: "查看SSL证书",
	  },
	  {
		name: "createOrder",
		path: 'wxapi/createOrder',
		method: "POST",
		desc: "SSL证书购买",
	  },
	  {
		name: "getSslBrandList",
		path: 'wxapi/getSslBrandList',
		method: "GET",
		desc: "查询SSL证书品牌",
	  },
	  {
		name: "logout",
		path: 'wxapi/logout',
		method: "GET",
		desc: "退出登录",
	  },
	  {
		name: "getRechargeStatus",
		path: 'wxapi/getRechargeStatus',
		method: "GET",
		desc: "查询支付状态",
	  },

	  {
		name: "upload",
		path: 'wxapi/upload/file',
		method: "POST",
		headers:{
			'Content-Type': 'multipart/form-data'
		},
		desc: "图片上传",
	  },
	  


  ]
  
  export default apiArr;
  