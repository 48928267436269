<template>
  <div class="w-100 h-100">

    <div class="recar">
      <div class="reacrmain">
      </div>
    </div>
    <div class="puricate">
      <div class="dawer">
        <div>
          <div>
            <div class="flex al-start">
              <span class="cho">压缩设置</span>
            </div>
            <div>
              <el-form :model="ruleForm" :rules="rules" class="eForm">
                <el-form-item label="文件压缩" class="yuanz">
                  <div class="">
                    <el-button type="primary" class="Fbtn" @click="AddZipFile" v-if="!examine">添加</el-button>
                    <el-table :data="cusGzip" style="width: 100%" tempty-text="暂无数据">
                      <el-table-column prop="type" label="压缩方式" width="300">
                        <template slot-scope="scope">
                          {{ scope.row.type == 0 ? 'gzip' : 'brotli' }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="file_type" label="压缩类型" width="400">
                      </el-table-column>
                      <el-table-column prop="min_length" label="最小压缩文件大小" width="400">
                      </el-table-column>
                      <el-table-column prop="address" label="操作">
                        <template slot-scope="scope">
                          <div>
                            <span class="color-7D" @click="filendFicati(scope.$index, 1)" v-if="!examine">修改</span>
                            <small class="mg-lt-10 color-7D" @click="removefile(scope.$index)" v-if="!examine">删除</small>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div>

                  </div>
                </el-form-item>
                <div>

                </div>
              </el-form>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //添加文件压缩 -->
    <div class="nrcation">
      <el-dialog :visible.sync="compVisible">
        <div slot="title">
          <span>{{title}}文件压缩</span>
        </div>
        <el-divider></el-divider>
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item label="压缩文件方式" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择压缩文件方式">
                <el-option label="gzip" value="0"></el-option>
                <el-option label="brotli" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="压缩文件类型" prop="fileType">
              <div>
                <el-input v-model="ruleForm.fileType" placeholder="压缩文件类型,多个用,分割"></el-input>
                <p>支持文件类型
                  :text/xmltext/plain、text/css、application/javascript、application/x-javascript、application/rss+xml、text/javascript、image/tiffimage/svg+xml、application/json、application/xml
                </p>
              </div>

            </el-form-item>
            <el-form-item label="最小压缩文件" prop="minLength">
              <div>
                <el-input v-model="ruleForm.minLength" placeholder="最小压缩文件"></el-input>
                <p>支持(1-1023)K或(1-102399)M</p>
              </div>

            </el-form-item>
          </el-form>
        </div>
        <el-divider></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="compVisible = false" class="cancel">取 消</el-button>
          <el-button type="primary" @click="confirm" class="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
export default {

  props: {
    msg: String,
    dovervilist: Object,
    examine: Boolean
  },
  data() {
    return {
      compVisible: false,
      cundex: 0,
      title:"",
      ruleForm: {
        minLength: "1K",
        fileType: "",
        type: "",
      },
      rules: {
        type: [
          { required: true, message: '请选择压缩文件方式', trigger: 'change' },
        ],
        fileType: [
          { required: true, message: '请输入压缩文件类型', trigger: 'change' },
        ],
        minLength: [
          { required: true, message: '请输入最小压缩文件', trigger: 'change' },
        ],
      },
      cusGzip: [

      ],
      filendid: "",
      userInfo:{},
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getMemberDomainDetail()
  },
  methods: {
    getMemberDomainDetail() {
      this.$apis.getMemberDomainDetail({
        domain: this.dovervilist.domain,
        mid:this.userInfo.id
      }).then((res) => {
        this.cusGzip = res.data.cus_gzip
      })
    },
    removefile(index) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.cusGzip.splice(index, 1)
        // this.increUpdateMemberDomain()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
     
    },
    filendFicati(index, id) {
      this.title='修改'
      this.filendid = id
      this.cundex = index
      this.compVisible = true
      this.ruleForm.minLength = this.cusGzip[this.cundex].min_length
      this.ruleForm.fileType = this.cusGzip[this.cundex].file_type
      this.ruleForm.type = String(this.cusGzip[this.cundex].type)
    },
    AddZipFile() {
      this.title='新增'
      this.compVisible = true
      this.ruleForm.minLength = "1K"
      this.ruleForm.fileType = ""
      this.ruleForm.type = ""
      this.cundex = this.cusGzip.length - 1
    },
    confirm() {
      if ( this.title=='修改') {
        this.cusGzip[this.cundex].min_length = this.ruleForm.minLength
        this.cusGzip[this.cundex].file_type = this.ruleForm.fileType
        this.cusGzip[this.cundex].type = this.ruleForm.type
        this.compVisible = false
      } else {
        this.cusGzip.push({
          min_length: this.ruleForm.minLength,
          file_type: this.ruleForm.fileType,
          type: this.ruleForm.type,
        })
        this.compVisible = false
      }

    },
    getData() {
      let form= {
        cus_gzip: this.cusGzip
      }
      return form
    },
    // increUpdateMemberDomain() {
    //   this.$apis.increUpdateMemberDomain({
    //     domain: this.dovervilist.domain,
       
    //   }).then((res) => {
    //     if (res.code == 0) {
    //       this.$message({
    //         type: "success",
    //         message: "保存成功"
    //       })
    //       this.compVisible = false
    //     } else {
    //       this.cusGzip.splice(1)
    //       this.$message({
    //         type: "error",
    //         message: res.msg
    //       })
    //     }
    //   })
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
.nrcation {
  p {
    line-height: 1;
    font-size: 12px;
    color: #C1C1C1;
  }

  .el-dialog__header {
    span {
      font-size: 15px;
    }
  }

  .cancel {
    background-color: #6C757D;
    color: #fff;
    border: none;
  }

  .confirm {
    background-color: #007BFF;
    color: #fff;
    border: none;
  }

  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  /deep/.el-form {
    padding: 0 20px;
  }

  /deep/.el-form-item__content {
    margin-left: 80px;
    display: flex;
    align-content: center;
  }

  /deep/.el-form-item__label {
    text-align: left;
  }

  /deep/.el-input__inner {
    width: 100%;
  }

  /deep/.el-select__caret {
    // line-height: 50px;
  }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-divider {
    margin-top: 0;
  }
}

/deep/.eForm {
  margin-top: 20px;

  .souader {
    margin-top: 30px;

    .addion {
      margin-bottom: 10px;
    }

    .Fbtn {
      background-color: #007BFF;
      padding: 9px 18px;
      font-size: 18px;
    }
  }

  .el-table th.el-table__cell {
    background-color: #F7F7F7 !important;
    padding: 0 !important;

    .cell {
      font-weight: bold
    }
  }

  .Fbtn {
    background-color: rgba(0, 123, 255, 1);
    color: rgba(255, 255, 255, 1);
    padding: 5px 10px;
    border: none;
  }

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item__label {
    text-align: left;
    width: 80px;
  }






  p {
    line-height: 10px !important;
  }

  .el-input__inner {
    width: 300px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }
}

.puricate {













  .cho {
    width: 100px;
    padding: 0 15px 0 0;
    font-size: 15Px;
    color: #666666;
    font-weight: bold;
  }




}
</style>
