<template>
  <div class="w-100 h-100">
    <div class="mainbox" v-show="accforshow == 1">
      <div class="acco flex">
        <img src="@/assets/img/xxdy.png" alt="" srcset="">
        <span>消息订阅</span>
      </div>
      <div class="reg">
        <span>如果你的注册手机号不想接收消息，可以在此设置接收人，并取消注册手机号的消息订阅。<br /></span>

      </div>
      <div>
        <span class="jb">接收人</span>
        <el-divider></el-divider>
        <div class="flex essen">
          <label class="labe">注册手机</label>
          <div class="flex-column usid al-baseline">
            <span>{{ Tgmber }}****{{ Laomber }}</span>
            <p>当前绑定手机,如需修改请联系客服。</p>
          </div>

        </div>
        <div class="flex essen">
          <label class="labe">设置接收人</label>
          <div class="flex-column usid al-baseline">
            <div class="flex">
              <span v-if="recishow">{{ userInfo.messageMobile ? userInfo.messageMobile : '未设置' }}</span>
              <div v-else>
                <el-input v-model="messageMobile" label="描述文字"></el-input>
              </div>
              <img src="../../../assets/img/weisz.png" alt="" srcset="" class="iset">
              <label @click="recishow = !recishow" v-if="recishow" class="
              curpoin">设置</label>
              <label @click="memberEdit" v-else class="curpoin">确定</label>
            </div>


            <p>除了注册手机号以外，你还可以设置一个手机号来接收短信和语音消息。</p>
          </div>

        </div>
      </div>
      <div>
        <span class="jb">账号与财务消息</span>
        <el-divider></el-divider>
        <div class="flex essen">
          <label class="labe">账号欠费通知</label>
          <div class="flex-column usid al-baseline">
            <el-checkbox-group v-model="arrears">
              <el-checkbox label="arrearsNotice" @change="NoticeChange" v-model="arrearsNotice">站内消息</el-checkbox>
              <el-checkbox label="arrearsText" @change="TextChange" v-model="arrearsNotice">短信(注册手机)</el-checkbox>
            </el-checkbox-group>
            <p>账号欠费之后发出的通知，提醒用户充值，或者尽早备份数据，提醒注意按量计费产品可能会被停止服务。</p>
          </div>

        </div>
        <div class="flex essen">
          <label class="labe">余额预警通知</label>
          <div class="flex-column usid al-baseline">
            <el-checkbox-group v-model="moneyMarning">
              <el-checkbox label="moneyMarningNotice" @change="MarNoticeChange">站内消息</el-checkbox>
              <el-checkbox label="moneyMarningText" @change="MarTexChange"> 短信(注册手机)</el-checkbox>
            </el-checkbox-group>
            <p>如果你在费用中心首页设置了余额预警，当你账号的可用余额低于预警值时，会进行的通知。</p>
          </div>

        </div>
      </div>
      <div>
        <span class="jb">产品通知</span>
        <el-divider></el-divider>
        <!-- <div class="flex essen">
          <label class="labe">产品购买成功通知</label>
          <div class="flex-column usid al-baseline">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="站内消息"></el-checkbox>
              <el-checkbox label="短信(注册手机)"></el-checkbox>
            </el-checkbox-group>
            <p>购买了资源包或者其它产品时的通知。</p>
          </div>

        </div> -->
        <div class="flex essen">
          <label class="labe">CDN流量包用尽通知</label>
          <div class="flex-column usid al-baseline">
            <el-checkbox-group v-model="flow">
              <el-checkbox label="flowNotice" @change="flwNoticeChange">站内消息</el-checkbox>
              <el-checkbox label="flowText" @change="flTextChange"> 短信(注册手机)</el-checkbox>
            </el-checkbox-group>
            <p>融合 CDN 购买的流量包用尽时的通知，提醒用户购买流量包，不购买的话会按量后付费，不会对业务产生影响。</p>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
      accforshow: '1',
      arrears: [],
      moneyMarning: [],
      flow:[],
      messageMobile: "",
      recishow: true,
      userInfo: {},
      SubscribeSetting: {},
      arrearsNotice: "1",
      arrearsText: "1",
      moneyMarningNotice: "1",
      moneyMarningText: "1",
      flowNotice:"1",
      flowText:"1",
      Tgmber:"",
      Laomber:"",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.Tgmber = this.userInfo.mobile.slice(0, 3)
    this.Laomber = this.userInfo.mobile.slice(7, 11)
    this.getSubscribeSetting()
   
  },
  created() {
    
  },
  methods: {
    //重新获取用户信息
    member() {
      this.$apis.member({
        mid: this.userInfo.id
      }).then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.userInfo = res.data
      })
    },
    memberEdit() {
      this.recishow = !this.recishow
      if (this.messageMobile) {
        this.$apis.memberEdit({
          messageMobile: this.messageMobile,
          id: this.userInfo.id
        }).then(() => {
          this.$message({
            type: "success",
            message: "设置成功"
          })
          this.member()
        })
      } else {
        this.$message('不能为空')
      }

    },
    getSubscribeSetting() {
      this.$apis.getSubscribeSetting({
        mid: this.userInfo.id
      }).then((res) => {
        this.SubscribeSetting = res.data
        if (res.data.arrearsNotice == 1) {
          this.arrears=[]
          this.arrears.push('arrearsNotice')
          this.arrearsNotice = 1
        }
        if (res.data.arrearsText == 1) {
          this.arrears.push('arrearsText')
          this.arrearsText = 1
        }
        if (res.data.moneyWarningNotice == 1) {
          this.moneyMarning.push('moneyMarningNotice')
          this.moneyMarningNotice=1
        }
        if (res.data.moneyWarningText == 1) {
          this.moneyMarning.push('moneyMarningText')
          this.moneyMarningText=1
        }
        if(res.data.flowNotice==1) {
          this.flow.push('flowNotice')
          this.flowNotice=1
        }
        if(res.data.flowText==1) {
          this.flow.push('flowText')
          this.flowText=1
        }
      })
      this.modifySubscribeSetting()
    },
    modifySubscribeSetting() {
      this.$apis.modifySubscribeSetting({
        arrearsNotice: this.arrearsNotice,
        arrearsText: this.arrearsText,
        moneyWarningNotice: this.moneyMarningNotice,
        moneyWarningText: this.moneyMarningText,
        flowNotice: this.flowNotice,
        flowText: this.flowText,
        id: this.SubscribeSetting.id
      }).then((res) => {
      })
    },
    flTextChange(val) {
      this.flowText = val ? 1 : 0
      this.modifySubscribeSetting()
    },
    flwNoticeChange(val) {
      this.flowNotice = val ? 1 : 0
      this.modifySubscribeSetting()
    },
    MarTexChange(val) {
      this.moneyMarningText = val ? 1 : 0
      this.modifySubscribeSetting()
    },
    MarNoticeChange(val) {
      this.moneyMarningNotice = val ? 1 : 0
      this.modifySubscribeSetting()
    },
    TextChange(val) {
      this.arrearsText = val ? 1 : 0
      this.modifySubscribeSetting()
    },
    NoticeChange(val) {
      this.arrearsNotice = val ? 1 : 0
      this.modifySubscribeSetting()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.is-checked .el-checkbox__inner {
  background-color: #3F51B5;
  border-color: #3F51B5;
}

/deep/.el-checkbox-group {
  display: flex;
  align-items: center;
}

/deep/.el-checkbox {
  display: flex;
  align-items: center;
}

/deep/.el-checkbox__label {
  color: #8A8989 !important;
  font-size: 12Px;
}

/deep/.el-input__inner {
  height: 30px;
}

.iset {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

p {
  margin: 0;
}

.el-divider {
  margin: 10px 0;
}

.mainbox {
  //  height: 373px !important;
  background-color: #fff;
  padding: 10px 17px 1px 17px;
  margin: 0 0 339px 0;
}

.jb {
  font-size: 15px;
  color: #333333;
}

.essen {
  display: flex;
  align-items: inherit;
  margin-bottom: 20px;

  .labe {
    font-size: 14px;
    color: #666;
    // margin-right: 120px;
    width: 170px;
  }

  .usid {
    label {
      color: #4A9EFF;
      margin-left: 5px;
      font-size: 12px;
    }

    span {
      font-size: 16px;
      font-weight: bold;
      color: #71706F;
    }

    p {
      color: #A1A1A1;
      margin-top: 6px;
    }
  }
}

.reg {
  font-size: 80%;
  color: #666;
  margin: 35px 0 50px 0;

  a {
    color: #0056b3;
  }
}

.acco {
  img {
    width: 24px;
    height: 24px;
  }

  span {
    margin: 0;
    font-size: 20px;
    margin-left: 12px;
  }
}

// /deep/.el-submenu__title:hover {
//   background-color: #E6F7FF  !important;
//   color: #4BA1FD !important;

// }
.lgo {
  width: 126px;
  height: 28px;
  margin-right: 10px;
}
</style>
