<template>
  <div class="enterBox">
    <div class="main" v-show="forgwordshow">
      <div class="left">
        <img src="../../assets/img/logo.png" alt="" srcset="" class="logos">
        <img src="../../assets/img/diann.png" alt="" srcset="" class="dia">
      </div>
      <div class="right">
        <!-- //登录 -->
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="Logot">
          <el-tab-pane label="密码登录" name="1">
            <passlogin @sighay="sighay" @forgPassw="forgPassw"></passlogin>
          </el-tab-pane>
          <el-tab-pane label="验证码登录" name="2">
            <verificagin @sighay="sighay"></verificagin>
          </el-tab-pane>
        </el-tabs>
        <!-- //注册 -->
        <div class="regisbox" v-else-if="!Logot">
          <regis @sighay="sighay"></regis>
        </div>
      </div>
    </div>
    <!-- //忘记密码 -->
    <div v-show="!forgwordshow" class="forgword">
      <forgword @Bagin="Bagin"></forgword>
    </div>
    <!-- //忘记密码end -->
  </div>
</template>

<script>
import forgword from '../Login/components/forgword.vue'
import passlogin from '../Login/components/passlogin.vue'
import verificagin from '../Login/components/verificagin.vue'
import regis from '../Login/components/regis.vue'
export default {
  props: {
    msg: String
  },
  components: {
    forgword,
    passlogin,
    verificagin,
    regis
  },
  data() {
    return {
      activeName: '1',

      Logot: true,
      forgwordshow: true,

    }
  },
  mounted() {
  },
  methods: {
    //忘记密码
    forgPassw() {
      this.forgwordshow = !this.forgwordshow
    },
    sighay() {
      this.Logot = !this.Logot
    },
    handleClick(tab, event) {
    },
    Bagin() {
      this.forgwordshow = !this.forgwordshow
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.enterBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.curesor {
  cursor: pointer;
}

.forgword {
  margin-top: 186Px;
  display: flex;
  justify-content: center;
}

.main {
  justify-content: center;
  display: flex;
  // margin-top: 100px;

  /deep/.el-tabs__nav-wrap::after {
    background-color: transparent !important;

  }

  /deep/.el-tabs {
    margin-top: 129px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /deep/.el-tabs__item {
    color: rgba(136, 136, 136, 1);
    font-size: 16px;
  }

  /deep/.el-tabs__item.is-active {
    color: rgba(16, 16, 16, 1) !important;
  }

  /deep/.el-tabs__active-bar {
    height: 3px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(108, 116, 247, 1);
    text-align: center;
  }

  .left {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 480px;
    height: 580px;
    line-height: 20px;
    border-radius: 8px 0px 0px 8px;
    // background-color: rgba(108, 116, 247, 1);
    text-align: center;

    .dia {
      width: 383px;
      height: 308px;
      margin-top: 34px;
    }

    .logos {
      margin-top: 123px;
      width: 235px;
      height: 52px;
    }
  }

  .right {
    width: 520px;
    line-height: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    box-shadow: 0px 2px 6px 0px rgba(136, 136, 136, 45);



    /deep/.el-input-group__append {
      width: 80px;
      height: 50px;
      line-height: 50px;
      border-radius: 0px 8px 8px 0px;
      background-color: rgba(245, 245, 245, 1);
      color: rgba(85, 85, 85, 1);
      font-size: 14px;
      text-align: center;
      border: 1px solid rgba(215, 215, 215, 1);
      position: absolute;
      top: 0;
      right: 0;
    }

    .sinput {
      margin-top: 36px;
    }

    .ck {
      width: 24px;
      height: 24px;
      margin-top: 13px;
    }

    .sigp {
      margin-top: 24px;

      span {
        font-size: 13px;
      }
    }

    .minput {
      margin-top: 22px;
      display: flex;
    }

    /deep/.el-input__inner {
      width: 360px;
      height: 50px;
      line-height: 20px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(215, 215, 215, 1);
    }

    .forget {
      color: rgba(136, 136, 136, 1);
      font-size: 13px;
      text-align: right;
      margin: 16px 0 34px 0;
      cursor: pointer;
    }

    /deep/.el-button--primary {
      width: 360px;
      height: 56px;
      line-height: 23px;
      border-radius: 8px;
      background-color: rgba(108, 116, 247, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      text-align: center;
      font-family: Roboto;
      border: none;
    }
  }
}
</style>
