<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <h3 class="cz flex">
          <img src="@/assets/img/rzxz.png" alt="" srcset="" class="hedimg">
          <span> 日志下载</span>

        </h3>
        <div class="">
          <div class="deion">
            <h4>访问日志区分境内境外，按小时分割，仅保留最近30天的访问日志</h4>

          </div>
          <div class="selmain">
            <span>选择域名：</span>
            <el-select v-model="form.domain" placeholder="请选择">
              <el-option v-for="item in domainoptions" :key="item.domain" :label="item.domain" :value="item.domain">
              </el-option>
            </el-select>
            <el-date-picker v-model="form.time" type="daterange" value-format="yyyy-MM-dd" :clearable=false
              class="mg-lt-21" @change="dpicChange" ref="picker" :picker-options="pickerOptions" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="primary" class="inquire mg-lt-21" @click="logBsstimeFilesl">查询</el-button>
          </div>

          <div>
            <el-table :data="logBsstimeFiList" style="width: 100%" empty-text="未找到日志...">
              <el-table-column prop="timepoint" label="时间" width="300">
              </el-table-column>
              <el-table-column label="域名" width="260" align="center">
                {{ form.domain }}
              </el-table-column>
              <el-table-column prop="size" label="文件大小" width="500" align="center">
                <template slot-scope="scope">{{ scope.row.size }}</template>
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="primary" class="inquire " @click="download(scope.row.url)">下载</el-button>

                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]" :page-size="form.limit" layout=" sizes, prev, pager, next, jumper"
            :total="total" class="flex just-center mg-tp-23">
          </el-pagination>
        </div>


      </div>
    </div>
    <!-- //发票信息设置 -->

    <!-- //添加地址 -->

  </div>
</template>

<script>
import { formatDate } from '../../../utils/Date'
export default {

  props: {
    msg: String
  },
  data() {
    return {
      domainoptions: [],
      form: {
        domain: "",
        time: [],
        offset: 0,
        limit: 10,
        currentpage: 1,
      },
      currentpage: 0,
      total: 0,
      pickerOptions: {
        disabledDate(time) {
          let curDate = (new Date()).getTime();
          let three = 24 * 16 * 3600 * 1000;
          let threeMonths = curDate - three;

          return time.getTime() < threeMonths || time.getTime() > Date.now();
        }
      },
      logBsstimeFiList: [],
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let curDate = (new Date()).getTime();
    let three = 24 * 14 * 3600 * 1000;
    let threeMonths = curDate - three;
    this.form.time.push(
      String(this.formatDate(threeMonths)),
      String(this.formatDate(Date.now()))
    )
    this.getMemberDomainList()
  },
  methods: {
    formatDate(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    dpicChange() {

    },

    getMemberDomainList() {
      this.$apis.getMemberDomainList({
        mid: this.userInfo.id
      }).then((res) => {
        this.domainoptions = res.data

      })
    },
    download(url) {
      let a = document.createElement('a')
      a.href = url
      a.click();

    },
    logBsstimeFilesl() {
      this.$apis.logBsstimeFilesl({
        domain: this.form.domain,
        start: this.form.time[0],
        end: this.form.time[1],
        offset: this.form.offset,
        limit: this.form.limit,
        mid: this.userInfo.id
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg
          })
          this.logBsstimeFiList = res.data.rows
          this.logBsstimeFiList.forEach((el)=>{
              el.size = el.size>1000? el.size/1000+'KB':el.size>100000?el.size/100000+'MB':el.size+'B'
          })
          this.total = res.data.total
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }

      })
    },
    handleCurrentChange(val) {
      this.currentpage = val
      this.form.offset = (val - 1) * this.form.limit
      this.logBsstimeFilesl()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.logBsstimeFilesl()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

.selmain {
  margin: 20px 0 10px 0;

  /deep/.el-input__inner {
    // height: 30px;
    // width: 300px;
  }

  span {
    font-size: 14Px;
  }

  color: #666666;
}

.hedimg {
  width: 25px;
  height: 25px;
}







.deion {
  color: #004085;
  background-color: #CCE5FF;
  border-color: #b8daff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 400;

  h4 {
    margin: 0;
  }
}

.alert {
  span {
    width: 56px;
    height: 50px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin-right: 54px;
  }
}

.appbton {
  padding: 0;
  width: 59px;
  height: 30px;
  line-height: 0;
  border-radius: 4px;
  background-color: rgba(95, 165, 242, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
}

.tabdelt {
  width: 32px;
  height: 34px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 800;
  }

  .has-gutter {
    color: #666666;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}
</style>
