<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <div class="flex">
          <div class="flex">
            <el-select
              v-model="form.domain"
              placeholder="请选择域名"
              clearable
              multiple
              collapse-tags
              class="domaiselct"
            >
              <el-option
                v-for="item in domainoptions"
                :key="item.domain"
                :label="item.domain"
                :value="item.domain"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.httpCode"
              placeholder="请选择"
              clearable
              class="mg-lt-10"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flex flow sjfw mg-lt-10">
            <el-tabs v-model="form.sortType" type="card" class="tretab">
              <el-tab-pane label="流量优先" name="flow"></el-tab-pane>
              <el-tab-pane label="访问次数优先" name="request"></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="flex flow mg-tp-23 mg-bot-10">
          <el-tabs
            v-model="form.timeType"
            type="card"
            @tab-click="handletime"
            class="tretab"
          >
            <el-tab-pane label="今天" name="1"></el-tab-pane>
            <el-tab-pane label="昨天" name="2"></el-tab-pane>
            <el-tab-pane
              label="自定义"
              name="3"
              v-if="customShow"
            ></el-tab-pane>
          </el-tabs>
          <el-date-picker
            v-if="!customShow"
            v-model="time"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            ref="picker"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-button type="primary" class="inquire" @click="inQuire"
            >查询</el-button
          >
        </div>
        <div>
          <div>
            <el-table
              :data="analysisTopList"
              style="width: 100%"
              empty-text="暂无数据..."
            >
              <el-table-column prop="rank" label="排行" width="80">
              </el-table-column>
              <el-table-column label="URL" width="390" prop="uri">
                <template slot-scope="scope">
                  {{ scope.row.port == 80 ? "http://" : "https://"
                  }}{{ scope.row.channel + scope.row.uri }}
                </template>
              </el-table-column>
              <el-table-column prop="flow" width="200" label="流量">
              </el-table-column>
              <el-table-column
                prop="flowRatio"
                label="流量占比(%)"
                width="200"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="request"
                label="访问次数"
                width="200"
                align="center"
              >
              </el-table-column>
              <el-table-column
                label="访问占比(%)"
                align="center"
                prop="requestRatio"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- //购买证书 -->
  </div>
</template>

<script>
import { formatting } from "../../../../../utils/Date";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      time: [],
      customShow: true,
      domainoptions: [],
      options: [
        {
          value: "2XX",
          label: "2XX",
        },
        {
          value: "3XX",
          label: "3XX",
        },
        {
          value: "4XX",
          label: "4XX",
        },
        {
          value: "5XX",
          label: "5XX",
        },
      ],
      form: {
        httpCode: [],
        sortType: "flow",
        timeType: "1",
      },
      //时间限制
      pickerOptions: {
        disabledDate(time) {
          // let startDate = startyear + '-' + (new Date().getMonth() + 1) + '-' +
          //   new Date().getDate();//限制12个月,限制到天
          let curDate = new Date().getTime();
          let three = 180 * 24 * 3600 * 1000;
          let threeMonths = curDate + three;
          let threeMo = curDate - three;
          return time.getTime() > threeMonths || time.getTime() < threeMo;
        },
      },
      analysisTopList: [],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getMemberDomainList();
    this.analysisTopUrl();
  },
  methods: {
    inQuire() {
      if (!this.time && this.form.timeType == "3") {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
      } else {
        this.analysisTopUrl();
      }
    },
    httpChange() {
      this.form.httpCode = this.form.httpCode.join(",");
    },
    dpicChange() {
      this.form.timeType = "";
    },
    analysisTopUrl() {
      let GB = 1000 * 1000 * 1000;
      let MB = 1000 * 1000;
      let KB = 1000;
      this.$apis
        .analysisTopUrl({
          mid: this.userInfo.id,
          domain: this.form.domain.join(","),
          httpCode: this.form.httpCode,
          sortType: this.form.sortType,
          timeType: this.form.timeType,
          start: this.time[0],
          end: this.time[1],
        })
        .then((res) => {
          this.analysisTopList = res.data;
          this.analysisTopList.forEach((el) => {
            el.flow =
              el.flow > GB
                ? (el.flow / GB).toFixed(2) + "GB"
                : el.flow > MB
                ? (el.flow / MB).toFixed(2) + "MB"
                : el.flow > KB
                ? (el.flow / KB).toFixed(2) + "KB"
                : el.flow + "B";
          });
        });
    },
    comPile() {
      this.$emit("comPile");
    },
    handletime() {
      if (this.form.timeType == "3") {
        this.customShow = false;
        this.time = [];
        const end = new Date();
        const start = new Date();
        // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.time.push(
          String(formatting(start) + " 00:00:00"),
          String(formatting(end) + " 23:59:59")
        );
      } else {
        this.customShow = true;
        this.time = "";
      }
    },
    getMemberDomainList() {
      this.$apis
        .getMemberDomainList({
          mid: this.userInfo.id,
        })
        .then((res) => {
          this.domainoptions = res.data;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-date-editor {
  width: 300px !important;
}

/deep/.el-icon-arrow-right {
  display: none;
}
/deep/.el-icon-arrow-left {
  display: none;
}
.inquire {
  border-radius: 4px;
  background-color: rgba(22, 132, 252, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  padding: 8px 10px !important;
  margin-left: 20px;
  border: none;
}

/deep/.el-tabs__nav-scroll {
  padding: 0 !important;
}

/deep/.el-tabs__item {
  width: 100px !important;
  height: 30px !important;
}

.flow /deep/.el-tabs__nav .is-active {
  color: #fff !important;
  background-color: #1684fc !important;
  border: none !important;
}

/deep/.el-tabs__nav {
  border: none;
  border-radius: 3px;
}

.flow /deep/.el-tabs__nav .el-tabs__item {
  width: 65px;
  height: 25px;
  line-height: 30px;
  padding: 0 !important;
  color: #000000;
  border-color: #3695fc;
  text-align: center;
  border: 1px solid #eaeaea;
  // border: none;
}

/deep/.el-tabs__header {
  border: none;
  margin: 0;
}

/deep/.eForm {
  margin-top: 20px;

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-form-item__label {
    text-align: left;
    width: 80px;
  }

  a {
    color: #4249f1;
  }

  .el-radio-group {
    display: flex;
    align-items: center;
    margin-top: 13px;

    .el-radio__label {
      font-size: 12px;
    }

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    // margin-top: 5px;
  }
}
.shuxian {
  width: 1px;
  height: 10px;
  background-color: #666666;
  display: inline-block;
  margin: 0 3px;
}

.tabimg {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

/deep/.el-table thead {
  color: #666666;
  font-weight: bold;
  font-size: 20px !important;
}

/deep/.el-table__body-wrapper {
  .tabthre {
    width: 15px;
    height: 15px;
  }

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 10px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.el-button {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
