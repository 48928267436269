<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <h3 class="cz flex">
          <img src="@/assets/img/fyzhd.png" alt="" srcset="" class="hedimg">
          <span> 费用账单</span>

        </h3>
        <div class="alert-primary">
          <span>
            - 账期区分以扣费周期为准，举例来说：你在 1 月 31 日 22:00~23:59 使用的按小时结算的按量计费资源，或 1 月 31 日当天使用的按量计费日结资源，实际扣费时间发生在 2 月 1
            日，则按扣费周期，该笔费用统计到 2 月账单。
          </span>
        </div>
        <div>
          <div class="flex">
            <div class="sjfw">
              <span class="chars font-14">账期:</span>
              <el-date-picker v-model="dateTime" format="yyyy:MM" type="month" placeholder="选择月" :clearable="false" @change="dateTimeChange">
              </el-date-picker>
            </div>
            <div class="sjfw">
              <span class="chars font-14">产品:</span>
              <el-select v-model="productType" placeholder="请选择" @change="productTypeChange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

          </div>

          <div class="allcost">
            <span class="flex">
              总费用：<label>￥{{ totalMoney }} </label>
              <img src="@/assets/img/jis.png" title="当前月份出账未完成，所列费用不是最终的本月账单费用，仅供参考" alt="" srcset="" class="ruleimg">
              <!-- （现金支付 ￥0 + 收益金支付 ￥0 + 代金券支付 ￥0） -->
            </span>
          </div>
          <div class="ncoinc">
            <el-table :data="billList" style="width: 100%" empty-text="暂无符合条件的收支记录...">
              <el-table-column prop="createTime" label="日期" align="center">
              </el-table-column>
              <el-table-column prop="productName" label="产品名称" align="center">
              </el-table-column>

              <el-table-column prop="payPrice" label="产生费用" align="center">
                <!-- <template>
                  <div class="flex">
                    <img src="@/assets/img/shanchu.png" alt="" srcset="" class="tabdelt">
                    <el-button type="primary" @click="dialogVisible = true" class="appbton">申请退款</el-button>
                  </div>

                </template> -->
              </el-table-column>
            </el-table>
          </div>

        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
          :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
          class="flex just-center mg-tp-23">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
      value: '',
      dateTime: '',
      radio: "",
      productType: "",
      num: 1,
      currentpage:0,
      value1: "",
      options: [
        {
          value: '0',
          label: 'CDN'
        },
        {
          value: '1',
          label: 'SSL'
        },
        {
          value: '',
          label: '全部产品'
        },


      ],
      userInfo: {},
      billList: [],
      offset: 0,
      limit: 10,
      total: 0,
      totalMoney: "",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getExpenseBill()
  },
  methods: {
    dateTimeChange(value) {
      let yy = new Date(value).getFullYear();
      let mm = new Date(value).getMonth() + 1;
      this.dateTime = yy + "-" + mm;
      this.getExpenseBill()
    },
    productTypeChange() {
      this.getExpenseBill()
    },
    handleCurrentChange(val) {
      this.currentpage=val
      this.offset = (val - 1) * this.limit
      this.getExpenseBill()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getExpenseBill()
    },
    getExpenseBill() {
      this.$apis.getExpenseBill({
        mid: this.userInfo.id,
        dateTime: this.dateTime,
        productType: this.productType,
        offset: this.offset,
        limit: this.limit
      }).then((res) => {
        this.billList = res.data.billList.rows
        this.totalMoney = res.data.totalMoney
        this.total = res.data.billList.total
      })
    },
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

.hedimg {
  width: 25px;
  height: 25px;
}

.ncoinc {
  text-align: center;
  // margin-top: px;
  color: #888;
  font-size: 14px;


}

.allcost {
  margin-top: 10px;

  .ruleimg {
    margin: 0 6px 0 2px;
    width: 10px;
    height: 10px;
  }

  label {
    color: #33ba4a;
    font-size: 16px !important;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    color: #666;
  }
}

.polriza {
  margin: 20px 0;

  /deep/.el-radio__label {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }

  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #3E51B5;
    background: #3E51B5;

  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #666;
  }

  .perod {
    margin-left: 35px;

    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: rgba(0, 0, 0, .26);
    }

    span {
      color: rgba(0, 0, 0, .26);
    }
  }
}

.alert-primary {
  font-size: 80%;
  font-weight: 400;
  margin-bottom: 20px;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  padding: 10px 10px;
}

/deep/.el-input__prefix {
  display: none;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
  }

  /deep/.el-input__inner {
    width: 200px;
    // color: rgba(16, 16, 16, 1);
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }

}



/deep/ .el-input__icon {
}


</style>
