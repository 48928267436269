// rem等比适配配置文件
// 基准大小
const baseSize = 14;
// 设置 rem 函数
function setRem() {
  // 当前页面屏幕分辨率相对于1920宽的缩放比例，可根据自己需要修改
  let scale = document.documentElement.clientWidth / 1440;
  // 下面这一行代码可以忽略，这是我另外加的，我加这行代码是为了屏幕宽度小于1280时就不继续等比缩放了
  if (document.documentElement.clientWidth < 1024) scale = 1024 / 1440
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize = `${baseSize * Math.min(scale, 1.1)}px`;
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = () => {
  //  document.body.style.zoom = 1 / window.devicePixelRatio;
  setRem();
};