import vue from 'vue'
import vueRouter from 'vue-router'
import register from '../view/Login/register.vue'
import homage from '../view/homage/index.vue'
import personter from '../view/personter/index.vue'
import expensnter from '../view/expensnter/index.vue'
import fusecdn from '../view/fusecdn/index.vue'
import sslertificate from '../view/sslertificate/index.vue'
vue.use(vueRouter)
const router = new vueRouter({
    routes:[
        //路由重定向
        { path:'/',redirect:'/register' },
      //在 routes 数组中，申明路由的匹配规则
      //path 表示要匹配的 hash 地址；component 表示要展示的路由组件
      { path:'/register',//登录
      component:register,
     },
     { path:'/homage',//首页
     component:homage,
    },
    { path:'/personter', //个人中心
    component:personter,
   },
   { path:'/expensnter', //费用中心
   component:expensnter,
  },
  { path:'/fusecdn', //融合CDN
   component:fusecdn,
  },
  { path:'/sslertificate', //SSL证书
  component:sslertificate,
 },
    ]
  })

export default router