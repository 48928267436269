<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain"></div>
    </div>
    <div class="puricate">
      <div class="dawer">
        <div>
          <div>
            <div class="flex">
              <span class="cho">缓存设置</span>
              <el-switch
                v-model="vaswchit"
                active-color="#1684FC"
                inactive-color="#909399"
                @change="vasChange"
                :disabled="examine"
              >
              </el-switch>
              <span class="cho f-400">默认缓存</span>
            </div>
            <div>
              <el-form :model="ruleForm" :rules="rules" class="eForm">
                <el-form-item prop="name" class="cating">
                  <div class="">
                    <el-button
                      type="primary"
                      class="Fbtn"
                      @click="AddCache"
                      v-if="!examine"
                      >添加</el-button
                    >
                    <small class="mg-lt-10" v-if="!examine"
                      >支持配置自定义资源的缓存过期时间规则，支持指定路径或者文件名后缀方式。</small
                    >
                    <p>全局默认优先遵循源站缓存</p>
                    <p>
                      默认开启去问号参数缓存，若需要带问号后参数缓存，请选择关闭该功能
                    </p>
                    <p>权重支持自定义生效顺序，优先级数字大则优先生效。</p>
                    <el-table :data="tablehtps">
                      <el-table-column
                        prop="cache_type"
                        label="类型"
                        width="160"
                      > 
                        <template slot-scope="scope">
                          <div>
                            {{
                              scope.row.mode == 0
                                ? "文件后缀"
                                : scope.row.mode == 1
                                ? "目录"
                                : scope.row.mode == 2
                                ? "首页"
                                : scope.row.mode == 3
                                ? "全部文件"
                                : scope.row.mode == 4
                                ? "全部路径"
                                : ""
                            }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="file_type"
                        label="内容"
                        width="160"
                      >
                      </el-table-column>
                      <el-table-column prop="ttl" label="过期时间" width="160">
                        <template slot-scope="scope">
                          <div>
                            {{ scope.row.ttl
                            }}{{
                              scope.row.timeType == 0
                                ? "天"
                                : scope.row.timeType == 1
                                ? "小时"
                                : scope.row.timeType == 2
                                ? "分钟"
                                : scope.row.timeType == 3
                                ? "秒"
                                : ""
                            }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="cache_type"
                        label="缓存规则"
                        width="300"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <div>
                            {{
                              scope.row.cache_type == 1
                                ? "不缓存"
                                : scope.row.cache_type == 2
                                ? "遵循源站"
                                : scope.row.cache_type == 3
                                ? "强制缓存"
                                : ""
                            }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="cache_with_args"
                        label="去问号缓存"
                        width="200"
                      > 
                        <template slot-scope="scope"> 
                          <div>
                            {{
                              scope.row.cache_with_args==0   ? "开启" : "关闭"
                            }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="priority" label="权重" width="100">
                      </el-table-column>
                      <el-table-column prop="address" label="操作">
                        <template slot-scope="scope">
                          <div>
                            <span
                              class="color-7D"
                              @click="modiNfigu(scope.row, scope.$index)"
                              v-if="!examine"
                              >修改</span
                            >
                            <small
                              class="mg-lt-10 color-7D"
                              @click="removeNfigu(scope.$index)"
                              v-if="!examine"
                              >删除</small
                            >
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div></div>
                </el-form-item>
                <div class="flex al-start">
                  <span class="cho">Http响应头</span>
                </div>
                <el-form-item prop="name" class="cating">
                  <div class="souader">
                    <div class="flex addion">
                      <el-button
                        type="primary"
                        class="Fbtn"
                        @click="Addstationnifg"
                        v-if="!examine"
                        >添加</el-button
                      >
                      <p class="mg-lt-10">
                        管理回源HTTP请求头，可支持新增、修改、删除，返回特定的HTTP
                        Hearder参数信息给客户端。
                      </p>
                    </div>

                    <el-table :data="resp_headers" style="width: 100%">
                      <el-table-column prop="key" label="参数">
                      </el-table-column>
                      <el-table-column prop="value" label="取值">
                      </el-table-column>
                      <el-table-column prop="address" label="操作">
                        <template slot-scope="scope">
                          <div>
                            <span
                              class="color-7D"
                              @click="Modse(scope.$index, 1)"
                              v-if="!examine"
                              >修改</span
                            >
                            <small
                              class="mg-lt-10 color-7D"
                              @click="heardHttpremove(scope.$index)"
                              v-if="!examine"
                              >删除</small
                            >
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-form>
              <!-- <div class="text-cer mg-tp-50 ">
                <el-button type="primary" class="sacommit">保存提交</el-button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //默认缓存 -->
    <div class="dcache">
      <el-dialog :visible.sync="souVisible">
        <div slot="title">
          <span>默认缓存</span>
        </div>
        <el-divider></el-divider>
        <div>
          <div class="regul">
            <p>全局默认优先遵循源站缓存</p>
            <p>
              默认开启去问号参数缓存，若需要带问号后参数缓存，请选择关闭该功能
            </p>
            <p>权重支持自定义生效顺序，优先级数字大则优先生效。</p>
          </div>

          <div class="flex">
            <div class="">
              <el-form
                :model="DeftCByDo[index]"
                :rules="DeftCBrules"
                ref="DeftCForm"
                class="demo-ruleForm"
                v-for="(item, index) in DeftCByDo"
                :key="item.id"
              >
                <div>
                  <!-- <el-form-item label="缓存名字" class="" prop="name">
                    <el-input v-model="DeftCByDo[index].name"></el-input>
                  </el-form-item> -->
                  <ul class="flex just-center">
                    <li class="al-flex-end">
                      <el-form-item label="类型" class="" prop="mode">
                        <el-select
                          v-model="DeftCByDo[index].mode"
                          placeholder="请选择类型"
                        >
                          <el-option label="文件后缀" value="0"></el-option>
                          <el-option label="目录" value="1"></el-option>
                          <el-option label="首页 " value="2"></el-option>
                          <el-option label="全部文件" value="3"></el-option>
                          <el-option label="全路径" value="4"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="缓存规则" class="" prop="cache_type">
                        <el-select
                          v-model="DeftCByDo[index].cache_type"
                          placeholder="请选择缓存规则"
                        >
                          <el-option label="不缓存" value="1"></el-option>
                          <el-option label="优先遵循源站" value="2"></el-option>
                          <el-option label="强制缓存" value="3"></el-option>
                        </el-select>
                      </el-form-item>
                    </li>
                    <li class="al-end">
                      <el-form-item label="内容" class=" " prop="file_type">
                        <el-input
                          v-model="DeftCByDo[index].file_type"
                          placeholder="请输入内容"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="去问号缓存"
                        class=""
                        prop="cache_with_args"
                      >
                        <el-switch
                          v-model="DeftCByDo[index].cache_with_args"
                          active-color="#FA8334"
                          inactive-color="#909399"
                          @change="souVisible = true"
                        >
                        </el-switch>
                      </el-form-item>
                    </li>
                    <li class="flex-column al-flex-end">
                      <el-form-item label="缓存时间" class="" prop="ttl">
                        <el-input
                          v-model="DeftCByDo[index].ttl"
                          placeholder="请输入内容"
                        >
                          <template slot="append">
                            <el-select
                              v-model="DeftCByDo[index].timeType"
                              placeholder="请选择"
                              class="hcInput"
                            >
                              <el-option
                                v-for="item in DefacheOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </template>
                          <!-- <template slot="append">
                            <div class="flex just-bet">
                              <span class="">{{
                                DeftCByDo[index].timeType == 0 ? '天' : DeftCByDo[index].timeType == 1 ? '小时' :
                                DeftCByDo[index].timeType == 2 ? '分钟' : DeftCByDo[index].timeType == 3 ? '秒' : ""
                              }} </span>
                              <i class="el-icon-arrow-down" style="letter-spacing:0"></i>
                            </div>
                          </template> -->
                        </el-input>
                      </el-form-item>
                      <el-form-item label="权重" class=" " prop="priority">
                        <div class="flex">
                          <el-input
                            v-model="DeftCByDo[index].priority"
                            placeholder="请输入权重"
                            class="winput"
                          ></el-input>
                          <!-- <img src="@/assets/img/quanz.jpg" alt="" srcset="" class="weiimg"
                            @click="removeDefaultCache(item.id)"> -->
                        </div>
                      </el-form-item>
                    </li>
                    <!-- <div class="mg-lt-10">
                      <el-button type="primary" @click="updateDefaultCache(item.id, index)" class="confirm">修改</el-button>
                    </div> -->
                  </ul>
                  <!-- <el-button type="primary" @click="addDefe(index)" class="confirm"
                    v-if="Deftcundex == index">添加</el-button> -->
                </div>
              </el-form>
            </div>
          </div>
          <p class="mg-lt-30 mg-tp-10">
            支持自定义生效顺序，优先级数字大则优先生效。
          </p>
        </div>
        <el-divider class="fotdiv"></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelSouv" class="cancel">取 消</el-button>
          <el-button type="primary" @click="vaswcChange" class="confirm"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- //新增默认缓存 -->
    <div class="newche">
      <el-dialog
        :visible.sync="newcheVisible"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <div slot="title">
          <span>{{ mNfigutitle }}缓存设置</span>
        </div>
        <el-divider></el-divider>
        <div>
          <el-form ref="newcform" :model="Defacache" :rules="newcherules">
            <!-- <el-form-item label="默认缓存">
              <el-select v-model="DeftCByDtype" placeholder="请选择类型" @change="DeftCByChange">
                <el-option v-for="item in Defca" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="类型">
              <el-radio-group v-model="Defacache.mode" @change="modeChange">
                <el-radio label="0">后缀名</el-radio>
                <el-radio label="1">目录</el-radio>
                <el-radio label="2">首页</el-radio>
                <el-radio label="3">全部文件</el-radio>
                <el-radio label="4">全路径文件</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="内容" prop="file_type">
              <el-input
                v-model="Defacache.file_type"
                :placeholder="placeholder"
              ></el-input>
            </el-form-item>
            <el-form-item label="过期时间" prop="ttl">
              <el-input v-model="Defacache.ttl" placeholder="请输入过期时间">
                <template slot="append">
                  <el-select v-model="time" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="缓存规则">
              <el-radio-group v-model="Defacache.cache_type">
                <el-radio label="1">不缓存</el-radio>
                <el-radio label="2">优先遵循源站</el-radio>
                <el-radio label="3">强制缓存</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="去问号缓存">
              <el-switch v-model="Defacache.cache_with_args"></el-switch>
            </el-form-item>
            <el-form-item label="权重">
              <el-input-number
                v-model="Defacache.priority"
                :min="10"
                :max="100"
                label="jpg.png.css(以','分割)"
                :controls="false"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </div>
        <el-divider class="fotdiv"></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelnewche" class="cancel">取 消</el-button>
          <el-button
            type="primary"
            @click="newNfigu('newcform')"
            class="confirm"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- //添加HTTPS响应头 -->
    <div class="htpche">
      <el-dialog :visible.sync="htaddVisible">
        <div slot="title">
          <span>{{ figherdtitle }}HTTP响应头</span>
        </div>
        <el-divider></el-divider>
        <div>
          <el-form
            ref="nfiguform"
            :model="nfiguForm"
            label-width="80px"
            :rules="nfigurules"
          >
            <el-form-item label="参数" prop="key">
              <div>
                <el-input v-model="nfiguForm.key"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="取值" prop="name">
              <div>
                <el-input v-model="nfiguForm.value"></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <el-divider class="fotdiv"></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="htaddVisible = false" class="cancel"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="addhttp('nfiguform')"
            class="confirm"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
    dovervilist: Object, //信息对象
    examine: Boolean, //编辑还是查看
  },
  data() {
    return {
      souVisible: false,
      vaswchit: false,
      newcheVisible: false,
      htaddVisible: false,
      placeholder: "",
      DeftCByDtype: "",
      time: "3",
      Defca: [],
      DefacheOptions: [
        {
          value: "3",
          label: "天",
        },
        {
          value: "2",
          label: "小时",
        },
        {
          value: "4",
          label: "分钟",
        },
        {
          value: "1",
          label: "秒",
        },
      ],
      options: [
        {
          value: "0",
          label: "天",
        },
        {
          value: "1",
          label: "小时",
        },
        {
          value: "2",
          label: "分钟",
        },
        {
          value: "3",
          label: "秒",
        },
      ],
      Cacfiguration: [],
      rules: {},
      nfiguForm: {
        key: "",
        value: "",
        name: "",
        region: "CDN加速",
      },
      DeftCByDo: [
        {
          cache_type: "",
          mode: "",
          file_type: "",
          cache_with_args: "",
          ttl: "",
          priority: "",
          timeType: "",
        },
      ],
      ruleForm: {
        name: "",
        cacheType: "",
        mode: "",
        fileType: "",
        cacheWithArgs: false,
        ttl: "",
        priority: "",
      },
      nfigurules: {
        key: [{ required: true, message: "请输入取值", trigger: "change" }],
      },
      newcherules: {
        file_type: [
          { required: true, message: "请输入缓存内容", trigger: "change" },
        ],
        ttl: [{ required: true, message: "请输入过期时间", trigger: "change" }],
      },
      DeftCBrules: {
        name: [
          { required: true, message: "请输入缓存名字", trigger: "change" },
        ],
        mode: [{ required: true, message: "请选择类型", trigger: "change" }],
        cacheType: [
          { required: true, message: "请输入缓存规则", trigger: "change" },
        ],
        fileType: [
          { required: true, message: "请输入内容", trigger: "change" },
        ],
        cacheWithArgs: [
          {
            required: true,
            message: "请选择是否去问好缓存",
            trigger: "change",
          },
        ],
        ttl: [{ required: true, message: "请输入缓存时间", trigger: "change" }],
        cacheWithArgs: [
          {
            required: true,
            message: "请选择是否去问好缓存",
            trigger: "change",
          },
        ],
        priority: [
          { required: true, message: "请输入权重", trigger: "change" },
        ],
      },
      tableData: [],
      tablehcundex: 0,
      Deftcundex: 0,
      cudexheadr: 0,
      Defacache: {
        cache_type: "1",
        cache_with_args: false,
        mode: "0",
        file_type: "",
        priority: "",
        ttl: "",
      },
      tablehtps: [],
      resp_headers: [],
      headersid: "",
      mNfigutitle: "",
      figherdtitle: "",
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.getMemberDomainDetail();
    this.getDefaultCacheByDomain();
    this.member();
  },
  methods: {
    vasChange() {
      if (this.vaswchit) {
        this.souVisible = true;
        this.getDefaultCacheByDomain();
      } else {
        let arr = this.tablehtps.filter((el) => {
          return !el.defaultCacheId;
        });
        this.tablehtps = arr;
      }
    },

    member() {
      this.$apis
        .member({
          mid: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.isDefaultCache == "1") {
            this.vaswchit = true;
          } else {
            this.vaswchit = false;
          }
        });
    },
    cancelSouv() {
      this.souVisible = false;
    },
    vaswcChange() {
      if (this.vaswchit) {
        this.souVisible = false;
        let arr = [];

        this.DeftCByDo.map((el) => {
          arr.push({
            timeType:
              el.timeType == 1
                ? "3"
                : el.timeType == 2
                ? "1"
                : el.timeType == 3
                ? "0"
                : el.timeType == 4
                ? "2"
                : "",
            cache_type: el.cache_type,
            cache_with_args: el.cache_with_args ? 0 : 1,
            mode: el.mode,
            file_type: el.file_type,
            priority: el.priority,
            ttl: parseInt(el.ttl),
            defaultCacheId: el.defaultCacheId,
          });
        });
        arr.forEach((el) => {
          this.tablehtps.push(el);
        });
        this.$nextTick(() => {
          this.updateDefaultCache();
        });
      } else {
        // this.tablehtps = []
      }
    },

    DeftCByChange(value) {
      this.getDefaultCacheById(value);
    },
    getDefaultCacheById(id) {
      this.tablehcundex = this.tablehcundex;
      this.$apis
        .getDefaultCacheById({
          id: id,
        })
        .then((res) => {
          this.Defacache.mode = String(res.data.mode);
          this.Defacache.file_type = res.data.fileType;
          this.Defacache.ttl = res.data.ttl;
          this.Defacache.cache_type = String(res.data.cacheType);
          this.Defacache.cache_with_args =
            res.data.cacheWithArgs == 0 ? true : false;
          this.Defacache.priority = res.data.priority;
        });
    },
    removeNfigu(index) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tablehtps.splice(index, 1);
          this.increUpdateMemberDomain();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cancelnewche() {
      this.newcheVisible = false;
    },
    modiNfigu(data, index) {
      console.log(data,'555')
      this.tablehcundex = index;
      this.mNfigutitle = "修改";
      this.Defacache.cache_type = String(data.cache_type);
      this.Defacache.cache_with_args = data.cache_with_args==0 ? true:false
      this.Defacache.mode = String(data.mode);
      this.Defacache.file_type = data.file_type;
      this.Defacache.priority = data.priority;
      this.Defacache.ttl = data.ttl;
      this.time = String(data.timeType);
      this.newcheVisible = true;
    },
    modeChange() {
      if (this.tablehtps[this.tablehcundex].mode == 0) {
        this.placeholder = "jpg,png,css( 以””分割)";
      } else if (this.tablehtps[this.tablehcundex].mode == 1) {
        this.placeholder = "/test,/a/b/c( 不能以”“结尾)";
      } else if (this.tablehtps[this.tablehcundex].mode == 2) {
        this.placeholder = "类型固定为/";
      } else if (this.tablehtps[this.tablehcundex].mode == 3) {
        this.placeholder = "类型固定为/";
      } else if (this.tablehtps[this.tablehcundex].mode == 4) {
        this.placeholder = "/index.html,/test/*jpg";
      }
    },
    AddCache() {
      this.mNfigutitle = "新增";
      this.DeftCByDtype = "";
      (this.Defacache = {
        cache_type: "1",
        cache_with_args: false,
        mode: "0",
        file_type: "",
        priority: "",
        ttl: 0,
        timeType: this.time,
      }),
        (this.newcheVisible = true);
    },
    //获取详情
    getMemberDomainDetail() {
      this.$apis
        .getMemberDomainDetail({
          domain: this.dovervilist.domain,
          mid: this.userInfo.id,
        })
        .then((res) => {
          this.tablehtps =
            res.filetype_ttl.length > 0 ? res.filetype_ttl : this.tablehtps;
          let arr = [];
          this.tablehtps.forEach((el) => {
            arr.push({
              file_type: el.fileType,
              priority: el.priority,
              id: el.id, 
              cache_with_args: el.cacheWithArgs  ,
              mode: String(el.mode),
              cache_type: String(el.cacheType),
              timeType: String(el.timeType) == 4 ? "2" : String(el.timeType),
              ttl: parseInt(el.ttl),
              defaultCacheId: el.defaultCacheId,
            });
          });
          this.tablehtps = arr;
          this.resp_headers = res.data.resp_headers;
        });
    },
    heardHttpremove(index) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.resp_headers.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //确定添加
    newNfigu(newcform) {
    console.log(this.Defacache,'666')
      this.$refs[newcform].validate((valid) => {
        if (valid) {
          if (this.mNfigutitle == "修改") {
            this.newcheVisible = false;
            this.tablehtps[this.tablehcundex].cache_type =
              this.Defacache.cache_type;
            this.tablehtps[this.tablehcundex].cache_with_args =
              this.Defacache.cache_with_args?0:1;
            this.tablehtps[this.tablehcundex].mode = this.Defacache.mode;
            this.tablehtps[this.tablehcundex].file_type =
              this.Defacache.file_type;
            this.tablehtps[this.tablehcundex].priority =
              this.Defacache.priority;
            this.tablehtps[this.tablehcundex].ttl = this.Defacache.ttl;
            this.tablehtps[this.tablehcundex].timeType = this.time;
          } else {
            this.Defacache.cache_with_args = this.Defacache.cache_with_args?0:1 
            this.tablehtps.push(this.Defacache);
            this.tablehtps[this.tablehtps.length - 1].timeType = this.time;

            this.newcheVisible = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "请填写完整",
          });
        }
      });
    },
    getData() {
      let arr = [];
      arr = JSON.stringify(this.tablehtps);

      let form = {
        filetype_ttl: JSON.parse(arr),
        resp_headers: this.resp_headers,
        vaswchit: this.vaswchit, //是否开启默认缓存
      };
      return form;
    },
    increUpdateMemberDomain() {
      // this.$apis.increUpdateMemberDomain(JSON.stringify({
      //   filetype_ttl: this.tablehtps,
      //   domain: this.dovervilist.domain,
      //   resp_headers: this.resp_headers,
      // })).then((res) => {
      //   if (res.code == 0) {
      //     this.newcheVisible = false
      //     this.$message({
      //       type: "success",
      //       message: res.msg
      //     })
      //     this.getMemberDomainDetail()
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: res.msg
      //     })
      //   }
      // })
    },
    //查询默认缓存
    getDefaultCacheByDomain() {
      this.DeftCByDo = [];
      this.$apis
        .getDefaultCacheByDomain({
          mid: this.userInfo.id,
        })
        .then((res) => {
          this.DeftCByDo = res.data.length > 0 ? res.data : this.DeftCByDo;
          this.Defca = res.data.length > 0 ? res.data : this.Defca;
          this.Deftcundex = this.DeftCByDo.length - 1;
          let arr = [];
          this.DeftCByDo.forEach((el) => {
            arr.push({
              file_type: el.fileType,
              priority: el.priority,
              defaultCacheId: el.id, 
              cache_with_args: el.cacheWithArgs == 0 ? true : false,
              mode: String(el.mode),
              cache_type: String(el.cacheType),
              timeType: String(el.timeType),
              ttl:
                el.timeType == 1
                  ? parseInt(el.ttl)
                  : el.timeType == 2
                  ? parseInt(el.ttl / 60 / 60)
                  : el.timeType == 3
                  ? parseInt(el.ttl / (24 * 60 * 60))
                  : el.timeType == 4
                  ? parseInt(el.ttl / 60)
                  : "",
            });
          });
          this.DeftCByDo = arr;
          // this.vaswcChange()
        });
    },
    removeDefaultCache(id) {
      this.$apis
        .removeDefaultCache({
          id: id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.souVisible = false;
          this.vaswchit = false;
          this.getDefaultCacheByDomain();
        });
    },
    //修改默认缓存
    updateDefaultCache(id, index) {
      this.Deftcundex = index;
      this.DeftCByDo.forEach((el) => {
        el.id = el.defaultCacheId;
        el.timeType == 1
          ? "3"
          : el.timeType == 2
          ? "1"
          : el.timeType == 3
          ? "0"
          : "",
          (el.cache_with_args = el.cache_with_args ? 0 : 1);
        el.mid = this.userInfo.id;
        el.domain = this.dovervilist.domain;
        el.ttl =
          el.timeType == 1
            ? Number(el.ttl)
            : el.timeType == 2
            ? Number((el.ttl * 60 * 60).toFixed(2))
            : el.timeType == 3
            ? Number((el.ttl * 24 * 60 * 60).toFixed(2))
            : "";
      });
      this.$apis
        .updateDefaultCache(
          JSON.stringify({
            defaultCaches: this.DeftCByDo,
          })
        )
        .then((res) => {
          if (res.code == 0) {
            this.souVisible = false;
            this.$message({
              type: "success",
              message: "修改成功",
            });
            // this.getDefaultCacheByDomain()
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    // addDefe(index) {
    //   this.Deftcundex = index+1
    //   this.DeftCByDo.push({
    //     cacheType: "1",
    //     mode: "",
    //     fileType: "",
    //     cacheWithArgs: "",
    //     ttl: "",
    //     priority: "",
    //     mid: this.userInfo.id,
    //     domain: this.dovervilist.domain,
    //   })
    // },

    //修改HTTPS响应头
    Modse(index, id) {
      this.cudexheadr = index;
      this.headersid = id;
      this.figherdtitle = "修改";
      this.htaddVisible = true;
      this.nfiguForm.key = this.resp_headers[this.cudexheadr].key;
      this.nfiguForm.value = this.resp_headers[this.cudexheadr].value;
    },
    Addstationnifg() {
      this.figherdtitle = "添加";
      this.htaddVisible = true;
      this.nfiguForm.key = "";
      this.nfiguForm.value = "";
      this.headersid = "";
    },
    //新增HTTPS响应头
    addhttp(nfiguform) {
      this.$refs[nfiguform].validate((vaild) => {
        if (vaild) {
          if (this.figherdtitle == "修改") {
            this.resp_headers[this.cudexheadr].key = this.nfiguForm.key;
            this.resp_headers[this.cudexheadr].value = this.nfiguForm.value;
          } else {
            this.resp_headers.push({
              key: this.nfiguForm.key,
              value: this.nfiguForm.value,
            });
          }
          this.htaddVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "请输入完整",
          });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
.winput {
  width: 312px;
}

.hcInput {
  /deep/.el-input__inner {
    width: 100px !important;
  }

  /deep/.el-input__suffix {
    right: -10px !important;
  }
}

/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  width: 78%;
}

/deep/.el-switch__core {
  width: 30px !important;
  height: 18px !important;
  margin: 0 10px;
}

/deep/.el-table th.el-table__cell {
  background-color: #f7f7f7 !important;
  padding: 0 !important;

  .cell {
    font-weight: bold;
  }
}

.sacommit {
  padding: 5px 10px;
  background-color: #1684fc;
  text-align: center;
}

.htpche {
  p {
    color: #caced4;
    font-size: 12px;
    // margin-top: 10px;
    margin-left: 80px;
  }

  /deep/.el-radio-group {
    display: flex;
  }

  /deep/.el-radio {
    display: flex;
    align-items: center;

    .el-radio__label {
      font-size: 15px;
    }
  }

  .fotdiv {
    margin-top: 40px !important;
    margin-bottom: 10px;
  }

  .regul {
    margin-bottom: 20px;
    margin-left: 30px;
  }

  .weiimg {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }

  /deep/.el-input-group__append {
    width: 30px;
    padding: 0 10px;
  }

  /deep/.el-form-item__label {
    padding: 0;
    margin-right: 10px;
  }

  /deep/.el-input__icon {
    line-height: 50px;
    width: 0;
  }

  /deep/.el-switch__core {
    margin: 0;
  }

  .cancel {
    background-color: #6c757d;
    color: #fff;
    border: none;
  }

  .confirm {
    background-color: #007bff;
    color: #fff;
    border: none;
  }

  /deep/.el-form {
    padding: 0 20px;

    .el-form-item {
      display: flex;
      margin-bottom: 0;
      // height: 35px;
      align-items: center;

      .el-input__inner {
        width: 100%;
      }
    }
  }

  /deep/.el-dialog__header {
    span {
      font-size: 14px;
    }
  }

  /deep/.el-form-item__label {
    text-align: right;
    margin-right: 20px;
  }

  /deep/.el-select__caret {
    line-height: 40px;
  }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-divider {
    margin-top: 0;
  }
}

/deep/.el-form-item__content {
  line-height: 0;
  width: 80%;
  margin-left: 20px !important;
}

.newche {
  /deep/.el-radio-group {
    display: flex;
  }

  .el-input__icon {
    line-height: auto;
  }

  /deep/.el-radio {
    display: flex;
    align-items: center;

    .el-radio__label {
      font-size: 15px;
    }
  }

  .fotdiv {
    margin-top: 40px !important;
    margin-bottom: 10px;
  }

  .regul {
    margin-bottom: 20px;
    margin-left: 30px;
  }

  .weiimg {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }

  /deep/.el-input-group__append {
    width: 60px;
    padding: 0 10px;
  }

  /deep/.el-form-item__label {
    padding: 0;
  }

  // /deep/.el-input__icon {
  //   line-height: 50px;
  //   width: 0;
  // }

  /deep/.el-switch__core {
    margin: 0;
  }

  .cancel {
    background-color: #6c757d;
    color: #fff;
    border: none;
  }

  .confirm {
    background-color: #007bff;
    color: #fff;
    border: none;
  }

  /deep/.el-form {
    padding: 0 20px;

    .el-form-item {
      display: flex;
      margin-bottom: 30px;
      // height: 35px;
      align-items: center;

      .el-input__inner {
        // width: 260px;
      }
    }
  }

  /deep/.el-dialog__header {
    span {
      font-size: 14px;
    }
  }

  /deep/.el-form-item__content {
    line-height: 0;
    margin-left: 20px !important;
  }

  /deep/.el-form-item__label {
    text-align: right;
  }

  /deep/.el-input__inner {
    width: 100%;
  }

  // /deep/.el-select__caret {
  //   line-height: 40px;
  // }

  /deep/.el-select {
    width: 80px;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-divider {
    margin-top: 0;
  }
}

.dcache {
  .fotdiv {
    margin-top: 40px !important;
    margin-bottom: 10px;
  }

  .regul {
    margin-bottom: 20px;
    margin-left: 30px;
  }

  /deep/.el-form-item__content {
  }

  .weiimg {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }

  .el-dialog__header {
    span {
      font-size: 14px;
    }
  }

  /deep/.el-input-group__append {
    width: 30px;
    padding: 0 10px;
  }

  /deep/.el-form-item__label {
    padding: 0;
    margin: 0 10px;
    white-space: nowrap;
  }

  /deep/.el-input__icon {
    width: 0;
  }

  /deep/.el-switch__core {
    margin: 0;
  }

  .cancel {
    background-color: #6c757d;
    color: #fff;
    border: none;
  }

  .confirm {
    background-color: #007bff;
    color: #fff;
    border: none;
  }

  /deep/.el-form {
    // padding: 0 20px;
    .el-form-item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .el-input__inner {
        width: 230px;
        // height: -webkit-fill-available;
      }

      .el-input__suffix {
        right: 20px;
      }
    }
  }

  /deep/.el-form-item__content {
    // margin-left: 30px;
  }

  /deep/.el-form-item__label {
    text-align: left;
  }

  /deep/.el-select__caret {
    line-height: 40px;
  }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-divider {
    margin-top: 0;
  }
}

/deep/.eForm {
  margin-top: 20px;

  .cating {
    .el-form-item__content {
      margin-left: 20px !important;
    }
  }

  .souader {
    margin-top: 30px;

    .addion {
      margin-bottom: 10px;
    }

    .Fbtn {
      background-color: rgba(0, 123, 255, 1);
    }
  }

  .Fbtn {
    background-color: rgba(0, 123, 255, 1);
    color: rgba(255, 255, 255, 1);
    padding: 5px 10px;
    border: none;
  }

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  // .el-form-item__content {
  //   margin-left: 80px;
  // }

  .el-form-item__label {
    text-align: left;
  }

  p {
    // line-height: 10px !important;
  }

  .el-input__inner {
    width: 300px;
  }

  .el-form-item {
  }
}

.puricate {
  .cho {
    padding: 0 15px 0 0;
    font-size: 15px;
    color: #666666;
    font-weight: bold;
  }
}

li {
  list-style: none;
  display: flex;
  flex-direction: column;
}
</style>
