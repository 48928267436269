<template>
  <div class="regisbox">
    <span class="zc">注册</span>
    <div>
      <el-input v-model="mobile" placeholder="手机号" class="sinput" @change="mobiChange"></el-input>
      <el-input v-model="password" placeholder="设置密码 (大小字母+数字+特殊字符,至少8位)" show-password class="minput" @change="passChange">
        <!-- <div slot="suffix">
                <img src="../../assets/img/chak.png" alt="" srcset="" class="ck">
              </div> -->

      </el-input>
      <el-input v-model="smsCode" placeholder="验证码" class="minput">
        <span slot="append" @click="sendRegisterVerifyCode" class="curpoin">获取验证码</span>

      </el-input>
      <div class="icade">
        <el-input v-model="code" placeholder="请输入验证码" class="minput yzm">
          <div slot="append">
            <span class="Entifi">
              {{ idende }}
            </span>
          </div>
        </el-input>
        <div class="chane" @click="getproducts">
          <span>换一张</span>
        </div>
      </div>
      <el-input v-model="inviteCode" placeholder="请输入邀请码" class="minput"></el-input>
    </div>
    <div @click="memberRegister" class="mg-tp-10">
      <el-button type="primary">注册</el-button>
    </div>
    <div class="readsent">
      <el-checkbox v-model="checked">
        <div>
          <span style="color: #888888;">我已阅读并同意</span>
          <a href="https://upload.znidc.net/files/zhxy.html" target="_blank" rel="noopener noreferrer"><span
              style="color: #6C74F7 ">《思智云服务协议》</span></a>
          <a href="https://upload.znidc.net/files/ysxy.html" target="_blank" rel="noopener noreferrer"> <span
              style="color: #6C74F7 ">《隐私政策》</span></a>

        </div>
      </el-checkbox>
    </div>
    <div class="acount">
      <span style="color: #888888;">我有账号？</span>
      <span style="color: #6C74F7;cursor:pointer;" @click="sighay">立即登录</span>
    </div>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      password: "",
      mobile: "",
      smsCode: "",
      inviteCode: "",
      code: "",
      checked: false,
      idende: "",
      veriode: true,
      time: "",
    }
  },
  mounted() {
    this.getproducts()
  },
  destroyed() {
    clearInterval(this.time)
  },
  methods: {
    mobiChange() {
      let regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;// 11位手机
      if(regs.test(this.mobile)) {
        return
      } else {
        this.$message({
              message: '请输入正确手机号',
              type: 'error'
            })
      }
    },
    passChange(){
      let passwordreg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,30}$/
      if(passwordreg.test(this.password)) {
        return
      } else {
        this.$message({
              message: '大小字母+数字+特殊字符,至少8位',
              type: 'error'
            })
      }

    },
    sighay() {
      this.$emit('sighay')
    },
    //图形验证码
    memberRegister() {
      let regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;// 11位手机
      if (this.checked && regs.test(this.mobile) && this.password && this.smsCode && this.code && this.inviteCode) {
        this.$apis.memberRegister({
          mobile: this.mobile,
          password: this.password,
          smsCode: this.smsCode,
          code: this.code,
          inviteCode: this.inviteCode
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: '注册成功',
              type: 'success'
            })
            this.$emit('sighay')
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请先同意隐私政策或检查是否填写正确'
        })
      }

    },
    //获取验证码
    sendRegisterVerifyCode() {
      let regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;// 11位手机
      if (this.veriode) {
        if (regs.test(this.mobile)) {
          this.$apis.sendRegisterVerifyCode({
            mobile: this.mobile
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "获取成功"
              })
              this.veriode = false
              this.time = setInterval(() => {
                this.veriode = true
              }, 60000)
            } else {
              this.$message({
                type: "success",
                message: res.msg
              })
            }

          })
        } else {
          this.$message({
            type: "error",
            message: "请输入正确的手机号"
          })
        }
      } else {
        return
      }


    },
    getproducts() {
      this.$apis.getKaptchaImage(
      ).then(res => {
        this.idende = res.data;
        // this.idende = window.URL.createObjectURL(new Blob([res],{type: 'imges/png'}));// 这里调用window的URL方法
      })
        .catch((err) => {
        })
    },
  }
}
</script>
  
<style scoped lang="scss"> a {
   text-decoration: none;
 }

 .Entifi {
   font-size: 30px;
   color: rgb(95, 103, 247);
 }

 .right {
   width: 520px;
   line-height: 20px;
   border-radius: 8px;
   background-color: rgba(255, 255, 255, 1);
   text-align: center;
   box-shadow: 0px 2px 6px 0px rgba(136, 136, 136, 45);

   .regisbox {
     display: flex;
     flex-direction: column;
     align-items: center;
     margin-top: 31px;
     position: relative;

     .zc {

       color: rgba(16, 16, 16, 1);
       font-size: 20px;
       font-weight: bold;
     }

     .icade {
       display: flex;

       .chane {
         position: absolute;
         color: rgba(108, 116, 247, 1);
         font-size: 14px;
         right: 23px;
         top: 294px;
       }
     }

     .yzm {
       /deep/.el-input-group__append {
         width: 164px !important;
       }
     }

     .readsent {
       margin-top: 23px;
       color: rgba(136, 136, 136, 1);
       font-size: 12px;
     }

     .acount {
       margin-top: 15px;
       font-size: 14px
     }
   }

   /deep/.el-input-group__append {
     width: 80px;
     height: 50px;
     line-height: 50px;
     border-radius: 0px 8px 8px 0px;
     background-color: rgba(245, 245, 245, 1);
     color: rgba(85, 85, 85, 1);
     font-size: 14px;
     text-align: center;
     border: 1px solid rgba(215, 215, 215, 1);
     position: absolute;
     top: 0;
     right: 0;
   }

   .sinput {
     margin-top: 36px;
   }

   .ck {
     width: 24px;
     height: 24px;
     margin-top: 13px;
   }

   .sigp {
     margin-top: 24px;

     span {
       font-size: 13px;
     }
   }

   .minput {
     margin-top: 22px;
     display: flex;
   }

   /deep/.el-input__inner {
     width: 360px;
     height: 50px;
     line-height: 20px;
     border-radius: 8px;
     background-color: rgba(255, 255, 255, 1);
     border: 1px solid rgba(215, 215, 215, 1);
   }

   .forget {
     color: rgba(136, 136, 136, 1);
     font-size: 13px;
     text-align: right;
     margin: 16px 0 34px 0;
     cursor: pointer;
   }

   /deep/.el-button--primary {
     width: 360px;
     height: 56px;
     line-height: 23px;
     border-radius: 8px;
     background-color: rgba(108, 116, 247, 1);
     color: rgba(255, 255, 255, 1);
     font-size: 16px;
     text-align: center;
     font-family: Roboto;
     border: none;
   }
 }</style>
  