<template>
  <div class="w-100 h-100">
    <div class="mainbox">
      <div class="acco flex">
        <img src="@/assets/img/xiaoxygg.png" alt="" srcset="">
        <span>消息与公告</span>
      </div>
      <div class="reg">
        <span>共有{{ total }}条消息与公告,以读{{ isRedNum }}条</span>

      </div>
      <div>
        <el-table ref="multipleTable" :data="NoticeRedList" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="80">
          </el-table-column>
          <el-table-column width="1000">
            <template slot="header" >
              <el-input placeholder="请输入内容" suffix-icon="el-icon-search" v-model="titleipit"  @input='inpChange()'>
              </el-input>

            </template>

            <template slot-scope="scope">
              <div class="flex">
                <img src="../../../assets/img/duanxin.png" alt="" srcset="" v-if="scope.row.type == 0" class="xxgimg">
                <img src="../../../assets/img/xxgg.png" alt="" srcset="" v-else class="xxgimg">
                <span style="margin-left: 10px" @click="redNoticeRed(scope.row)">{{ scope.row.title }}</span>
              </div>

            </template>
          </el-table-column>
          <el-table-column prop="name" label="消息时间" width="280" align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" show-overflow-tooltip >
            <template slot="header">
              <div class="">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    消息类型<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="0">系统消息</el-dropdown-item>
                    <el-dropdown-item command="1">平台公告</el-dropdown-item>
                    <el-dropdown-item command="">全部</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

            </template>
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.type == 1 ? '平台公告' : '系统消息' }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
          :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
          class="flex just-center mg-tp-23">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="isPop" width="30%" class="welec">
        <div>
          {{ content }}
        </div>
     
        <span slot="footer" class="dialog-footer">
          <el-button @click="isPop=false">关闭</el-button>
        </span>
      </el-dialog>
  </div>
</template>
  
<script>
export default {

  props: {
  },
  data() {
    return {
      total: 0,
      currentpage:0,
      titleipit: "",
      isPop:false,
      multipleSelection: [],
      offset: 0,
      limit: 10,
      type: "",
      title:"",
      content:"",
      userInfo: {},
      NoticeRedList: [],
      isRedNum: "",
    }
  },
  mounted() {
   
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.$nextTick(()=>{
      this.getNoticeRedList()
    })
   
  },
  methods: {
    redNoticeRed(data) {
      this.title = data.title
      this.content=data.content
      this.$apis.redNoticeRed({
        id:data.id
      }).then((res)=>{
        if(res.code==0){
          this.isPop = true
          this.getNoticeRedList()
        }
  
      })
    },
    handleCommand(command) {
      this.type = command
      this.getNoticeRedList()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getNoticeRedList()
    },
    handleCurrentChange(val) {
      this.currentpage=val
      this.offset = (val-1)*this.limit
      this.getNoticeRedList()
    },
    inpChange() {
      this.$forceUpdate();  //强制刷新
      this.getNoticeRedList()
    },
    getNoticeRedList() {
      this.$apis.getNoticeRedList({
        mid: this.userInfo.id,
        type: this.type,
        offset: this.offset,
        limit: this.limit,
        title: this.titleipit
      }).then((res) => {
        this.NoticeRedList = res.data.rows
        this.total = res.total
        this.isRedNum = res.isRedNum
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }

  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-button--default {
  width: 85px;
  height: 33px;
  border-radius: 4px;
  background-color: rgba(164, 173, 179, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
  font-family: Microsoft Yahei;
  line-height: 0;
}
/deep/.el-dropdown {
  color: #ADAEAF;
  
}

/deep/.el-pager {
  font-size: 12px;
  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }
li {
  padding: 0 10px;
  min-width:30px
}
.number {
  color: #007BFF;
  border: 1px solid #dadbdb ;
}
}

.xxgimg {
  width: 15px;
  height: 15px;
}

.mainbox {
  //  height: 373px !important;
  background-color: #fff;
  padding: 10px 17px 22px 17px;

}

/deep/.el-table__header-wrapper {
  /deep/.cell {
    font-weight: 400;
    font-size: 12px;
    color: #ADAEAF;
  }
}

/deep/.el-checkbox__inner {
  border: 4Px solid #ccc;
}

/deep/.el-checkbox__inner::after {
  top: 0 !important;
}

/deep/.el-table__row {
  /deep/.cell {
    font-weight: 400;
    font-size: 12px;
    // display: flex;
    //   align-items: center
  }
}

/deep/.el-input__inner {
  border: none;
  font-size: 12px;
  padding-left: 0;
}

/deep/.el-input {
  width: 100px !important;
}

.jb {
  font-size: 15px;
  color: #333333;
}

.essen {
  display: flex;
  align-items: inherit;
  margin-bottom: 20px;

  .labe {
    font-size: 14px;
    color: #666;
    // margin-right: 120px;
    width: 170px;
  }

  .usid {
    span {
      color: rgba(102, 102, 102, 1);
      font-size: 12px;
    }

    p {
      color: #A1A1A1;
      margin-top: 6px;
    }
  }
}

.reg {
  font-size: 12px;
  color: #666;
  margin: 35px 0 20px 0;
  width: 639px;

  a {
    color: #0056b3;
  }
}

.acco {
  img {
    width: 24px;
    height: 24px;
  }

  span {
    margin: 0;
    font-size: 20px;
    margin-left: 12px;
  }
}

// /deep/.el-submenu__title:hover {
//   background-color: #E6F7FF  !important;
//   color: #4BA1FD !important;

// }
.lgo {
  width: 126px;
  height: 28px;
  margin-right: 10px;
}
</style>
  