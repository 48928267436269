import { render, staticRenderFns } from "./substifica.vue?vue&type=template&id=9605ab32&scoped=true&"
import script from "./substifica.vue?vue&type=script&lang=js&"
export * from "./substifica.vue?vue&type=script&lang=js&"
import style0 from "./substifica.vue?vue&type=style&index=0&id=9605ab32&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9605ab32",
  null
  
)

export default component.exports