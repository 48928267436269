<template>
  <div class="w-100 h-100">
    <!-- //域名管理首页 -->
    <doerhomg v-if="accforshow" @comPile="comPile"></doerhomg>
    <!-- //域名设置 -->
    <domtings v-else :dovervilist="dovervilist"  :examine="examine"></domtings>
  </div>
</template>
  
<script>
import doerhomg from './componens/doerhomg.vue'
import domtings from './componens/domtings.vue'
export default {
  components: {
    doerhomg,
    domtings
  },
  props: {
    
  },
  data() {
    return {

      accforshow:true,
      dovervilist:{},
      examine:true,
    }
  },
  mounted() {

  },
  methods: {
    swge() {
      this.accforshow=true
    },
    comPile(data,examine) {
      this.dovervilist = data //从首页带过来的
      this.examine = examine,//是否为查看
      this.accforshow = false
    },

  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
  