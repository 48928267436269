<template>
  <div class="w-100 h-100">
    <div class="mainbox" v-show="accforshow == 1">
      <div class="acco flex">
        <img src="@/assets/img/ugrzx.png" alt="" srcset="">
        <span>账号信息</span>
      </div>
      <div class="reg">
        <span>你注册于{{hyrs}}前,下面是你的账号信息</span>

      </div>
      <div>
        <span class="jb">基本信息</span>
        <el-divider></el-divider>
        <div class="flex essen">
          <label class="labe">用户ID</label>
          <div class="flex-column usid al-baseline">
            <span>{{ userInfo.id }}</span>
            <p>是您在融合CDN平台的数字ID, 也是使用SDK时需要传递的userID</p>
          </div>

        </div>
        <div class="flex essen">
          <label class="labe">绑定手机号</label>
          <div class="flex-column usid al-baseline">
            <span>{{Tgmber}}****{{Laomber}}</span>
            <p>是登录管理控制台的唯一凭证，无法自行修改。</p>
          </div>

        </div>
        <div class="flex essen">
          <label class="labe"> {{userInfo.type==0?'个人名称':userInfo.type==1?'企业':""}}</label>
          <div class="flex-column usid al-baseline">
            <div class="flex">
              <span v-if="ehatshow">{{userInfo.nickname?userInfo.nickname:'未设置'}}</span>
              <div v-else>
                <el-input v-model="nickname"  label="设置名称" maxlength="50"></el-input>
              </div>
              <img src="../../../assets/img/weisz.png" alt="" srcset="" class="iset">
              <label @click="ehatshow = !ehatshow" v-if="ehatshow">设置</label>
              <label @click="memberEdit" v-else>确定</label>
            </div>

            <p>注册后无法自行修改，如果不是个人真实姓名或者企业全称，我们有可能会要求你进行修改。</p>
          </div>

        </div>
     
      </div>
    </div>

  </div>
</template>
  
<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
      accforshow: '1',
      ehatshow: true,
      setshow: true,
      userInfo: {},
      hyrs:"",
      nickname:"",
      Tgmber:"",
      Laomber:"",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.Tgmber = this.userInfo.mobile.slice(0, 3)
    this.Laomber = this.userInfo.mobile.slice(7, 11)
    this.getTime()
  },
  methods: {
    getTime() {
      let nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };
      let newmonth = date.month > 10 ? date.month : "0" + date.month;
      let newday = date.date > 10 ? date.date : "0" + date.date;
      let newminutes = date.minutes < 10 ? "0" + date.minutes : date.minutes;
      let newseconds = date.seconds < 10 ? "0" + date.seconds : date.seconds;
      // 我需要的时间格式为 "yyyy-MM-dd HH:mm:ss" 有不一样的自己拼别的，都是字符串拼接
      let newTime =
        date.year +
        "-" +
        newmonth +
        "-" +
        newday +
        " " +
        date.hours +
        ":" +
        newminutes +
        ":" +
        newseconds;
      
    let newtime =  Date.parse(new Date(newTime))/1000-Date.parse(new Date(this.userInfo.createTime))/1000
    let time =  parseInt(newtime/3600);
    //  60/60/24
     this.hyrs = time >=24? parseInt(newtime/60/60/24)+'天':time +'个小时'
    },
    //重新获取用户信息
    member() {
      this.$apis.member({
        mid:this.userInfo.id
      }).then((res)=>{
        localStorage.setItem('userInfo',JSON.stringify(res.data))
        this.userInfo  = res.data
        this.nickname=""
      })
    },
    memberEdit() {
      this.$apis.memberEdit({
        id:this.userInfo.id,
        nickname:this.nickname
      }).then((res)=>{
        this.ehatshow=!this.ehatshow
        this.$message({
          type:"success",
          message:"设置成功"
        })
        this.member()
      })
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.iset {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

p {
  margin: 0;
}

.el-divider {
  margin: 10px 0;
}

/deep/.el-input__inner {
  height: 30px;
}

.mainbox {
  //  height: 373px !important;
  background-color: #fff;
  padding: 10px 17px 1px 17px;
}

.jb {
  font-size: 15px;
  color: #333333;
}

.essen {
  display: flex;
  align-items: inherit;
  margin-bottom: 20px;

  .labe {
    font-size: 14px;
    color: #666;
    // margin-right: 120px;
    width: 170px;
  }

  .usid {
    span {
      font-size: 16px;
      font-weight: bold;
      color: #71706F;
    }

    label {
      color: #4A9EFF;
      margin-left: 5px;
      font-size: 12px;
    }

    p {
      color: #A1A1A1;
      margin-top: 6px;
    }
  }
}

.reg {
  font-size: 80%;
  color: #666;
  margin: 35px 0 50px 0;
}

.acco {
  img {
    width: 24px;
    height: 24px;
  }

  span {
    margin: 0;
    font-size: 20px;
    margin-left: 12px;
  }
}

// /deep/.el-submenu__title:hover {
//   background-color: #E6F7FF  !important;
//   color: #4BA1FD !important;

// }
.lgo {
  width: 126px;
  height: 28px;
  margin-right: 10px;
}
</style>
  