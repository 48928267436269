

// import { getSession } from '@/utils';
const token = localStorage.getItem('token');
const config = {
 
    baseURL: 'https://admin.znidc.net/',
    // http://36.111.207.190:8001/
    timeout: 15000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      "Authorization":localStorage.getItem('token')?localStorage.getItem('token'):"",
    },
    // 自定义全局请求头
    // headerHandlers: [
    //   () => new Promise((resolve) => {
    //     const userInfo = getSession('userInfo');
    //     resolve({'session': userInfo ? userInfo.session : ''});
    //   })
    // ],
  }

  export default config;
  