<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <h3 class="cz flex">
          <img src="@/assets/img/ddgli.png" alt="" srcset="" class="hedimg" />
          <span> 订单管理</span>
        </h3>
        <div>
          <div class="flex">
            <div class="sjfw">
              <span class="chars">时间范围</span>
              <el-select
                v-model="dateType"
                placeholder="请选择时间范围"
                @change="dateTypeChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="sjfw">
              <span class="chars">产品</span>
              <el-select
                v-model="productType"
                placeholder="请选择产品"
                @change="productTypeChange"
              >
                <el-option
                  v-for="item in optionstype"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="jylx">
              <span class="chars">订单状态</span>
              <el-select
                v-model="status"
                placeholder="请选择订单状态"
                @change="statusChange"
              >
                <el-option
                  v-for="item in statusoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div>
            <el-table
              :data="OrderListByMid"
              style="width: 100%"
              empty-text="暂无符合条件的收支记录..."
            >
              <el-table-column prop="orderSn" label="订单号" width="300">
              </el-table-column>
              <el-table-column
                prop="productType"
                label="产品"
                width="160"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.productType == 0 ? "CDN" : "SSL证书" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="productName"
                label="商品名称"
                width="150"
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="createTime"
                label="订单创建时间"
                align="center"
                width="200"
              >
              </el-table-column>
              <el-table-column
                prop="payTime"
                label="订单完成时间"
                align="center"
                width="200"
              >
              </el-table-column>
              <el-table-column
                prop="status"
                label="状态"
                align="center"
                width="140"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.status == -1
                      ? "取消支付"
                      : scope.row.status == 0
                      ? "待支付"
                      : scope.row.status == 1
                      ? "完成"
                      : scope.row.status == 2
                      ? "退款中"
                      : "退款完成"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="payPrice"
                label="金额"
                align="center"
                width="160"
              >
              </el-table-column>
              <el-table-column prop="address" label="操作" width="200"  >
                <template slot-scope="scope">
                  <div class="">
                    <!-- <img src="@/assets/img/shanchu.png" alt="" srcset="" class="tabdelt"> -->
                    <el-button
                      type="primary"
                      @click="refundOrder(scope.row.id)"
                      v-if="scope.row.status == 1 && scope.row.orderType !== 0"
                      >申请退款</el-button
                    >
                    <el-button
                      type="primary"
                      @click="immedyment(scope.row.id)"
                      v-if="scope.row.status == 0"
                      >立即支付</el-button
                    >
                    <el-button
                      type="primary"
                      @click="cancelpayment(scope.row.id)"
                      v-if="scope.row.status == 0"
                      >取消订单</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout=" sizes, prev, pager, next, jumper"
            :total="total"
            class="flex just-center mg-tp-23"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
    >
      <div slot="title" class="text-cer">
        <span>申请退款</span>
      </div>
      <div class="alert">
        <span>退款原因</span>
        <el-select v-model="refundRemark" placeholder="请选择退款原因">
          <el-option
            v-for="item in refson"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-button type="primary" class="cancel" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="cnfifund">确定</el-button>
      </div>
    </el-dialog>
    <!-- 流量包支付 -->
    <!-- 流量包支付 -->
    <div class="trymentdialog">
      <el-dialog :visible.sync="dackVisible" width="80%" :show-close="false">
        <div slot="title">
          <h5>核对和支付</h5>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="prme flex">
            <label>商品名称</label>
            <span>{{ OrderList.productName }}</span>
          </div>
          <div class="prme flex">
            <label>购买数量 </label>
            <span>{{ OrderList.total }} 个</span>
          </div>
          <div class="prme flex nepay">
            <label>需要支付 </label>
            <span class=""
              ><label class="unit">￥</label>{{ OrderList.payPrice }}</span
            >
          </div>
          <div class="prme flex">
            <label>支付方式 </label>
            <div class="maym">
              <ul class="flex">
                <li :class="suffioff ? 'active' : ''" @click="sitchinalance">
                  <span class="">{{ remaititle }} ￥{{ money }}</span>
                </li>
                <li :class="!suffioff ? 'active' : ''" @click="WeChatPay">
                  <span class="">微信支付</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="prme flex scpay al-baseline" v-show="!suffioff">
            <label>扫码支付 </label>
            <div class="flex-column al-baseline">
              <span class="">请使用微信客户端扫码二维码完成支付</span>
              <div id="qrcode" ref="qrcode" class="erwma mg-tp-10"></div>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-divider></el-divider>

          <el-button @click="cancel" class="cancel">取 消</el-button>
          <el-button @click="confiyment" class="mg-rt-22" type="primary"
            >确认支付</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  components: { QRCode },
  props: {
    msg: String,
    hogeproduct: String,
    homgstatus: String,
  },
  data() {
    return {
      codeurl: "", //回调url
      qrcode: "",
      suffioff: false,
      remaititle: "余额",
      money: "",
      currentpage: 0,
      refson: [
        {
          value: "买错了",
          label: "买错了",
        },
        {
          value: "不想要了",
          label: "不想要了",
        },
        {
          value: "3买重了",
          label: "买重了",
        },
      ],
      dialogVisible: false,
      dackVisible: false,
      OrderListByMid: [],
      OrderList: {},
      num: 1,
      imageUrl: "",
      statusoptions: [
        {
          value: "-1",
          label: "取消状态",
        },
        {
          value: "0",
          label: "待支付",
        },
        {
          value: "1",
          label: "完成 ",
        },
        {
          value: "2",
          label: "退款中",
        },
        {
          value: "3",
          label: "退款完成",
        },
        {
          value: "",
          label: "所有状态",
        },
      ],
      optionstype: [
        {
          value: "0",
          label: "CDN",
        },
        {
          value: "1",
          label: "SSL证书",
        },
        {
          value: "",
          label: "全部产品",
        },
      ],
      options: [
        {
          value: "1",
          label: "今天",
        },
        {
          value: "2",
          label: "昨天",
        },
        {
          value: "3",
          label: "近7天",
        },
        {
          value: "4",
          label: "近30天",
        },
        {
          value: "5",
          label: "本月",
        },
        {
          value: "6",
          label: "上月",
        },
        {
          value: "",
          label: "所有时间",
        },
      ],
      dateType: "",
      productType: "",
      status: "",
      offset: 0,
      limit: 10,
      total: 0,
      orderId: "",
      refundRemark: "",
      Orderdetails: {},
    };
  },
  watch: {
    hogeproduct() {},
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (this.$route.query.status) {
      this.status = String(this.$route.query.status);
      this.$router.push({ query: {} });
      this.getOrderListByMid();
      return;
    }
    if (this.hogeproduct) {
      this.productType = String(this.hogeproduct);
      // this.$router.replace({ query: {} }); //清空query参数
      this.getOrderListByMid();
      return;
    }
    this.getOrderListByMid();
  },
  methods: {
    //从费用中心首页跳转改变类型
    Changetype(data) {
      this.productType = data;
      this.getOrderListByMid();
    },
    cancel() {
      this.dackVisible = false;
    },
    confiyment() {
      if (this.suffioff) {
        this.$apis
          .yuPayOrder({
            orderId: this.orderId,
          })
          .then((res) => {
            if (res.code == 0) {
              this.pufwer = false;
              this.dackVisible = false;
              this.getOrderListByMid();
            }
          });
      } else {
        this.$apis
          .getOrderById({
            orderId: this.orderId,
          })
          .then((res) => {
            if (res.data.status == 0) {
              this.$message({
                type: "warning",
                message: "请扫码支付",
              });
            } else if (res.data.status == 1) {
              this.pufwer = false;
              this.dackVisible = false;
              this.getOrderListByMid();
            }
          });
      }
    },
    WeChatPay() {
      this.suffioff = false;
      // this.wxPayOrder(this.payID)
    },
    wxPayOrder() {
      let that = this;
      this.$apis
        .wxPayOrder({
          orderId: this.orderId,
        })
        .then((res) => {
          that.codeurl = res.data.package.slice(9);

          const codeHtml = document.getElementById("qrcode");
          codeHtml.innerHTML = "";
          let qrcode = new QRCode("qrcode", {
            width: 200,
            height: 180,
            text: that.codeurl, // 二维码地址
            colorDark: "#000",
            colorLight: "#fff",
          });
        });
    },
    sitchinalance() {
      if (
        this.money > this.OrderList.payPrice ||
        this.money == this.OrderList.payPrice
      ) {
        this.suffioff = true;
      } else {
        return;
      }
    },
    refundOrder(id) {
      this.dialogVisible = true;
      this.orderId = id;
    },
    cancelOrder() {
      this.$apis
        .cancelOrder({
          orderId: this.orderId,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
          });
          this.getOrderListByMid();
        });
    },
    //取消订单
    cancelpayment(id) {
      this.orderId = id;
      this.cancelOrder();
    },
    immedyment(id) {
      this.orderId = id;
      this.getOrderById();
      this.wxPayOrder();
    },
    //确定退款
    cnfifund() {
      this.$apis
        .refundOrder({
          orderId: this.orderId,
          refundRemark: this.refundRemark,
        })
        .then((res) => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.orderId = "";
            this.refundRemark = "";
            this.getOrderListByMid();
          }

          this.$message({
            message: res.msg,
          });
        });
    },
    dateTypeChange() {
      this.getOrderListByMid();
    },
    productTypeChange() {
      this.getOrderListByMid();
    },
    statusChange() {
      this.getOrderListByMid();
    },
    getOrderById(id) {
      this.$apis
        .getOrderById({
          orderId: this.orderId,
        })
        .then((res) => {
          this.OrderList = res.data;
          if (
            res.money > this.OrderList.payPrice ||
            res.money == this.OrderList.payPrice
          ) {
            this.suffioff = true;
            this.remaititle = "余额";
          } else {
            this.remaititle = "余额不足";
          }
          this.money = res.money;
          this.dackVisible = true;
          this.pufwer = false;
        });
    },
    getOrderListByMid() {
      this.$apis
        .getOrderListByMid({
          mid: this.userInfo.id,
          dateType: this.dateType,
          productType: this.productType,
          status: this.status,
          offset: this.offset,
          limit: this.limit,
        })
        .then((res) => {
          this.OrderListByMid = res.data.rows;
          this.total = res.data.total;
        });
    },
    handleCurrentChange(val) {
      this.currentpage = val;
      this.offset = (val - 1) * this.limit;
      this.getOrderListByMid();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getOrderListByMid();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.el-table {
  overflow-x: scroll;
  /deep/.el-button--primary {
    padding: 0;
    height: 30px;
    line-height: 0;
    border-radius: 4px;
    background-color: rgba(95, 165, 242, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
  }
}

.trymentdialog {
  /deep/.el-dialog__footer {
    padding: 0;

    .el-divider {
      margin-bottom: 10px;
    }

    .cancel {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      margin: 0 20px 20px 0;
      padding: 10px 15px;
    }
  }

  .nepay {
    span {
      color: #ed711f !important;
      font-size: 22px !important;
    }
  }

  .scpay {
    img {
      width: 128px;
      height: 128px;
      margin-top: 10px;
    }
  }

  .prme {
    font-size: 14px;
    color: #666666;
    margin-bottom: 20px;

    .maym {
      .active {
        border-color: #007bff !important;
        background: url("../../../assets/img/xzl.png") no-repeat top right;
        background-size: 20px 20px;
      }

      li {
        list-style: none;
        padding: 10px;
        border: 1px solid #e4e9f0;
        list-style: none;
        margin-right: 10px;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        display: inline-block;
      }
    }

    .unit {
      font-size: 14px !important;
    }

    label {
      flex: 0 0 16.666667%;
    }

    span {
      flex: 0 0 83.333333%;
    }
  }

  /deep/.el-dialog__body {
    padding: 0 20px 0 20px;
  }

  /deep/.el-dialog__header {
    .el-divider {
      margin: 20px 0 10px 0;
    }

    h5 {
      font-size: 15px !important;
      color: #333333;
      font-weight: 400 !important;
    }
  }
}

/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007bff;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px;
  }

  .number {
    color: #007bff;
    border: 1px solid #dadbdb;
  }
}

.hedimg {
  width: 25px;
  height: 25px;
}

.alert {
  span {
    width: 56px;
    height: 50px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin-right: 54px;
  }
}

.tabdelt {
  width: 32px;
  height: 34px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__inner {
    width: 200px;
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__inner {
    width: 200px;
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }
}
</style>
