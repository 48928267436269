<template>
  <div id="app" class="w-100 h-100">
    <router-view></router-view>
  </div>
</template>

<script>
import DevicePixelRatio from './utils/devicePixelRatio.js'
export default {
  name: 'App',
  mounted(){
  },
  created(){
    new DevicePixelRatio().init()
  },
}
</script>

<style >
body {
  margin: 0;
  padding: 0;
  
}
</style>
