<template>
  <div class="w-100 h-100">
    <div class="hoge">
      <span class="expewz">￥费用中心</span>
      <div class="flex">
        <div class=" caount">
          <div class="flex">
            <div class="casou ">
              <div class="camain">
                <div class="">
                  <span>现金金额</span>
                </div>
                <div class="amod">
                  <span>￥{{ costCentreList.money }}</span>
                </div>
                <div class="flex al-flex-end ">
                  <a class="toptanc curpoin" @click="record(5)">充值汇款</a>
                  <a class="deduecord curpoin" @click="record(6, '1')">扣费记录</a>
                  <div class="jiluyj">
                    <a class="czjl curpoin" @click="record(6, '0')">充值记录</a>
                    <a @click="Balanning" class="curpoin czjl">余额预警</a>
                    <a @click="Ppack" class="curpoin">资源包预警</a>
                  </div>

                </div>
              </div>
            </div>
            <div class="orderu ">
              <div class="ordmain">
                <div class="">
                  <span>订单数量</span>
                </div>
                <div class="amod">
                  <label>订单总数</label>
                  <span>{{ costCentreList.orderNum }}</span>
                </div>
                <div class="flex al-flex-end ">
                  <a class="toptanc curpoin" @click="record(3, '0')">CDN订单</a>
                  <a class="deduecord curpoin" @click="record(3, '1')">SLL订单</a>
                  <div class="jiluyj " @click="record(3)">
                    <a class="czjl curpoin">订单管理</a>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="costren">
            <div class="costmain">
              <div class="fyqs flex just-bet">
                <span class="bt">费用趋势</span>
              </div>
              <div class="nexp">
                <!-- <span> 你暂时还没产生费用~</span> -->
                <div class="echart" id="mychart"></div>
              </div>
            </div>
          </div>

        </div>
        <div class=" cotre ">
          <div class="invoice ">
            <div class="invomain">
              <div class="">
                <span>发票</span>
              </div>
              <div class="amod">
                <label>可开发票金额</label>
                <span style="color: #28D34B;">￥{{ costCentreList.invoiceMoney }}</span>
                <div class="apvoic">
                  <label>正在申请开发票</label>
                  <span style="color: #1977FF">{{ costCentreList.invoiceNum }}</span>
                </div>

              </div>
              <div class="flex al-flex-end ">
                <a class="toptanc" @click="record(4)">申请开票</a>
                <div class="jiluyj" @click="record(4)">
                  <a class="czjl curpoin">发票管理</a>
                </div>

              </div>
            </div>
          </div>

          <div class="prodonsu">
            <div class="prodmain">
              <div class="prodhea">
                <span>本月产品消费</span>
              </div>
              <el-divider></el-divider>
              <div class="detaixt">
                <div class="flex just-bet w-100" v-for="item in costCentreList.orders" :key="item.id">
                  <label>{{ item.createTime.split(' ')[0] }}</label>
                  <label>{{ item.productName }}</label>
                  <label>￥{{ item.orderPrice }}</label>
                </div>
              </div>

              <!-- //没有数据时候的 -->
              <!-- <div class="byxf flex just-center">
              
              <label>你本月还没有产生消费...</label>
            </div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div slot="title">
        <span>余额预警</span>

      </div>
      <el-divider></el-divider>
      <div class="alert">
        <div slot="" class="alert-info">

          <span>开启后，当你的账号可用余额低于你设置的阈值时，将进行通知提醒。可以在 </span>
          <a @click="perJump(4)">消息订阅</a>
          中修改通知方式和联系人。
        </div>
        <!-- <div class="off">
          <span>开关</span>
          <el-checkbox v-model="checked">接收余额预警通知</el-checkbox>
        </div> -->
        <div class="whres">
          <span>预警阈值</span>
          <div class="flex-column al-start">
            <el-input-number v-model="moneyWarning" :min="0.01" :max="9999999" label="描述文字" :controls="false"
              @change="moneyWarChange"></el-input-number>
            <label for="">范围：0.01 ~ 9999999，允许设置到小数点后两位。</label>
          </div>

        </div>

      </div>
      <el-divider></el-divider>
      <div slot="footer">
        <el-button type="primary" class="cancel" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="memberEdit">保存设置</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="ResningVisi" width="30%">
      <div slot="title">
        <span>资源包预警</span>

      </div>
      <el-divider></el-divider>
      <div class="alert">
        <!-- <div slot="" class="alert-info">

          <span>开启后，当你的账号可用资源包低于你设置的阈值时，将进行通知提醒。可以在 </span>
          <a @click="perJump(4)">消息订阅</a>
          中修改通知方式和联系人。
        </div> -->
        <!-- <div class="off">
          <span>开关</span>
          <el-checkbox v-model="checked">接收余额预警通知</el-checkbox>
        </div> -->
        <div class="whres">
          <span>是否开启</span>
            <el-switch v-model="moneyWarni" active-color="#1684FC" inactive-color="#909399">
            </el-switch>
        </div>
       
        <div class="whres">

         
         
          <span>预警阈值</span>
          <div class="flex-column al-start">

            <div>
              <el-input-number v-model="RespaWarning" :min="1" :max="99" label="描述文字" :controls="false"
                @change="RespaWarChange"></el-input-number>
              <label for="">%</label>
            </div>

            <label for="">范围：1 ~ 99，允许设置到小数点后两位。</label>
          </div>

        </div>

      </div>
      <el-divider></el-divider>
      <div slot="footer">
        <el-button type="primary" class="cancel" @click="ResningVisi = false">取消</el-button>
        <el-button type="primary" @click="ResEdit">保存设置</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import * as echarts from "echarts";
export default {

  props: {
    msg: String
  },
  data() {
    return {

      dialogVisible: false,
      ResningVisi: false,
      checked: false,
      moneyWarni: true,
      moneyWarning: 0,
      RespaWarning:1,
      userInfo: {},
      costCentreList: {},
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.costCentre()
  },
  methods: {
    Balanning() {
      this.dialogVisible = true
      this.moneyWarning = this.userInfo.moneyWarning
    },
    Ppack() {
      this.ResningVisi = true
      this.moneyWarni = this.userInfo.isResourceWarning==1?true:false
      this.RespaWarning = this.userInfo.resourceWarning
    },
    perJump(data) {
      this.$router.push({ path: '/personter', query: { perhoew: data } })
    },
    RespaWarChange() {
      this.RespaWarning = this.RespaWarning.toFixed(2)
    },
    moneyWarChange() {
      this.moneyWarning = this.moneyWarning.toFixed(2)
    },
    //重新获取用户信息
    member() {
      this.$apis.member({
        mid: this.userInfo.id
      }).then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    },
    ResEdit() {
      this.$apis.memberEdit({
        id: this.userInfo.id,
        resourceWarning: this.RespaWarning,
        isResourceWarning: this.moneyWarni ? 1 : 0
      }).then(() => {
        this.ResningVisi = false
        this.member()
        this.$message({
          type: "success",
          message: "更新成功"
        })
      })
    },
    memberEdit() {
      this.$apis.memberEdit({
        moneyWarning: this.moneyWarning,
        id: this.userInfo.id,
      }).then(() => {
        this.dialogVisible = false
        this.member()
        this.$message({
          type: "success",
          message: "更新成功"
        })
      })
    },
    costCentre() {
      this.$apis.costCentre({
        mid: this.userInfo.id
      }).then((res) => {
        this.costCentreList = res
        if (document.getElementById("mychart")) {
          this.initEcharts()
        }

      })
    },
    initEcharts() {
      // 基本柱状图
      const option = {
        xAxis: {
          data: this.costCentreList.countMothMoney.month,
          axisLabel: {
            color: '##686868'
          },
        },
        yAxis: {
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
          axisLabel: {
            //这种做法就是在y轴的数据的值旁边拼接单位，貌似也挺方便的
            formatter: "{value} 元",
            color: '##686868'
          },
        },
        // legend: {
        //   itemWidth: 24,    //图例的宽
        //   itemHeight: 15,   //图例的高
        //   show: true,  //是否显示图例
        //   // left: "8%",  //图例距离左方位置
        //   bottom: "2%",   //图例距离上方位置
        // },
        series: [
          {
            type: "bar", //形状为柱状图
            data: this.costCentreList.countMothMoney.moneyList,
            // name: "收入", // legend属性
            // barCategoryGap: '10%',
            // 调整同一系列中柱状图之间的间隔
            // barGap: 100,
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: true,
              position: "top",
              formatter: '{c}元',
              color: '#6293ED'
            },
            itemStyle: {
              color: '#5087EC'
            },
            // barGap:'0%',

          },

          // {
          //   type: "bar", //形状为柱状图
          //   data: this.data2,
          //   // barGap: 100,
          //   // barCategoryGap: '70%',
          //   name: "支出", // legend属性
          //   itemStyle: {
          //     color: '#68BBC4'
          //   },
          //   label: {
          //     // 柱状图上方文本标签，默认展示数值信息
          //     show: true,
          //     // position: "top",
          //     position: ['-10%', '-80%'],
          //     formatter: '{c}k'
          //   }
          // }
        ]
      };
      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    //首页跳转其他地方然后带有参数
    record(data, index) {
      this.$emit('record', data, index)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-input-number {
  width: 12rem;
}

.whres {
  margin-top: 15px;

  span {
    margin-right: 47px;
  }

  display: flex;

  /deep/.el-input__inner {
    width: 120px;
    background-color: #e9ecef;
    opacity: 1;
    border-radius: 2px;
    height: 30px;
    text-align: left;
  }

  label {
    color: #999;
    font-size: 12px;
  }
}

.off {
  font-size: 12px;
  color: #666;
  margin-top: 15px;

  span {
    margin-right: 80px;
  }

  /deep/.el-checkbox__label {
    color: #666;

    font-size: 14px;
  }
}

.cancel {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}


/deep/.el-dialog__header {
  font-size: 13px;
}

/deep/.el-dialog__body {

  .alert {
    padding: 10px;
  }

  padding:0;

  .el-divider {
    margin: 10px 0 10px 0;
  }
}

.alert-info {

  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 5px;

  a {
    color: #0056b3;
    text-decoration: none;
  }
}

.hoge {
  padding: 0 40px 0 40px;
}

.cotre {
  margin-top: 27px;
  width: 32%;

  .invoice {
    width: 100%;
    height: 144px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-bottom: 19px;

    .invomain {
      padding: 19px 30px 19px 30px;

      a {
        text-decoration: none;
        color: #1977FF;

      }

      span {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }

      .amod {
        margin: 15px 0 15px 0;
        display: flex;
        align-items: baseline;
        white-space: nowrap;

        .apvoic {
          margin-left: 10px;
        }

        label {
          color: #ADADAD;
          font-weight: bold;
          margin-right: 6px;
          font-size: 80%;
        }

        span {
          color: #1977FF;
          font-size: 24px;
        }
      }

      .toptanc {
        width: 65px;
        height: 27px;
        border-radius: 5px;
        background-color: rgba(33, 196, 205, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        text-align: center;
        display: block;
        line-height: 27px;
        margin-right: 9px;
      }

      .jiluyj {
        font-size: 14px;

      }
    }
  }

  .prodonsu {
    width: 100%;
    height: 521px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    .prodmain {
      /deep/.el-divider {
        margin: 10px 0;
      }

      .detaixt {
        padding: 0 24px;

        label {
          color: rgba(16, 16, 16, 1);
          font-size: 14px;
          // margin-right: 20px;
        }
      }

      .prodhea {
        padding: 19px 0 0 30px;
      }

      .byxf {
        margin-top: 260.5px;
      }

      span {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

.caount {
  margin-top: 26px;
  width: 68%;

  .costren {
    height: 521px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-right: 21px;

    .echart {
      width: calc(100% - 40px);
      ;
      height: 470px;
      margin-right: 20px;
    }

    .costmain {
      padding: 19px 0 19px 30px;
    }

    .fyqs {
      .bt {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }


  .orderu {
    width: 50%;
    height: 144px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-right: 19px;
    margin-bottom: 19px;

    .ordmain {
      padding: 19px 0 19px 30px;

      a {
        text-decoration: none;
        color: #1977FF;

      }

      span {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }

      .amod {
        margin: 15px 0 15px 0;

        label {
          color: #ADADAD;
          font-weight: bold;
          margin-right: 6px;
        }

        span {
          color: #1977FF;
          font-size: 24px;
        }
      }

      .toptanc {
        width: 65px;
        height: 27px;
        border-radius: 5px;
        background-color: rgba(245, 152, 49, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        text-align: center;
        display: block;
        line-height: 27px;
        margin-right: 9px;
      }

      .deduecord {
        width: 65px;
        height: 27px;
        border-radius: 5px;
        background-color: rgba(164, 173, 179, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        text-align: center;
        display: block;
        line-height: 27px;
        margin-right: 9px;
      }

      .jiluyj {
        font-size: 14px;
        margin-left: 19px;

      }
    }

  }

  .casou {
    width: 50%;
    height: 144px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-right: 19px;
    margin-bottom: 19px;

    .camain {
      padding: 19px 0 19px 20px;
    }

    .jiluyj {
      font-size: 12px;

      .czjl {
        margin-right: 9px;
      }
    }

    a {
      text-decoration: none;
      color: #1977FF;

    }

    span {
      color: rgba(16, 16, 16, 1);
      font-size: 12px;
      font-weight: bold;
    }

    .amod {
      margin: 15px 0 15px 0;

      span {
        color: #28D34B;
        font-size: 24px;
      }
    }

    .toptanc {
      width: 65px;
      height: 27px;
      border-radius: 5px;
      background-color: rgba(49, 152, 245, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 27px;
      margin-right: 9px;
    }

    .deduecord {
      width: 65px;
      height: 27px;
      border-radius: 5px;
      background-color: rgba(148, 148, 148, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 27px;
      margin-right: 9px;
    }
  }

}

.expewz {
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  font-weight: bold;
}
</style>
