<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <div class="flex just-bet">
          <h3 class="cz flex">
            <img src="@/assets/img/zsglwxzd.png" alt="" srcset="" class="hedimg">
            <span> 证书管理</span>

          </h3>
          <div>
            <el-button type="primary" class="purcha" @click="coementVisible = true">上传证书</el-button>
          </div>
        </div>

        <div class="">


          <div>
            <el-table :data="CdnPackagePage" style="width: 100%" empty-text="还没有上传证书...">
              <el-table-column prop="id" label="ID" width="200" align="center">
              </el-table-column>
              <el-table-column prop="name" label="备注名" width="300" align="center">
              </el-table-column>
              <el-table-column prop="cn" label="通用名称" width="300" align="center">
              </el-table-column>
              <el-table-column prop="issuer" label="类型" width="200" align="center">
              </el-table-column>
              <el-table-column prop="expires" label="到期时间" width="200" align="center">
              </el-table-column>
              <el-table-column prop="sans" label="绑定域名数" width="180" align="center">
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <template slot-scope="scope">
                  <div class="flex just-center">
                    <span  class="tabdelt" style="color: red;"
                      @click="removeCdnPackage(scope.row.id)">删除</span>
                    <!-- <img src="@/assets/img/jinzsy.png" alt="" srcset="" class="tabjzst"> -->
                  </div>

                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
            class="flex just-center  mg-tp-23">
          </el-pagination>
        </div>


      </div>

    </div>
    <el-dialog title="提示" :visible.sync="coementVisible" width="40%">
      <div slot="title">
        <span>上传证书</span>
      </div>
      <el-divider></el-divider>
      <div class="dialbody">
        <div class="explain">
          <p>请到你的证书服务商处下载证书文件(如果有格式选择请选择 Nginx 格式)，如果是压缩包请先解压缩，然后:</p>
          <p>-将证书文件(一般是.pem 或ct 格式)用文本编器打开，将其中的内容复制到下方“证书”文本框，</p>
          <p>-将密钥文件(一般是key 格式)用文本编辑器打开，将其中的内容复制到下方"私钥 文本框;</p>
          <p>-证书内容(.pem 或cit)一般有两段或更多内容(从 BEGIN CERTIFICATE 到 END CERTIFICATE 为一段)，如果你的只有一段，则可能还有单独的 CA
            证书文件，请把它的内容加在证书后面构成两段，</p>
        </div>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="备注名" prop="name">
            <el-input v-model="form.name" placeholder="输入备注名"></el-input>
            <p>仅用于控制台区分</p>
          </el-form-item>
          <el-form-item label="证书" class="certifica" prop="certs">
            <el-input type="textarea" v-model="form.certs" placeholder="填入证书内容，包含CA中间证书"></el-input>
            <p>证书内容，需要包含完整证书链，至少有两段: 公钥和 CA 中间证书。一般为 .ct 或 .pem 式文件的内容.</p>
          </el-form-item>
          <el-form-item label="私钥" class="certifica" prop="key">
            <el-input type="textarea" v-model="form.key" placeholder="填入证书私钥"></el-input>
            <p>证书私钥内容,一般为key格式文件的内容</p>
          </el-form-item>
        </el-form>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="coementVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="addCdnPackage" class="confirm">上传证书</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '../../../utils/Date'
export default {

  props: {
    msg: String
  },
  data() {
    return {
      form: {
        name: '',
        key: "",
        certs: "",
      },
      currentpage: 0,
      offset: 0,
      limit: 10,
      total: 0,
      coementVisible: false,
      CdnPackagePage: [

      ],
      userInfo: {},
      rules: {
        name: [
          { required: true, message: '请填写证书备注名', trigger: 'change' }
        ],
        key: [
          { required: true, message: '请填写私钥', trigger: 'change' }
        ],
        certs: [
          { required: true, message: '请填写证书内容', trigger: 'change' }
        ],
      }
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getCdnPackagePage()
  },
  methods: {
    removeCdnPackage(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$apis.removeCdnPackage({
          id: id
        }).then((res) => {
          if (res.code == 0) {
            this.getCdnPackagePage()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    addCdnPackage() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$apis.addCdnPackage({
            mid: this.userInfo.id,
            name: this.form.name,
            key: this.form.key,
            certs: this.form.certs,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "上传成功"
              })
              this.coementVisible = false
              this.getCdnPackagePage()
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }

          })
        } else {
          this.$message({
            type: "error",
            message: "请继续填写完整"
          })
        }
      })

    },
    getCdnPackagePage() {
      this.$apis.getCdnPackagePage({
        offset: this.offset,
        limit: this.limit,
        mid: this.userInfo.id
      }).then((res) => {
        this.CdnPackagePage = res.data.rows
        this.total = res.data.total
        this.CdnPackagePage.forEach(el => {
          el.expires = formatDate(el.expires)
        })
      })
    },
    handleCurrentChange(val) {
      this.currentpage = val
      this.offset = (val - 1) * this.limit
      this.getCdnPackagePage()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getCdnPackagePage()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

.cancel {
  background-color: #6C757D;
  color: #fff;
  border: none;
  // padding: 15px;
}

.confirm {
  background-color: #007BFF;
  color: #fff;
  border: none;
}

/deep/.el-textarea__inner {
  height: 150px;
}

/deep/.el-form {
  margin-top: 10px;
}

/deep/.el-form-item__label {

  text-align: left;
}

.dialbody {
  padding: 10px;
}

.certifica {
  /deep/.el-form-item__label {
    line-height: 10px;
  }
}

/deep/.el-input {
  .el-input__inner {
    height: 30px;
  }
}

/deep/.el-form-item {
  margin-bottom: 8px;
}

/deep/.el-form {
  p {
    line-height: 15px;
    font-size: 12px;
    color: #C0BAC2;
  }
}

.explain {
  font-size: 12px;
  color: #004085;
  background-color: #CCE5FF;
  padding: 10px;
}

/deep/.el-divider {
  margin-top: 0;
  margin-bottom: 10px;
}





/deep/.el-dialog__header {
  padding-top: 10px;
  padding-left: 10px;
}

/deep/.el-dialog__header {
  span {
    font-size: 15px;
  }
}

/deep/.el-dialog__body {
  flex: 1;
  overflow: auto;
  padding: 0;
}

/deep/.el-progress-bar__innerText {
  color: #fff !important;
}



.purcha {
  background: #4272d7;
  border: none;
  line-height: 0px;
  font-size: 14px;
  padding: 13px 15px;
  border-radius: 0;
  margin-left: 10px;
}



.hedimg {
  width: 25px;
  height: 25px;
}











.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;

    h3 {
      margin: 0;
    }
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 800;
  }

  .has-gutter {
    color: #666666;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  // margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
    // font-weight: bold;
  }
}
</style>
