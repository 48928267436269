<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <div class="cz flex just-bet ">
          <h3 class=" flex">
            <img src="@/assets/img/fapgli.png" alt="" srcset="" class="hedimg">
            <span> 发票管理</span>

          </h3>
          <div class="ilable flex">
            <label class="color-999">当前可开票金额:</label>
            <label class="f-bold color-11f mg-lt-5">￥{{ invoiceMoney }}</label>
            <img src="@/assets/img/jis.png" alt="" srcset="" class="mg-lt-5">
          </div>
        </div>

        <div class="">
          <div class="deion">
            <h4>-本月产生的按量计费业务费用，在下个月2号生成月结算单以后，才可以索取发票；</h4>
            <h4>-纸质发票申请信息填写错误的,在"待处理"状态下可以在表格中自助撤销申请，进入开票流程之后将无法撤销,此时需要重开发票，请联系客服</h4>
          </div>


          <div>
            <el-table :data="InvoiceListByMid" style="width: 100%" empty-text="暂无数据...">
              <el-table-column prop="createTime" label="申请时间" width="300" align="center">
              </el-table-column>
              <el-table-column prop="name" label="发票抬头" width="300" align="center">
              </el-table-column>
              <el-table-column prop="invoiceTypeName" label="发票类型" width="150" align="center">
              </el-table-column>
              <el-table-column prop="amount" label="发票总额" align="center" width="400">
              </el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.isSend == 1">已开票</span>
                  <span v-if="scope.row.isSend == 0">未开票</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="address" label="操作">
                <template>
                  <div class="flex">
                    <img src="@/assets/img/shanchu.png" alt="" srcset="" class="tabdelt">
                    <el-button type="primary" @click="dialogVisible = true" class="appbton">申请退款</el-button>
                  </div>

                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="hadccurrentpage" :page-sizes="[10, 20, 30, 40]" :page-size="limit"
            layout=" sizes, prev, pager, next, jumper" :total="total" class="flex just-center mg-tp-23">
          </el-pagination>
        </div>
        <div class="infor flex al-baseline">
          <div class="commerc">
            <div class="bill flex">
              <span>发票抬头</span>
              <div class="flex modif" @click="updateInvoiceHead" v-if="invoiceHead.name">
                <img src="@/assets/img/weisz.png" alt="" srcset="">
                <label>修改</label>

              </div>

            </div>
            <el-divider></el-divider>
            <!-- //没有数据的时候 -->
            <div class="invoice" v-if="!invoiceHead.name">
              <span> 需要先设置发票信息才能申请开票，请<a @click="iodialog = true" class="curpoin">设置</a></span>
            </div>
            <div class="invoitype" v-else>
              <div class="itype">
                <span>发票类型</span>
                <label>{{ invoiceHead.invoiceType == 1 ? '企业增值税普通发票' : '企业增值税专用发票' }}</label>
              </div>
              <div class="itype">
                <span>发票抬头</span>
                <label>{{ invoiceHead.name }}</label>
              </div>
            </div>
          </div>

          <div class="delidress">
            <div class="delie flex">
              <span>寄送地址管理</span>
              <div class="flex modif" @click="Adresdialog = true" v-if="!invoiceAddress.length > 0">
                <img src="@/assets/img/tjfap.png" alt="" srcset="">
                <label>添加</label>

              </div>
            </div>
            <el-divider></el-divider>
            <!-- //没有数据时候的 -->
            <div class="invoice" v-if="invoiceAddress.length == 0">
              <span> 还没有寄送地址，请<a @click="Adresdialog = true" class="curpoin">添加</a></span>
            </div>
            <div class="delivery" v-else>
              <el-table :data="invoiceAddress" style="width: 100%" empty-text="暂无数据...">
                <el-table-column prop="memName" label="收件人姓名" width="100">
                </el-table-column>
                <el-table-column prop="phone" label="电话号码" width="130">
                </el-table-column>
                <el-table-column prop="address" label="地址" width="200">
                </el-table-column>
                <el-table-column prop="address" label="操作">
                  <template slot-scope="scope">
                    <div>
                      <label class="curr"> 当前默认</label>
                      <label class="" style="color:#499CFF ;" @click="updateInvoiceAddress(scope.row)">修改</label>
                      <label style="color: #EA76A8;" @click="deleteInvoice(scope.row.id)">删除</label>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>

        </div>
        <div class="applling">
          <el-button type="primary" @click="apilling" class="appbton">申请开票</el-button>
        </div>
      </div>
    </div>
    <!-- //发票信息设置 -->
    <div class="invoice-dialog">
      <el-dialog title="提示" :visible.sync="iodialog" width="30%">
        <div slot="title">
          <span>发票信息设置</span>

        </div>
        <el-divider></el-divider>
        <div class="">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="发票类型" prop="productType">
              <div class="flex-column al-baseline">
                <el-select v-model="form.productType" placeholder="请选择发票类型">
                  <el-option v-for="item in InTypeoption" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>

                <label class="quey">如有疑问，建议联系贵司财务确认后在选择</label>
              </div>

            </el-form-item>
            <el-form-item label="发票抬头" prop="name">
              <el-input v-model="form.name" @change="nameChange(1)"></el-input>
            </el-form-item>
            <el-form-item label="纳税人识别号" prop="taxId">
              <div class="flex-column al-baseline">
                <el-input v-model="form.taxId" @change="nameChange(2)"></el-input>
                <label class="quey">以三证合一的请输入18位统一社会信用代码。</label>
              </div>

            </el-form-item>
            <el-form-item label="基本开户银行">
              <div class="flex-column al-baseline">
                <el-input v-model="form.accountOpeningBank" @change="nameChange(3)"></el-input>
                <label class="quey">如：中国建设银行长沙麓谷科技支行</label>
              </div>

            </el-form-item>
            <el-form-item label="基本开户账号">
              <div class="flex-column al-baseline">
                <el-input v-model="form.publicBankAccount" @change="nameChange(4)"></el-input>
                <label class="quey">该银行的户名必须是你上面填写的企业名称。</label>
              </div>

            </el-form-item>
            <el-form-item label="注册场所地址">
              <el-input v-model="form.address" placeholder="请填写营业执照上的注册地址"></el-input>

            </el-form-item>
            <el-form-item label="注册固定电话">
              <el-input v-model="form.phone" placeholder="请填写企业有效的联系电话" @change="nameChange(5)"></el-input>

            </el-form-item>
            <!-- <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item> -->
          </el-form>
        </div>

        <div slot="footer">
          <el-divider></el-divider>
          <el-button type="primary" class="cancel" @click="iodialog = false">取消</el-button>
          <el-button type="primary" @click="addInvoiceHead" class="coing">保存设置</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- //添加地址 -->
    <div class="adress-dialog">
      <el-dialog title="提示" :visible.sync="Adresdialog">
        <div slot="title">
          <span>寄送地址</span>

        </div>
        <el-divider></el-divider>
        <div class="">
          <el-form ref="sendform" :model="sendform" :rules="sendrules">
            <el-form-item label="收件人姓名" prop="memName">
              <el-input v-model="sendform.memName" placeholder="请填写营业执照上的收件人姓名" @change="sendformChange(2)"></el-input>

            </el-form-item>
            <el-form-item label="邮寄地址" prop="areaName">
              <div class="flex-column al-baseline">
                <div class="flex">
                  <el-select v-model="sendform.provinceName" placeholder="-请选择-" class="mailadd"
                    @change="seletekey($event, 'province')">
                    <el-option v-for="(val, key) in provinceName" :key="key" :label="val" :value="val" />
                  </el-select>
                  <el-select v-model="sendform.cityName" placeholder="-请选择-" class="mailadd maitow"
                    @change="seletekey($event, 'city')">
                    <el-option v-for="(val, key) in cityName" :key="key" :label="val" :value="val" />
                  </el-select>
                  <el-select v-model="sendform.areaName" placeholder="-请选择-" class="mailadd maitow"
                    @change="seletekey($event, 'area')">
                    <el-option v-for="(val, key) in areaName" :key="key" :label="val" :value="val" />
                  </el-select>
                </div>

                <label class="quey">暂不支持配送到中国以外的地区</label>
              </div>

            </el-form-item>

            <el-form-item label="详细地址" prop="address" >
              <el-input v-model="sendform.address" placeholder="请填写详细地址,无需重复省市区信息" @change="sendformChange(3)"></el-input>

            </el-form-item>
            <el-form-item label="联系方式" prop="phone" >
              <el-input v-model="sendform.phone" placeholder="请填写联系方式" @change="sendformChange(1)"></el-input>

            </el-form-item>
            <!-- <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item> -->
          </el-form>
        </div>
        <el-divider></el-divider>
        <div slot="footer">
          <el-button type="primary" class="cancel" @click="Adresdialog = false">取消</el-button>
          <el-button type="primary" @click="addInvoiceAddress" class="coing">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- //选择开票产品-->
    <div class="noInvoice-dialog">
      <el-dialog :visible.sync="noInvoicedialog">
        <el-table :data="noInvoList" style="width: 100%" @selection-change="handleSelectionChange"
          empty-text="暂无符合条件的记录...">
          <el-table-column type="selection" width="75">
          </el-table-column>
          <el-table-column prop="productName" label="产品" align="center">
            <template slot-scope="scope">
              {{ scope.row.productType == 0 ? 'CDN' : 'SSL证书' }}
            </template>
          </el-table-column>
          <el-table-column prop="payTime" label="订单完成时间" align="center">
          </el-table-column>
          <el-table-column prop="payPrice" label="金额" align="center">
          </el-table-column>
        </el-table>
        <el-pagination @size-change="noInvohandleSizeChange" @current-change="noInvohandleCurrentChange"
          :current-page="currentpage" :page-sizes="[10, 20, 30, 40]" :page-size="limit"
          layout=" sizes, prev, pager, next, jumper" :total="noInvototal" class="flex just-flex-end mg-tp-23">
        </el-pagination>
        <div class="flex just-flex-end  mg-tp-23">
          <el-button type="primary" @click="addInvoice" class="appbton">确定</el-button>
        </div>

      </el-dialog>
    </div>
  </div>
</template>

<script>

import { pcaa } from 'area-data'
export default {

  props: {
    msg: String
  },
  data() {
    return {
      pcaaData: pcaa,
      InTypeoption: [],
      areaName: [],
      OrderListByMid: [],
      provinceName: pcaa['86'],
      cityName: [],
      currentpage: 0,
      hadccurrentpage: 0,
      sendform: {
        address: "",
        phone: "",
        memName: "",
        provinceName: "",
        cityName: "",
        areaName: "",
      },
      sendrules: {
        memName: [
          { required: true, message: '请输入收件人姓名', trigger: 'change' },
        ],
        areaName: [
          { required: true, message: '请选择区域', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' }
        ],
      },
      rules: {
        name: [
          { required: true, message: '请输入发票抬头', trigger: 'change' },
        ],
        productType: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        taxId: [
          { required: true, message: '请输入纳税人识别号', trigger: 'change' }
        ],

      },
      form: {
        productType: "",
        name: "",
        taxId: "",
        accountOpeningBank: "",
        publicBankAccount: "",
        address: "",
        phone: "",
      },
      iodialog: false,
      Adresdialog: false,
      noInvoicedialog: false,
      deliveryData: [
      ],
      noInvoList: [
      ],
      InvoiceListByMid: [],
      userInfo: {},
      offset: 0,
      limit: 10,
      noInvooffset: 0,
      noInvolimit: 10,
      total: 0,
      noInvototal: 0,
      invoiceMoney: "",
      invoiceAddress: [],
      invoiceHead: {},
      id: "",
      headid: "",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getInvoiceListByMid()
    this.getInvoiceHead()
    this.getInvoiceType()
    this.noInvoiceOrderList()
  },
  methods: {
    sendformChange(val) {
      const mPattern = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/; //手机号
      const regName = /^[\u2E80-\u9FFF]+$/;
      if (val == 1) {
        if (!mPattern.test(this.sendform.phone)) {
          this.$message({
            type: "error",
            message: "请输入正确的手机号"
          })
          this.sendform.phone = ""
        }
      } else if (val == 2) {
        if (!regName.test(this.sendform.memName)) {
          this.$message({
            type: "error",
            message: "请输入正确的姓名"
          })
          this.sendform.memName = ""
        }
      } 

    },
    nameChange(val) {
      const invoiceTitle = /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/ //发票抬头
      const identifyNumber = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/ //纳税人识别号
      const openingbank = /^[\u4e00-\u9fa5.·\u36c3\u4DAE]{2,}$/ //开户行
      const mPattern = /^1[2345789]d{9}$/; //手机号
      const accountnumber = /^[0-9\-]*$/
      if (val == 1) {
        if (!invoiceTitle.test(this.form.name)) {
          this.$message({
            type: "error",
            message: "请输入正确的发票抬头"
          })
          this.form.name = ""
        }
      } else if (val == 2) {
        if (!identifyNumber.test(this.form.taxId)) {
          this.$message({
            type: "error",
            message: "请输入正确的识别号"
          })
          this.form.taxId = ""
        }
      } else if (val == 3) {
        if (!openingbank.test(this.form.accountOpeningBank)) {
          this.$message({
            type: "error",
            message: "请输入正确的开户行"
          })
          this.form.accountOpeningBank = ""
        }
      } else if (val == 4) {
        if (!accountnumber.test(this.form.publicBankAccount)) {
          this.$message({
            type: "error",
            message: "请输入正确的账号"
          })
          this.form.publicBankAccount = ""
        }
      } else if (val == 5) {
        if (!mPattern.test(this.form.phone)) {
          this.$message({
            type: "error",
            message: "请输入正确的手机号"
          })
          this.form.phone = ""
        }
      }

    },
    noInvohandleCurrentChange(val) {
      this.currentpage = val
      this.noInvooffset = (val - 1) * this.limit
      this.noInvoiceOrderList()
    },
    noInvohandleSizeChange(val) {
      this.noInvolimit = val
      this.noInvoiceOrderList()
    },
    handleSelectionChange(val) {
      this.Invoicedata = val
    },
    apilling() {
      if (this.invoiceHead.id && this.invoiceAddress.length > 0) {
        this.noInvoicedialog = true
      } else {
        this.$message({
          type: "error",
          message: "请填写发票抬头或者寄送地址"
        })
      }
    },
    //未开票订单表格
    noInvoiceOrderList() {
      this.$apis.noInvoiceOrderList({
        mid: this.userInfo.id,
        offset: this.noInvooffset,
        limit: this.noInvolimit
      }).then((res) => {
        this.noInvoList = res.data.rows
        this.noInvototal = res.data.total
      })
    },
    //确定开票
    addInvoice() {
      let arr = []
      this.Invoicedata.forEach((el) => {
        arr.push(el.id)
      })
      this.$apis.addInvoice({
        memberId: this.userInfo.id,
        orderIds: arr.join(','),
        invoiceHeadId: this.invoiceHead.id,
        invoiceAddressId: this.invoiceAddress[0].id,
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.msg
        })
        this.noInvoicedialog = false
        this.getInvoiceListByMid()
      })
    },
    //编辑寄送地址
    updateInvoiceAddress(data) {
      this.Adresdialog = true
      this.sendform.address = data.address
      this.sendform.phone = data.phone
      this.sendform.memName = data.memName
      this.sendform.provinceName = data.provinceName
      this.sendform.cityName = data.cityName
      this.sendform.areaName = data.areaName
      this.id = data.id

    },
    seletekey(e, type) {

      if (e) {
        switch (type) {
          case 'province':
            Object.keys(this.provinceName).filter(item => {
              if (this.provinceName[item] === e) {
                this.cityName = this.pcaaData[item]
              }
            })
            break
          case 'city':
            Object.keys(this.cityName).filter(item => {
              if (this.cityName[item] === e) {
                this.areaName = this.pcaaData[item]
              }
            })
            break
          case 'area':
            break
        }
      }
    },
    deleteInvoice(id) {
      this.$confirm('此操作将永久删除该寄送地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$apis.updateInvoiceAddress({
          id: id,
          usableFlag: 0
        }).then((res) => {
          if (res.code == 0) {
            this.sendform = {
              address: "",
              phone: "",
              memName: "",
              provinceName: "",
              cityName: "",
              areaName: "",
            },
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            this.getInvoiceHead()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }


        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    //添加地址
    addInvoiceAddress() {
      this.$refs['sendform'].validate((valid) => {
        if (valid) {
          if (this.id) {
            this.$apis.updateInvoiceAddress({
              id: this.id,
              address: this.sendform.address,
              phone: this.sendform.phone,
              memName: this.sendform.memName,
              provinceName: this.sendform.provinceName,
              cityName: this.sendform.cityName,
              areaName: this.sendform.areaName,
            }).then((res) => {
              this.$message({
                type: "success",
                message: "编辑成功"
              })
              this.getInvoiceHead()
              this.Adresdialog = false
            })
          } else {

            this.$apis.addInvoiceAddress({
              mid: this.userInfo.id,
              address: this.sendform.address,
              phone: this.sendform.phone,
              memName: this.sendform.memName,
              provinceName: this.sendform.provinceName,
              cityName: this.sendform.cityName,
              areaName: this.sendform.areaName,
            }).then(() => {
              this.$message({
                type: "success",
                message: "添加成功"
              })
              this.getInvoiceHead()
            })
            this.Adresdialog = false


          }
        } else {
          this.$message({
            type: "error",
            message: "请继续填写完整"
          })
          return
        }
      });


    },
    //确定添加发票抬头
    addInvoiceHead() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.headid) {
            this.$apis.updateInvoiceHead({
              id: this.headid,
              name: this.form.name,
              taxId: this.form.taxId,
              accountOpeningBank: this.form.accountOpeningBank,
              publicBankAccount: this.form.publicBankAccount,
              address: this.form.address,
              phone: this.form.phone,
              invoiceType: this.form.productType,
            }).then((res) => {
              this.iodialog = false
              this.headid = ""
              this.getInvoiceHead()
              this.$message({
                type: "success",
                message: res.msg
              })
            })
          } else {
            this.$apis.addInvoiceHead({
              memberId: this.userInfo.id,
              name: this.form.name,
              taxId: this.form.taxId,
              accountOpeningBank: this.form.accountOpeningBank,
              publicBankAccount: this.form.publicBankAccount,
              address: this.form.address,
              phone: this.form.phone,
              invoiceType: this.form.productType,
            }).then((res) => {
              this.iodialog = false
              this.getInvoiceHead()
              this.$message({
                type: "success",
                message: res.msg
              })
            })
          }

        } else {
          this.$message({
            type: "error",
            message: "请继续填写完整"
          })
          return
        }
      });


    },
    //编辑抬头
    updateInvoiceHead() {
      this.headid = this.invoiceHead.id
      this.form.productType = this.invoiceHead.invoiceType
      this.form.name = this.invoiceHead.name
      this.form.taxId = this.invoiceHead.taxId
      this.form.accountOpeningBank = this.invoiceHead.accountOpeningBank
      this.form.publicBankAccount = this.invoiceHead.publicBankAccount
      this.form.address = this.invoiceHead.address
      this.form.phone = this.invoiceHead.phone
      this.iodialog = true
    },
    //发票类型
    getInvoiceType() {
      this.$apis.getInvoiceType({
        mid: this.userInfo.id
      }).then((res) => {
        this.InTypeoption = res.data
      })
    },
    //发票抬头
    getInvoiceHead() {
      this.$apis.getInvoiceHead({
        mid: this.userInfo.id
      }).then((res) => {
        this.invoiceHead = res.invoiceHead
        this.invoiceAddress = res.invoiceAddress
      })
    },
    getInvoiceListByMid() {
      this.$apis.getInvoiceListByMid({
        mid: this.userInfo.id,
        offset: this.offset,
        limit: this.limit,
      }).then((res) => {
        this.invoiceMoney = res.invoiceMoney
        this.InvoiceListByMid = res.data.rows
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.hadccurrentpage = val
      this.offset = (val - 1) * this.limit
      this.getInvoiceListByMid()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getInvoiceListByMid()
    },
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

.delivery {
  label {
    margin-right: 5px;
  }

  .curr {
    color: #c7c5c4;
  }
}

.applling {
  text-align: center;
  width: 100%;
  margin-top: 70px;

  .appbton {
    width: 308px;
    text-align: center;
    background-color: #286EFA;
    border: none;
  }
}

.hedimg {
  width: 25px;
  height: 25px;
}

.adress-dialog {

  .el-divider {
    margin-top: 20px !important;
  }

  /deep/.el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);


    .maitow {
      margin-left: 10px;

    }

    .mailadd {

      .el-input__inner {
        width: 150px;
      }
    }

    .el-dialog__header {
      font-size: 15px;
    }

    .el-form-item {
      margin-bottom: 25px;
      display: flex;
    }

    .el-form-item__label {
      text-align: left;
      font-size: 12px;
      width: 120px;
    }

    .quey {
      font-size: 12px;
      margin: 0;
      line-height: 1.5;
      color: #c4c4c4;
    }

    .el-dialog__body {
      flex: 1;
      overflow: auto;
    }

    .el-divider {
      margin: 10px 0;
    }

    .el-input__inner {
      width: 500px;
    }

    .el-select__caret {
      line-height: 46px;
    }

    .cancel {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
    }

  }
}

.invoice-dialog {

  /deep/.el-dialog {
    width: 515px !important;

    .el-dialog__header {
      font-size: 15px;
    }

    .el-form-item {
      margin-bottom: 15px;
      display: flex;
    }

    .el-form-item__label {
      text-align: left;
      font-size: 12px;
      width: 120px;
    }

    .quey {
      font-size: 12px;
      margin: 0;
      line-height: 1.5;
      color: #c4c4c4;
    }

    .el-dialog__body {
      padding-top: 0;
      padding-bottom: 0px;
    }

    .el-divider {
      margin: 10px 0;
    }

    .el-input__inner {
      width: 350px !important;
    }

    .el-select__caret {
      line-height: 46px;
    }

    .cancel {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
    }

  }
}



.infor {
  margin-top: 20px;

  .table {}

  .el-divider {
    margin: 20px 0 0 0;
  }

  .invoitype {
    margin-left: 40px;

    .itype {
      margin-top: 20px;

      span {
        color: #98938E;
        font-size: 12px;
      }

      label {
        font-size: 18px;
        font-weight: bold;
        color: #756A6B;
        margin-left: 60px;
      }
    }
  }

  .commerc {
    .invoice {
      margin: 80px 0;
      text-align: center;
      color: #aaa;
      font-size: 12px;

      a {
        color: #007bff;
        text-decoration: none;
        margin-left: 5px;
        font-size: 13px;
      }
    }
  }

  .delidress {
    margin-left: 40px;
    width: 502px;

    .invoice {
      margin: 80px 0;
      text-align: center;
      color: #aaa;
      font-size: 12px;

      a {
        color: #007bff;
        text-decoration: none;
        margin-left: 5px;
        font-size: 13px;
      }
    }
  }

  .delie {
    color: #333333;


    span {
      font-size: 15Px;
    }

    label {
      color: #007bff;
      margin-left: 3px;
      font-size: 14Px;
    }

    img {
      width: 10px;
      height: 10px;
    }
  }

  .modif {
    margin-left: 10px;
  }

  .bill {
    width: 462px;
    color: #333333;

    span {
      font-size: 15Px;
    }

    label {
      color: #007bff;
      margin-left: 5px;
      font-size: 14Px;
    }

    img {
      width: 10px;
      height: 10px;
    }
  }
}

.deion {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  padding: 10px 20px;
  font-size: 12px;

  h4 {
    margin: 0;
  }
}

.alert {
  span {
    width: 56px;
    height: 50px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin-right: 54px;
  }
}

.appbton {
  padding: 0;
  width: 59px;
  height: 30px;
  line-height: 0;
  border-radius: 4px;
  background-color: rgba(95, 165, 242, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
}

.tabdelt {
  width: 32px;
  height: 34px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  margin-bottom: 35px;
  margin-top: 0;

  .ilable {
    img {
      width: 10px;
      height: 10px;
    }
  }

  span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 24px;
    color: #333;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__inner {

    width: 200px;
    height: 30px;
    line-height: 17px;
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__inner {

    width: 200px;
    height: 30px;
    line-height: 17px;
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }
}
</style>
