<template>
  <div class="w-100 h-100">
    <div class="flex just-bet cz">
      <h3 class=" flex">
        <img src="@/assets/img/yumgliwxz.png" alt="" srcset="" class="hedimg">
        <span> 域名管理</span>

      </h3>
      <div>
        <el-button type="primary" class="purcha" @click="Adddoame">+ 添加域名</el-button>
      </div>
    </div>

    <div class="recar">
      <div class="reacrmain">

        <div>
          <div class="flex">

            <div class="sjfw">
              <el-select v-model="productCode" placeholder="请选择" @change="productChange">
                <el-option v-for="item in optionstype" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sjfw">
              <el-select v-model="status" placeholder="请选择" @change="statusChange">
                <el-option v-for="item in optstatus" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sjfw">
              <el-input v-model="domain" placeholder="请输入域名关键词" @change="domainChange"></el-input>
            </div>
          </div>
          <div>
            <el-table :data="MemberDomainList" style="width: 100%" empty-text="暂无符合条件的记录...">
              <el-table-column prop="id" label="编号" width="80">

              </el-table-column>
              <el-table-column label="域名" width="210" prop="domain">
                <template slot-scope="scpoe">
                  <div class="flex">
                    <span>{{ scpoe.row.domain }}</span>
                    <img src="@/assets/img/cwl.png" title="配置下发中" class="tabimg" v-if="scpoe.row.isOrder == 1">
                  </div>

                </template>
              </el-table-column>
              <el-table-column prop="cname" width="170" align="center" label="域名">
                <template slot="header">
                  <div class="flex">
                    <span> CNAME </span>
                    <!-- <img src="@/assets/img/jis.png" alt="" srcset="" class="tabimg"> -->
                  </div>

                </template>
              </el-table-column>
              <el-table-column label="加速类型" width="190" align="center">
                <template slot-scope="scpoe">
                  <div>
                    {{
                      scpoe.row.productCode == '001' ?
                      '静态加速' : scpoe.row.productCode == '003' ?
                        '下载加速' : scpoe.row.productCode == '004'
                          ? '视频点播加速' : scpoe.row.productCode == '008'
                            ? 'CDN加速' : scpoe.row.productCode == '006' ?
                              '全站加速' : scpoe.row.productCode == '007' ? '安全加速' : '下载加速闲时'
                    }}
                  </div>

                </template>
              </el-table-column>
              <el-table-column prop="Accel" label="加速范围" align="center" width="190">
                <template slot-scope="scpoe">
                  <div>
                    {{
                      scpoe.row.areaScope == '1' ?
                      '国内' : scpoe.row.areaScope == '2' ?
                        '海外' : '全球'
                    }}
                  </div>

                </template>
              </el-table-column>
              <el-table-column label="状态" width="200" align="center">
                <template slot-scope="scpoe">
                  <div class="">
                    <span class="color-5D">{{ scpoe.row.status == '1' ?
                      '配置下发中' : scpoe.row.status == '2' ?
                        '审核成功' : scpoe.row.status == '3'
                          ? '配置中' : scpoe.row.status == '4'
                            ? '已启用' : scpoe.row.status == '5' ?
                              '停止中' : scpoe.row.status == '6' ? '已停止' : scpoe.row.status == '7' ? '删除中' :
                                scpoe.row.status == '8' ? '已删除' : scpoe.row.status == '9' ? '审核失败'
                                  : scpoe.row.status == '10' ? '配置失败' : scpoe.row.status == '11' ? '停止失败' : "删除失败"
                    }}</span>
                  </div>

                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="200" align="center">
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <template slot-scope="scpoe">
                  <div class=" ">
                    <span class="color-47 curpoin wte-nrap" @click="comPile(scpoe.row, true)">查看</span>
                    <span class="shuxian"></span>

                    <span class="color-47 curpoin wte-nrap" @click="comPile(scpoe.row, false)"
                      v-if="scpoe.row.isOrder == 1 ? false : scpoe.row.status == 1 ? false : scpoe.row.status == 6 ? false : true">编辑</span>

                    <span class="shuxian"
                      v-if="scpoe.row.isOrder == 1 ? false : scpoe.row.status == 1 ? false : scpoe.row.status == 6 ? false : true"></span>


                    <span class="color-47 curpoin wte-nrap" v-if="scpoe.row.status == 4"
                      @click="stopDomain(scpoe.row.id, 1)">停用</span>
                    <span class="color-47 curpoin wte-nrap" v-if="scpoe.row.status == 6"
                      @click="stopDomain(scpoe.row.id, 2)">启用</span>
                    <!-- <span class="shuxian"></span> -->

                    <span class="shuxian" v-if="scpoe.row.status == 6"></span>

                    <span class="color-47 curpoin wte-nrap" v-if="scpoe.row.status == 6"
                      @click="removeDomain(scpoe.row.id)">删除</span>
                    <!-- <img src="@/assets/img/sd.png" alt="" srcset="" class="tabthre"> -->
                  </div>

                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
            class="flex just-center mg-tp-23">
          </el-pagination>
        </div>

      </div>
    </div>
    <!-- //添加域名 -->
    <div class="puricate">
      <el-drawer title="添加域名" :visible.sync="purwer" @close="draClose">
        <div class="dawer">
          <div>
            <div class="flex al-start">
              <span class="cho">基本信息</span>
            </div>
            <div>
              <el-form :model="ruleForm" :rules="rules" class="eForm" ref="reForm">
                <el-form-item label="加速域名" prop="domain">
                  <el-input v-model="ruleForm.domain" @change="domChange"></el-input>
                  <p class="verficik" v-if="!verify_result">域名 [{{ ruleForm.domain }}] 需要完成归属权认证,点击进入<a
                      @click="getVerifyContent">验证环节</a></p>
                  <p>加速域名为需要使用加速服务的域名，支持泛域名</p>
                </el-form-item>
                <el-form-item label="加速类型" prop="productCode">
                  <el-select v-model="ruleForm.productCode" placeholder="请选择加速类型">
                    <el-option v-for="item in optionstype" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <p>产品类型一经确认，无法自助修改，请根据您的业务类型谨情选择。</p>
                </el-form-item>
                <el-form-item label="加速范围" prop="areaScope">
                  <el-radio-group v-model="ruleForm.areaScope">
                    <el-radio label="1">中国内地</el-radio>
                    <el-radio label="2" disabled>全球(不含中国内地)</el-radio>
                    <el-radio label="3" disabled>全球</el-radio>
                  </el-radio-group>
                  <p>加速范围包含中国内地时，加速域名请先完成在中国大陆的ICP备案，同时完成公安网备案</p>
                  <p>您当前尚未开通CDN加速境外服务，如果有需要请联系客户经理开通CDN加速-全球(不含中国内地)服务</p>

                </el-form-item>
                <div>
                  <div class="flex al-start">
                    <span class="cho">源站设置</span>
                  </div>
                  <div class="mg-tp-10">
                    <el-form-item label="源站：" class="yuanz" prop="souation" v-for="(item, index) in ruleForm.origins"
                      :key="item.id">
                      <div class="flex">

                        <el-input v-model="ruleForm.origins[index].origin" @change="ruinsChange"></el-input>
                        <span class="wte-nrap ">层级</span>
                        <el-select v-model="ruleForm.origins[index].role" placeholder="请选择层级" class="selctcj"
                          :disabled="index == 0">
                          <el-option label="主" value="master"></el-option>
                          <el-option label="备" value="slave"></el-option>
                        </el-select>
                      </div>
                      <div v-if="cudex == index">
                        <span style="color: #95A4D8;" @click="Nercation(index)">+新增源站</span>
                        <p>支持IP或域名，最多可添加60个</p>
                      </div>
                    </el-form-item>

                    <el-form-item label="权重" prop="weight">
                      <div>
                        <el-input-number v-model="ruleForm.weight" controls-position="right" :min="1" :max="100"
                          :controls="false"></el-input-number>
                        <p>范围:1-100</p>
                      </div>
                    </el-form-item>
                    <el-form-item label="回源协议" prop="oprotocoport">
                      <el-radio-group v-model="ruleForm.oprotocoport" @change="portChange">
                        <el-radio label="1">HTTP</el-radio>
                        <el-radio label="2">HTTPS</el-radio>
                        <el-radio label="3">跟随</el-radio>
                      </el-radio-group>

                    </el-form-item>
                    <el-form-item label="源站端口：" class="port" prop="port1">
                      <div class="flex">
                        <div class="flex">
                          <span>http</span>
                          <el-input v-model="ruleForm.port1" class="htinput"></el-input>
                        </div>
                        <div class="flex hps">
                          <span>https</span>
                          <el-input v-model="ruleForm.port2" class="htinput" :disabled="true"></el-input>
                        </div>
                      </div>

                    </el-form-item>

                  </div>
                </div>
                <el-divider></el-divider>
                <div class="base flex ">

                  <div class="butto">
                    <el-button type="primary" class="cancel" @click="purwer = false">取消</el-button>
                    <el-button type="primary" class="acknowle" @click="addMemberDomain">确认添加</el-button>
                  </div>
                </div>
              </el-form>
            </div>
          </div>




        </div>

      </el-drawer>
    </div>
    <div class="Valiaime">
      <el-dialog title="提示" :visible.sync="ValiVisible" width="90%">
        <div slot="title">
          <span>认证域名归属权</span>

        </div>
        <el-divider></el-divider>
        <div class="alert">
          <div slot="" class="alert-info">
            <img src="" alt="" srcset="">
            <span>域名[{{ domain_zone }}] 需要完成归属权验证，您可以通过DNS解析验证或文件验证 </span>
            <el-tabs v-model="authentica" @tab-click="handleClick">
              <el-tab-pane label="DNS解析认证" name="1">
                1、请在您的域名DNS服务商添加以下 TXT 记录。
                <div class="valtab">
                  <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="type" label="记录类型" width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="dnsverify" label="主机记录" width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="content" label="记录值" align="center">
                    </el-table-column>
                  </el-table>
                </div>
                <p class="mg-tp-23">2、等待TXT解析生成。</p>
                <p class="mg-tp-23"> 3、点击下方按钮进行验证。</p>
                <el-button type="primary" class="mg-tp-23" @click="verification(1)">验证</el-button>
                <p v-show="nsiicatisult" class="esult">验证结果：{{ verifresult }}</p>
              </el-tab-pane>
              <el-tab-pane label="文件认证" name="2">
                <p class="mg-tp-23">1、请在您的111.com域名源站根目录创建一个文件。 验证操作指南文件名: dnsverifytxt文件内容:
                  20230720094129617131e13fa5499fb49f9bfdbaf0dbff73137ce0b629493eb8</p>
                <p class="mg-tp-23"> 2、通过 http(s)://111.com/dnsverify.txt
                  访问到该文件</p>
                <p class="mg-tp-23"> 3、点击下方按钮进行验证。</p>
                <el-button type="primary" class="mg-tp-23" @click="verification(2)">验证</el-button>
                <p v-show="nsiicatisult" class="esult">验证结果：{{ verifresult }}</p>
              </el-tab-pane>

            </el-tabs>
          </div>
          <!-- <div class="off">
          <span>开关</span>
          <el-checkbox v-model="checked">接收余额预警通知</el-checkbox>
        </div> -->


        </div>
        <el-divider></el-divider>
        <div slot="footer">
          <el-button class="cancel" @click="close">关闭</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
      value: '',
      offset: 0,
      limit: 10,
      total: 0,
      cudex: 0,
      authentica: "1",
      userInfo: {},
      tableData: [],
      domain_zone: "",
      ruleForm: {
        domain: '',
        productCode: '',
        areaScope: "1",
        souation: "",
        weight: 10,
        port1: "80",
        port2: "443",
        oprotocoport: "1",
        origins: [{
          origin: "",
          weight: 1,
          role: "master",
          port: "",
          oprotocoport: "1",
        },

        ],
      },
      rules: {
        domain: [
          { required: true, message: '请填写加速域名', trigger: 'bule' }
        ],
        productCode: [
          { required: true, message: '请选择加速类型', trigger: 'change' }
        ],
        areaScope: [
          { required: true, message: '请选择加速范围', trigger: 'change' }
        ],
        souation: [
          { required: true, message: '请填写源站', trigger: 'change' }
        ],
        weight: [
          { required: true, message: '请填写权重', trigger: 'change' }
        ],
        oprotocoport: [
          { required: true, message: '请选择回源协议', trigger: 'change' }
        ],
        port1: [
          { required: true, message: '请填写端口号', trigger: 'change' }
        ]
      },
      productCode: "",
      status: "",
      purwer: false,
      ValiVisible: false,
      currentpage: 0,
      num: 1,
      imageUrl: "",
      value1: "",
      domain: "",
      optstatus: [{
        value: '',
        label: '所有状态'
      }, {
        value: '1',
        label: '配置下发中'
      },
      {
        value: '4',
        label: '已启用'
      },
      {
        value: '6',
        label: '已停止'
      },
      ],
      optionstype: [
        {
          value: '008',
          label: 'CDN加速'
        },
      ],
      MemberDomainList: [],
      verifresult: "",
      nsiicatisult: false,
      verify_result: true,//域名验证
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.portChange()
    this.getMemberDomainPage()
  },
  methods: {
    draClose() {
      this.purwer = false
      this.cudex = 0
    },
    Adddoame() {
      this.purwer = true
      this.verify_result = true
      this.ruleForm = {
        domain: '',
        productCode: '',
        areaScope: "",
        souation: "",
        weight: "",
        port1: "80",
        port2: "443",
        oprotocoport: "1",
        origins: [{
          origin: "",
          weight: "",
          role: "master",
          oprotocoport: "1",
          port1: "80",
          port2: "443",
          port: "",
        },

        ],
      }
    },
    removeDomain(id) {
      this.$confirm('此操作将永久删除该域名, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$apis.removeDomain({
          id: id
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg
            })
            this.getMemberDomainPage()
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    stopDomain(id, type) {
      this.$apis.stopDomain({
        id: id,
        type: type,
        mid: this.userInfo.id
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg
          })
          this.getMemberDomainPage()
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
      })
    },
    close() {
      this.ValiVisible = false
      if (this.verify_result) {
        this.verify_result = false
      }
    },
    verification(type) {
      this.$apis.verifyDomainDns({
        domain: this.ruleForm.domain,
        mid: this.userInfo.id,
        type: type
      }).then((res) => {
        if (res.data.verify_result) {
          this.verifresult = '验证结果:成功'
        } else {
          this.verifresult = '验证失败，失败原因为校验失败，TXT记承值与解析不一致'
        }
        this.verify_result = res.data.verify_result
        this.nsiicatisult = true
      })
    },
    getVerifyContent() {

      this.$apis.getVerifyContent({
        domain: this.ruleForm.domain,
        mid: this.userInfo.id
      }).then((res) => {
        this.tableData = []
        if (res.code == 0) {
          this.tableData.push({
            type: "TXT",
            content: res.data.content,
            dnsverify: res.data.dnsverify
          })
          this.domain_zone = res.data.domain_zone
          this.ValiVisible = true
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
      })
    },
    domChange() {
      let reg = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
      if (this.ruleForm.domain) {
        if (reg.test(this.ruleForm.domain)) {
          this.$apis.verifyDomain({
            domain: this.ruleForm.domain,
            mid: this.userInfo.id
          }).then((res) => {
            this.verify_result = res.data.verify_result
          })
        } else {
          this.$message({
            type: "error",
            message: "请输入正确的域名列入：www.ctcdn.cn"
          })
          this.ruleForm.domain = ""
        }

      } else {
        this.verify_result = false
      }
    },
    handleClick() {

    },
    memberEdit() {

    },
    ruinsChange() {
      this.ruleForm.souation = this.ruleForm.origins[this.cudex].origin
    },
    portChange() {
      if (this.ruleForm.origins[this.cudex].oprotocoport == 1) {
        this.ruleForm.origins[this.cudex].port = this.ruleForm.origins[this.cudex].port1
      } else {
        this.ruleForm.origins[this.cudex].port = "443"
      }
    },
    // port1Change() {
    //   if (this.ruleForm.origins[this.cudex].port1 == "") {
    //     this.hhztps = false
    //   } else {
    //     this.hhztps = true
    //     this.ruleForm.origins[this.cudex].port = this.ruleForm.origins[this.cudex].port1
    //   }
    // },
    // port2Change() {
    //   if (this.ruleForm.origins[this.cudex].port2 == "") {
    //     return
    //   } else {
    //     this.ruleForm.origins[this.cudex].port = "443"
    //   }
    // },
    Nercation(index) {
      if (this.ruleForm.origins.length <= 60) {
        this.ruleForm.origins.push({
          origin: "",
          weight: this.ruleForm.weight,
          role: this.ruleForm.origins[index].role,
          oprotocoport: this.ruleForm.oprotocoport,
          port: ""
        },)
        this.cudex = index + 1
      } else {
        this.$message({
          type: 'error',
          message: "已达上限"
        })
      }

    },
    addMemberDomain() {
      this.ruleForm.origins.forEach((el) => {
        el.port = this.ruleForm.oprotocoport == 1 ? this.ruleForm.port1 : this.ruleForm.port2
        el.weight = this.ruleForm.weight

      })
      this.$refs['reForm'].validate(valid => {
        if (valid) {
          this.$apis.addMemberDomain(JSON.stringify({
            "mid": this.userInfo.id,
            "domain": this.ruleForm.domain,
            "productCode": this.ruleForm.productCode,
            "areaScope": this.ruleForm.areaScope,
            "origins": this.ruleForm.origins,
          })).then((res) => {
            this.$message({
              type: "succcess",
              message: res.msg
            })
          })
        } else {
          this.$message({
            type: "waring",
            message: '继续填写完整'
          })
          return false;
        }
      });

    },
    handleSizeChange(val) {
      this.limit = val
      this.getMemberDomainPage()
    },
    handleCurrentChange(val) {
      this.currentpage = val
      this.offset = (val - 1) * this.limit
      this.getMemberDomainPage()
    },
    domainChange() {
      this.getMemberDomainPage()
    },
    productChange() {
      this.getMemberDomainPage()
    },
    statusChange() {
      this.getMemberDomainPage()
    },
    getMemberDomainPage() {
      this.$apis.getMemberDomainPage({
        mid: this.userInfo.id,
        productCode: this.productCode,
        status: this.status,
        offset: this.offset,
        limit: this.limit,
        domain: this.domain
      }).then((res) => {
        this.MemberDomainList = res.data.rows
        this.total = res.data.total
      })
    },
    comPile(data, examine) {
      this.$emit('comPile', data, examine)
    },
    handleAvatarSuccess() {

    },
    beforeAvatarUpload() {

    },
    handleChange(value) {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.shuxian {
  width: 1px;
  height: 10px;
  background-color: #666666;
  display: inline-block;
  margin: 0 5px;
}

/deep/.el-table {
  overflow-x: scroll;
}

/deep/.el-form-item__error {
  position: relative;
}

/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

// /deep/.el-input__inner {
//   height: 30px;
// }

.Valiaime {
  .esult {
    color: #ff8aa2
  }

  /deep/.el-dialog__body {
    padding-top: 0;

    /deep/.el-button--primary {
      background-color: #3d73f5;
      border: none;
    }
  }

  /deep/.el-table__header {
    /deep/.el-table__cell {
      background-color: #f7f8fa;
    }
  }

  /deep/.el-dialog__header {
    padding-bottom: 0;
  }
}

.unit {
  font-size: 14Px !important;
}

.verficik {
  color: #ff8aa2 !important;
}

/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

/deep/.eForm {


  margin-top: 20px;

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-form-item__label {
    text-align: left;
    width: 80px;
    white-space: nowrap;
  }

  a {
    color: #4249F1;
  }



  .el-radio-group {
    display: flex;
    align-items: center;
    margin-top: 13px;

    .el-radio__label {
      font-size: 12Px;
    }

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    // margin-top: 5px;
  }

  .el-input__inner {
    width: 300px;
  }
}

.puricate {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13Px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22Px;

      .unit {
        font-size: 14Px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {
    padding: 10px 15px 0px;
  }

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16Px;
    }

    span {
      font-size: 13Px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16Px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15Px;
    color: #333333;
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
    padding: 15px;
    margin-bottom: 10px;
  }

  /deep/.el-drawer__body {


    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15Px;
      color: #666666;
      font-weight: bold;
    }



    .pitcon {
      border-color: #007bff !important;
      background: url('../../../../../assets/img/xzl.png') no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22Px;

          label {
            font-size: 14Px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13Px;

        }
      }

      .left {
        p {
          font-size: 20Px;
          color: #666666;
        }
      }
    }
  }
}

.purcha {
  background-color: #4272D7;
  border: none;
  font-size: 12px !important;
}

.cz {

  margin-bottom: 10px;
  margin-top: 30px;

  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #333;
  }

  span {
    margin-left: 10px;
  }
}

.tabimg {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.hedimg {
  width: 25px;
  height: 25px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }

}

/deep/.el-table thead {
  color: #666666;
  font-weight: bold;
  font-size: 20px !important;
}

/deep/.el-table__body-wrapper {
  .tabthre {
    width: 15px;
    height: 15px;
  }

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  // /deep/.el-input__inner {

  //   width: 200px;
  //   height: 30px;
  //   line-height: 17px;
  //   color: rgba(16, 16, 16, 1);
  //   font-size: 12px;
  //   border: 1px solid rgba(187, 187, 187, 1);
  // }
}

.sjfw {
  margin-right: 10px;

  // /deep/.el-input__suffix {
  //   top: 6px !important;
  // }

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  // /deep/.el-input__inner {

  //   width: 200px;
  //   height: 30px;
  //   line-height: 17px;
  //   color: rgba(16, 16, 16, 1);
  //   font-size: 12px;
  //   border: 1px solid rgba(187, 187, 187, 1);
  // }
}



.el-button {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
