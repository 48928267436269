<template>
  <div class="w-100 h-100">
    <div>
      <!-- //充值 -->
      <recharge v-show="finashow == '5'" @Vstory="Vstory"></recharge>
      <!-- //收支明细 -->
      <dencome v-show="finashow == '6'" :hogefinanna="hogefinanna"></dencome>
      <!-- //费用账单 -->
      <expeate v-show="finashow == '7'"></expeate>
    </div>
  </div>
</template>

<script>
import recharge from './components/recharge.vue'
import dencome from './components/dencome.vue'
import expeate from './components/expeate.vue'
export default {
  components: {
    recharge,
    dencome,
    expeate,
  },
  props: {
    finashow: {
      type: String,
      default: "",
    },
    hogefinanna: String
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
    Vstory(data,index) {
      this.$emit('record',data,index)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
