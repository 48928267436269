<template>
  <div class="w-100 h-100">

    <div class="recar">
      <div class="reacrmain">
      </div>
    </div>
    <div class="puricate">
      <div class="dawer">
        <div>
          <div>
            <div class="flex al-start">
              <span class="cho">源站配置</span>
            </div>
            <div>
              <el-form :model="sourtionForm" :rules="souForules" class="eForm" ref="sourtionForm">
                <el-form-item label="源站：" prop="name" class="yuanz">
                  <div class="">
                    <el-button type="primary" class="Fbtn" @click="Addsou" v-if="!examine">添加</el-button>
                    <small class="mg-lt-10">支持IP或域名，最多可添加60个</small>
                    <el-table :data="origin" style="width: 100%">
                      <el-table-column label="序号" width="60" type="index">

                      </el-table-column>
                      <el-table-column prop="origin" label="源站" width="600">
                      </el-table-column>
                      <el-table-column prop="role" label="层级">
                      </el-table-column>
                      <el-table-column prop="address" label="操作">
                        <template slot-scope="scope">
                          <div>
                            <span class="color-7D" @click="SourFication(scope.$index)" v-if="!examine">修改</span>
                            <small class="mg-lt-10 color-7D" @click="removeSour(scope.$index)" v-if="!examine">删除</small>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div>

                  </div>
                </el-form-item>
                <div class="flex al-start">
                  <span class="cho">回源协议</span>
                </div>
                <el-form-item label="回源协议：" prop="resource" class="wte-nrap">
                  <el-radio-group v-model="sourtionForm.resource">
                    <el-radio label="1" :disabled=examine>HTTP</el-radio>
                    <el-radio label="2" :disabled=examine>HTTPS</el-radio>
                    <el-radio label="3" :disabled=examine>跟随</el-radio>
                  </el-radio-group>
                  <p>目前仅HTTP协议回源支持自定义端口，HTTPS协议回源使用443端口，跟随请求协议回源将根据请求指定的协议回源到您源站的80或443端口</p>
                </el-form-item>
                <el-form-item label="源站端口：" class="port wte-nrap" prop="port1">
                  <div class="flex">
                    <div class="flex">
                      <span>http</span>
                      <el-input v-model="sourtionForm.port1" class="htinput" :disabled=examine></el-input>
                    </div>
                    <div class="flex hps">
                      <span>https</span>
                      <el-input v-model="sourtionForm.port2" class="htinput" disabled></el-input>
                    </div>
                  </div>

                </el-form-item>
                <el-form-item label="回源HOST：" class="port wte-nrap" prop="req_host">
                  <div class="">
                    <el-input v-model="sourtionForm.req_host" @change="reqhostChange" :disabled=examine></el-input>

                  </div>
                  <p class="mg-tp-10">回源host决定了回源请求访问到源站的哪个站点,默认值为加速域名。自定义配置时，请确保您的源站有配置相应的HOST</p>
                </el-form-item>
                <div class="flex al-start">
                  <span class="cho wte-nrap">回源HTTPS请求头</span>
                </div>
                <div class="souader">
                  <div class="flex addion">
                    <el-button type="primary" class="Fbtn" @click="Addstation" v-if="!examine">添加</el-button>
                    <p class="mg-lt-10">管理回源HTTP请求头，可支持新增、修改、删除，可携带特定的HTTP Heared参数信息给源站。</p>
                  </div>

                  <el-table :data="req_headers" style="width: 100%">
                    <el-table-column prop="key" label="参数">
                    </el-table-column>
                    <el-table-column prop="value" label="取值">
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <div>
                          <span class="color-7D" @click="modiFicati(scope.$index)" v-if="!examine">修改</span>
                          <small class="mg-lt-10 color-7D" @click="remove(scope.$index)" v-if="!examine">删除</small>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div>

                </div>
              </el-form>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //新增源站 -->
    <div class="nrcation">
      <el-dialog :visible.sync="souVisible">
        <div slot="title">
          <span>{{ soutitle }}源站</span>
        </div>
        <el-divider></el-divider>
        <div>
          <el-form :model="sourtionForm" :rules="souForules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item label="源站" prop="origin">
              <el-input v-model="sourtionForm.origin"></el-input>
            </el-form-item>
            <el-form-item label="层级" prop="role">
              <el-select v-model="sourtionForm.role" placeholder="请选择层级">
                <el-option label="主" value="master"></el-option>
                <el-option label="备" value="slave"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-divider></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="souVisible = false" class="cancel">取 消</el-button>
          <el-button type="primary" @click="newSotion" class="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- //新增HTTP -->
    <div class="nrcation">
      <el-dialog :visible.sync="aptVisible">
        <div slot="title">
          <span>{{ modiFtitle }}HTTP回源请求头规则</span>
        </div>
        <el-divider></el-divider>
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item label="参数名">
              <el-input v-model="ruleForm.key"></el-input>
            </el-form-item>
            <el-form-item label="取值">
              <el-input v-model="ruleForm.value"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-divider></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="aptVisible = false" class="cancel">取 消</el-button>
          <el-button type="primary" @click="addhttp" class="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    msg: String,
    dovervilist: Object,
    examine: Boolean
  },
  data() {
    return {
      value: '',
      souVisible: false,
      aptVisible: false,

      cudex: 0,
      cudexsou: 0,

      sourtionForm: {
        origin: "",
        role: "",
        port1: 80,
        port2: 443,
        resource: "1",
        req_host: "",
      },
      souForules: {
        req_host: [{
          required: true, message: '请输入回源host', trigger: 'change'
        }],
        origin: [
          { required: true, message: '请输入源站', trigger: 'change' },
        ],
        role: [
          { required: true, message: '请选择层级', trigger: 'change' },
        ],
        resource: [
          { required: true, message: '请选择回源协议', trigger: 'change' },
        ],
        port1: [
          { required: true, message: '请选择填写源站端口', trigger: 'change' },
        ]
      },

      ruleForm: {
        key: "",
        value: "",
        name: '',
        region: 'CDN加速',
      },
      typeval: "全部类型",
      status: "所有状态",
      purwer: false,
      modiFtitle: "",
      soutitle: "",
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      },
      origin: [
      ],
      req_headers: [],
      modid: "",
      userInfo: {},
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getMemberDomainDetail()
  },
  methods: {

    Addsou() {
      this.souVisible = true
      this.soutitle = '新增'
      this.sourtionForm.origin = ""
      this.sourtionForm.role = ""
    },
    SourFication(index) {
      this.cudexsou = index
      this.soutitle = '修改'
      this.sourtionForm.origin = this.origin[this.cudexsou].origin
      this.sourtionForm.role = this.origin[this.cudexsou].role
      this.souVisible = true
    },
    //添加HTTP请求头弹框
    Addstation() {
      this.modiFtitle = '添加'
      this.aptVisible = true
      this.modid = ""
      this.ruleForm.key = ""
      this.ruleForm.value = ""
    },
    reqhostChange() {
      this.increUpdateMemberDomain()
    },
    //新增源站
    newSotion() {
      this.$refs['sourtionForm'].validate((valid) => {
        if (valid) {
          if (this.soutitle == '修改') {
            this.origin[this.cudexsou].origin = this.sourtionForm.origin
            this.origin[this.cudexsou].role = this.sourtionForm.role
            this.souVisible = false
            // this.increUpdateMemberDomain()
          } else if (this.soutitle == '新增') {
            this.origin.push({
              origin: this.sourtionForm.origin,
              role: this.sourtionForm.role,
              weight: 10,
            })
            this.souVisible = false
            // this.increUpdateMemberDomain()
          }

        } else {
          this.$message({
            type: "waring",
            message: '继续填写完整'
          })
        }
      })

    },
    //删除HTTPS请求头
    remove(index) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.req_headers.splice(index, 1)
        // this.increUpdateMemberDomain()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

      // this.increUpdateMemberDomain()
    },
    removeSour(index) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.origin.splice(index, 1)
        // this.increUpdateMemberDomain()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    //修改HTTPS请求头
    modiFicati(index) {
      this.cudex = index
      this.modid = index
      this.modiFtitle = '修改'
      this.ruleForm.key = this.req_headers[this.cudex].key
      this.ruleForm.value = this.req_headers[this.cudex].value
      this.aptVisible = true
    },
    //新增或者修改确认
    addhttp() {
      if (this.modiFtitle == '修改') {
        this.req_headers[this.cudex].key = this.ruleForm.key
        this.req_headers[this.cudex].value = this.ruleForm.value
        this.aptVisible = false
      } else {
        this.req_headers.push({
          key: this.ruleForm.key,
          value: this.ruleForm.value,
        })
        this.aptVisible = false
      }
    },
    getData() {
      let form = {
        req_headers: this.req_headers,
        domain: this.dovervilist.domain,
        origin: this.origin,
        req_host: this.sourtionForm.req_host,
        origin_protocol:  this.sourtionForm.resource == "2" ? "https" : "http"
      }
      return form
    },
    increUpdateMemberDomain() {
      this.aptVisible = false
      // this.$apis.increUpdateMemberDomain(JSON.stringify({
      //   req_headers: this.req_headers,
      //   domain: this.dovervilist.domain,
      //   origin: this.origin,
      //   req_host: this.sourtionForm.req_host
      // })).then((res) => {
      //   this.$message({
      //     type: "success",
      //     message: res.msg
      //   })


      // })
    },
    getMemberDomainDetail() {
      this.$apis.getMemberDomainDetail({
        domain: this.dovervilist.domain,
        mid: this.userInfo.id
      }).then((res) => {
        this.origin = res.data.origin
        this.req_headers = res.data.req_headers
        this.sourtionForm.req_host = res.data.req_host
        this.sourtionForm.resource = res.data.https_basic.origin_protocol == "https" ? "2" : "1"
        this.sourtionForm.port1 = res.data.origin[0].protocol == 'http' ? res.data.origin[0].port : this.sourtionForm.port1
        this.sourtionForm.port2 = res.data.origin[0].protocol == 'https' ? res.data.origin[0].port : this.sourtionForm.port2
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.nrcation {
  .el-dialog__header {
    span {
      font-size: 15px;
    }
  }

  .cancel {
    background-color: #6C757D;
    color: #fff;
    border: none;
  }

  .confirm {
    background-color: #007BFF;
    color: #fff;
    border: none;
  }

  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  /deep/.el-form {
    padding: 0 20px;
  }

  /deep/.el-form-item__content {
    margin-left: 60px;
  }

  /deep/.el-form-item__label {
    text-align: left;
  }

  /deep/.el-input__inner {
    width: 100%;
  }

  /deep/.el-select__caret {
    line-height: 50px;
  }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-divider {
    margin-top: 0;
  }
}

/deep/.eForm {
  margin-top: 20px;

  .souader {
    margin-top: 30px;

    .addion {
      margin-bottom: 10px;
    }

    .Fbtn {
      background-color: #007BFF;
      padding: 9px 18px;
      font-size: 18px;
    }
  }

  .el-table th.el-table__cell {
    background-color: #F7F7F7 !important;
    padding: 0 !important;


  }

  .Fbtn {
    background-color: rgba(0, 123, 255, 1);
    color: rgba(255, 255, 255, 1);
    padding: 5px 10px;
    border: none;
  }

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }



  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item__label {
    text-align: left;
    width: 90px !important;
  }

  .el-input__icon {
    line-height: 50px;
  }




  p {
    line-height: 10px !important;
  }

  .el-input__inner {
    width: 300px;
  }

  .el-form-item {}
}

.puricate {













  .cho {
    width: 100px;
    padding: 0 15px 0 0;
    font-size: 15Px;
    color: #666666;
    font-weight: bold;
  }




}
</style>
