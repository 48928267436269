<template>
  <div class="w-100 h-100">

    <div class="recar">
      <div class="reacrmain">
      </div>
    </div>
    <div class="puricate">
      <div class="dawer">
        <div>
          <div>
            <div class="flex al-start">
              <span class="cho">Https配置</span>
            </div>
            <div>
              <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="eForm">
                <el-form-item label="https开关:" class="yuanz">
                  <div class="flex">
                    <small class="color-47 ">停用</small>
                    <el-switch @change="httpssChange" v-model="httpsswchit" active-color="#1684FC"
                      inactive-color="#909399" :disabled="examine">
                    </el-switch>
                    <small class="color-7F">启用</small>
                  </div>

                </el-form-item>
                <div v-if="httpsswchit">
                  <div class="flex al-start">
                    <span class="cho">https证书上传</span>
                  </div>
                  <el-form-item label="证书备注名：" class=" wte-nrap forump" prop="certname">
                    <div class="flex-column al-baseline">
                      <el-select v-model="ruleForm.certname" placeholder="请选择" :disabled="examine">
                        <el-option v-for="item in options" :key="item.name" :label="item.name" :value="item.name">
                        </el-option>
                      </el-select>
                      <p class=" mg-tp-10 ">可更换证书[请留意证书有效期]。</p>
                    </div>


                  </el-form-item>
                  <el-form-item label="Http2开关:" class="yuanz forump">
                    <div class="flex">
                      <small class="color-47 ">停用</small>
                      <el-switch v-model="ruleForm.use_http2" active-color="#1684FC" inactive-color="#909399"
                        :disabled="examine">

                      </el-switch>
                      <small class="color-7F">启用</small>
                    </div>

                  </el-form-item>
                  <div class="flex al-start">
                    <span class="cho">ocsP stapling</span>
                  </div>
                  <el-form-item label="ocsP Stapling开关: " class="yuanz forump">
                    <div class="flex">
                      <small class="color-47 ">停用</small>
                      <el-switch v-model="ruleForm.ocspstapling" active-color="#1684FC" inactive-color="#909399"
                        :disabled="examine">

                      </el-switch>
                      <small class="color-7F">启用</small>
                    </div>

                  </el-form-item>
                </div>

                <div>
                  <div class="flex al-start">
                    <span class="cho">强制跳转</span>
                  </div>
                  <el-form-item label="强制跳转:" class="forump">
                    <div class="flex">
                      <small class="color-47 ">停用</small>
                      <el-switch v-model="Forcjumpswitch" @change="ForswitChange" active-color="#1684FC"
                        inactive-color="#909399" :disabled="examine">

                      </el-switch>
                      <small class="color-7F">启用</small>
                    </div>

                  </el-form-item>
                  <div v-if="Forcjumpswitch">
                    <el-form-item label="跳转类型:" class="forump">
                      <el-tabs v-model="ruleForm.Jumptype" type="card" class="hedtabs">
                        <el-tab-pane label="Http->Https" name="1" :disabled="examine" v-if="httpsswchit"></el-tab-pane>
                        <el-tab-pane label="Https->Http" name="2" :disabled="examine"></el-tab-pane>

                      </el-tabs>

                    </el-form-item>
                    <el-form-item label="跳转方式:" class="forump">
                      <el-radio-group v-model="ruleForm.force_status">
                        <el-radio label="302" :disabled="examine">302跳转</el-radio>
                        <el-radio label="301" :disabled="examine">301跳转</el-radio>
                      </el-radio-group>


                    </el-form-item>
                  </div>

                </div>


                <div>
                </div>
                <!-- <div class="Btn" @click="increUpdateMemberDomain('ruleForm')">
                  <el-button type="primary" class="acknowle" v-if="!examine">确认添加</el-button>
                </div> -->
              </el-form>
              <div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  props: {
    msg: String,
    dovervilist: Object,
    examine: Boolean
  },
  data() {
    return {
      value: '',
      vaswchit: true,
      httpsswchit: false,
      type: "",
      options: [],
      Forcjumpswitch: false,
      ruleForm: {
        certname: "",
        httpsstatu: 'off',
        use_http2: 0,
        ocspstapling: false,
        https_force: false,
        http_force: false,
        force_status: "302",
        Jumptype: "1",
        Forcjumpswitch: false,
      },
      https_basic: {},
      rules: {
        certname: [
          { required: true, message: '请输入证书备用名', trigger: 'change' },
        ],
      },
      activeName: "1",
      userInfo: {}
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getCdnPackageList()
    this.getMemberDomainDetail()
    this.ForswitChange()
  },
  methods: {
    //获取详情
    getMemberDomainDetail() {
      this.$apis.getMemberDomainDetail({
        domain: this.dovervilist.domain,
        mid: this.userInfo.id
      }).then((res) => {
        this.httpsswchit = res.data.https_status == 'on' ? true : false
        this.ruleForm.httpsstatu = res.data.https_status
        this.ruleForm.use_http2 = res.data.basic_conf.use_http2 == 1 ? true : false
        this.ruleForm.ocspstapling = res.data.ssl_stapling == 'on' ? true : false
        this.ruleForm.force_status = res.data.https_basic.force_status
        this.ruleForm.Jumptype = res.data.https_basic.http_force == 'on' ? '2' : '1'
        this.ruleForm.Jumptype = res.data.https_basic.https_force == 'on' ? '1' : '2'
        this.ruleForm.certname = res.data.cert_name
        this.Forcjumpswitch = res.data.https_basic.http_force == 'on' ? true : res.data.https_basic.https_force == 'on' ? true : false
       
      })
    },
    ForswitChange() {
      if (this.Forcjumpswitch) {
        if (this.ruleForm.Jumptype == 1) {
          this.ruleForm.https_force = 'on'
          this.ruleForm.http_force = 'off'
        } else {
          this.ruleForm.https_force = 'off'
          this.ruleForm.http_force = 'on'
        }
      } else {
        this.ruleForm.https_force = 'off'
        this.ruleForm.http_force = 'off'
      }
    },
    getCdnPackageList() {
      this.$apis.getCdnPackageList({
        mid: this.userInfo.id
      }).then((res) => {
        this.options = res.data
      })
    },
    getData() {
      if (this.httpsswchit) {
        if (this.ruleForm.certname) {
          return this.ruleForm
        } else {
          this.$message({
            type: "error",
            message: "请选择证书备用名"
          })
          return false

        }
      }
      else {
        if (!this.Forcjumpswitch) {
          this.ruleForm.http_force = 'off'
          this.ruleForm.https_force = 'off'
          this.ruleForm.force_status = ""
        }
        this.ruleForm.certname = ""
        return this.ruleForm
      }


    },
    increUpdateMemberDomain(formName) {

      // if (this.httpsswchit) {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       this.$emit('dansmission',)
      //       this.$apis.increUpdateMemberDomain({
      //         domain: this.dovervilist.domain,
      //         https_status: this.ruleForm.httpsstatu,
      //         cert_name: this.ruleForm.certname,
      //         basic_conf: {
      //           use_http2: this.ruleForm.use_http2 ? '1' : '0'
      //         },
      //         ssl_stapling: this.ruleForm.ocspstapling ? 'on' : 'off',
      //         https_basic: {
      //           https_force: this.ruleForm.https_force,
      //           http_force: this.ruleForm.http_force,
      //           force_status: this.ruleForm.force_status,
      //           origin_protocol: this.ruleForm.Jumptype == 1 ? 'https' : 'http'
      //         }

      //       }).then((res) => {
      //         if (res.code == 0) {
      //           this.$message({
      //             type: "success",
      //             message: "保存成功"
      //           })
      //         } else {
      //           this.$message({
      //             type: "error",
      //             message: res.msg
      //           })
      //         }
      //       })
      //     } else {
      //       this.$message({
      //         type: "error",
      //         message: "请填写证书备注名"
      //       })
      //     }
      //   });
      // } else {
      //   this.$apis.increUpdateMemberDomain({
      //     domain: this.dovervilist.domain,
      //     https_status: this.ruleForm.httpsstatu,
      //     cert_name: this.ruleForm.certname,
      //     basic_conf: {
      //       use_http2: this.ruleForm.use_http2 ? '1' : '0'
      //     },
      //     ssl_stapling: this.ruleForm.ocspstapling ? 'on' : 'off',
      //     https_basic: {
      //       https_force: this.ruleForm.https_force,
      //       http_force: this.ruleForm.http_force,
      //       force_status: this.ruleForm.force_status,
      //       origin_protocol: this.ruleForm.Jumptype == 1 ? 'https' : 'http'
      //     }

      //   }).then((res) => {
      //     if (res.code == 0) {
      //       this.$message({
      //         type: "success",
      //         message: "保存成功"
      //       })
      //     } else {
      //       this.$message({
      //         type: "error",
      //         message: res.msg
      //       })
      //     }
      //   })
      // }


    },
    httpssChange() {
      this.ruleForm.httpsstatu = this.httpsswchit ? 'on' : "off"
    },


    handleChange(value) {
      if (this.ruleForm.Jumptype == 1 && this.ruleForm.Forcjumpswitch) {
        this.ruleForm.https_force = 'on'
        this.ruleForm.http_force = 'off'
      } else {
        this.ruleForm.https_force = 'off'
        this.ruleForm.http_force = 'on'
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Btn {
  text-align: right;
}

.nrcation {
  .el-dialog__header {
    span {
      font-size: 15px;
    }
  }

  .cancel {
    background-color: #6C757D;
    color: #fff;
    border: none;
  }

  .confirm {
    background-color: #007BFF;
    color: #fff;
    border: none;
  }

  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  /deep/.el-form {
    padding: 0 20px;
  }

  /deep/.el-form-item__content {
    margin-left: 50px;
  }

  /deep/.el-form-item__label {
    text-align: left;
  }

  /deep/.el-input__inner {
    width: 100%;
  }

  // /deep/.el-select__caret {
  //   line-height: 50px;
  // }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  /deep/.el-divider {
    margin-top: 0;
  }
}

/deep/.eForm {
  margin-top: 20px;

  .forump {
    margin-top: 20px;
  }

  .el-switch__core {
    width: 40px !important;
    height: 18px !important;
    margin: 0 10px;
  }

  .souader {
    margin-top: 30px;

    .addion {
      margin-bottom: 10px;
    }

    .Fbtn {
      background-color: #007BFF;
      padding: 9px 18px;
      font-size: 18px;
    }
  }

  .el-table th.el-table__cell {
    background-color: #F7F7F7 !important;
    padding: 0 !important;

    .cell {
      font-weight: bold
    }
  }








  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item__label {
    text-align: left;
    width: auto !important;
  }

  .el-form-item {
    margin-left: 15px;
  }





  p {
    line-height: 10px !important;
  }



}

.puricate {
  .cho {
    // width: 100px;
    padding: 0 15px 0 0;
    font-size: 15Px;
    color: #666666;
    font-weight: bold;
  }

}

/deep/.hedtabs {


  .is-active {
    background-color: #6C757D;
    color: #D9E2D3 !important;
  }

  .el-tabs__header {
    border: none;
    margin: 0;
  }

  .el-tabs__item {
    color: #ABABB0;
    border-color: #AFB3B6;
    // height: 27px;
    // line-height: 27px;
  }

  .el-tabs__nav {
    border-color: #AFB3B6;
  }
}
</style>
