<template>
  <div class="w-100 h-100">

    <div class="recar">
      <div class="reacrmain">
      </div>
    </div>
    <div class="puricate">
      <div class="dawer">
        <div>
          <div>
            <div class="flex al-start">
              <span class="cho wte-nrap">Referer防盗链</span>
            </div>
            <div>
              <el-form :model="blackReferer" :rules="rules" class="eForm">
                <el-form-item label="Referer防盗链:" class="yuanz wte-nrap">
                  <div>
                    <el-switch v-model="blackReferer.secuswich" active-color="#1684FC" inactive-color="#909399"
                      :disabled="examine" @change="secuChange">
                    </el-switch>
                  </div>

                </el-form-item>
                <div class="" v-if="blackReferer.secuswich">
                  <el-form-item label="是否允许空referer访问:" class="yuanz wte-nrap">
                    <div>
                      <el-switch v-model="blackReferer.allowEmpty" active-color="#1684FC" inactive-color="#909399"
                        :disabled="examine">
                      </el-switch>
                    </div>

                  </el-form-item>
                  <el-form-item label="类型" class="yuanz wte-nrap">
                    <div>
                      <el-radio-group v-model="blackReferer.Blite">
                        <el-radio label="1" :disabled="examine">黑名单</el-radio>
                        <el-radio label="2" :disabled="examine">白名单</el-radio>
                      </el-radio-group>
                      <el-input type="textarea" :rows="4"
                        placeholder="最多400个,使用换行符分隔,不可重复,支持通配，支持 IP ，支持端口，如:*,test,com 、test,com:80 、127.0.0.1:80、localhost"
                        v-model="blackReferer.allow" :disabled="examine">
                      </el-input>
                    </div>

                  </el-form-item>
                  <!-- <div class="text-rt">
                    <el-button type="primary" @click="blackReClick" class="confirm" v-if="!examine">确 定</el-button>
                  </div> -->
                </div>

                <div class="flex al-start">
                  <span class="cho wte-nrap">IP黑/白名单</span>
                </div>
                <el-form-item label="IP黑/白名单:" class="forump wte-nrap">
                  <div>
                    <el-switch v-model="Iwitch" active-color="#1684FC" inactive-color="#909399" :disabled="examine"
                      @change="IwitChange">

                    </el-switch>
                  </div>

                </el-form-item>
                <div v-show="Iwitch">
                  <el-form-item label="类型" class="yuanz wte-nrap">
                    <div>
                      <el-radio-group v-model="IpBlite">
                        <el-radio label="1">黑名单</el-radio>
                        <el-radio label="2">白名单</el-radio>
                      </el-radio-group>
                      <el-input type="textarea" :rows="4" placeholder="多个使用逗号分隔，不可重复，支持网段添加，如: 127.0.0.1/24"
                        v-model="Ipallow" :disabled="examine">
                      </el-input>
                    </div>

                  </el-form-item>
                  <!-- <div class="text-rt">
                    <el-button type="primary" @click="IPClick" class="confirm" v-if="!examine">确 定</el-button>
                  </div> -->
                </div>

              </el-form>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  props: {
    msg: String,
    dovervilist: Object,
    examine: Boolean
  },
  data() {
    return {
      Ipallow: "",
      IpBlite: "1",
      Iwitch: false,
      blackReferer: {
        allowEmpty: false,
        secuswich: false,
        Blite: "1",
        allow: "",

      },
      ruleForm: {

      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      },
      userInfo: {},
      white_referer: {},
      black_referer: {},
      ip_black_list: "",
      ip_white_list: "",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getMemberDomainDetail()
  },
  methods: {
    secuChange() {

    },
    IwitChange() {


    },
    getData() {
      if (!this.blackReferer.secuswich) {
        this.white_referer = {}
        this.black_referer = {}
      }
      if (!this.Iwitch) {
        this.ip_black_list = ""
        this.ip_white_list = ""
      }
      if (this.blackReferer.secuswich) {
        if (this.blackReferer.Blite == "1") {
          this.white_referer = {}
          this.black_referer = {
            allow_empty: this.blackReferer.allowEmpty ? 'on' : 'off',
            allow_list: this.blackReferer.allow.split('\n')
          }
        } else if (this.blackReferer.Blite == "2") {
          this.black_referer = {}
          this.white_referer = {
            allow_empty: this.blackReferer.allowEmpty ? 'on' : 'off',
            allow_list: this.blackReferer.allow.split('\n')
          }
        }
      }
      if (this.Iwitch) {
        if (this.IpBlite == "1") {
          this.ip_black_list = this.Ipallow
          this.ip_white_list = ""
        } else if (this.IpBlite == "2") {
          this.ip_white_list = this.Ipallow
          this.ip_black_list = ''
        }
      }

      let form = {
        ip_black_list: this.ip_black_list,
        ip_white_list: this.ip_white_list,
        black_referer: this.black_referer,
        white_referer: this.white_referer
      }
      return form
    },
    IPClick() {

    },
    getMemberDomainDetail() {
      this.$apis.getMemberDomainDetail({
        domain: this.dovervilist.domain,
        mid: this.userInfo.id
      }).then((res) => {
        if (res.data.ip_black_list) {
          this.Ipallow = res.data.ip_black_list
          this.Iwitch = true
        } else if (res.data.ip_white_list) {
          this.Ipallow = res.data.ip_white_list
          this.Iwitch = true
        }
        if (res.data.white_referer.allow_empty) {
          this.blackReferer.allowEmpty = res.data.white_referer.allow_empty == 'on' ? true : false
          this.blackReferer.allow = res.data.white_referer.allow_list.join('/br')
          this.blackReferer.Blite = "2"
          this.blackReferer.secuswich = true
        } else if (res.data.black_referer.allow_empty) {
          this.blackReferer.allowEmpty = res.data.black_referer.allow_empty == 'on' ? true : false
          this.blackReferer.allow = res.data.black_referer.allow_list.join('/br')
          this.blackReferer.Blite = "1"
          this.blackReferer.secuswich = true
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.eForm {
  margin-top: 20px;

  .forump {
    margin-top: 20px;
  }

  .el-switch__core {
    width: 40px !important;
    height: 18px !important;
    // margin: 0 40px;
  }

  .el-form-item__label {
    text-align: left;
    width: auto !important;
  }

  .el-table th.el-table__cell {
    background-color: #F7F7F7 !important;
    padding: 0 !important;

    .cell {
      font-weight: bold
    }
  }






}

.puricate {
  .cho {
    width: 100px;
    padding: 0 15px 0 0;
    font-size: 15Px;
    color: #666666;
    font-weight: bold;
  }




}
</style>
