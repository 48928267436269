<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="flex just-bet cz">
        <h3 class="flex">
          <img
            src="@/assets/img/yumgliwxz.png"
            alt=""
            srcset=""
            class="hedimg"
          />
          <span> {{ dovervilist.domain }}</span>
        </h3>
      </div>

      <div class="reacrmain">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本设置" name="first">
            <batup :dovervilist="dovervilist" :examine="examine"></batup>
          </el-tab-pane>
          <el-tab-pane label="回源设置" name="second">
            <bactting
              :dovervilist="dovervilist"
              :examine="examine"
              ref="bactting"
            ></bactting>
          </el-tab-pane>
          <el-tab-pane label="HTTPS配置" name="third">
            <Hconf
              :dovervilist="dovervilist"
              :examine="examine"
              ref="Hconf"
            ></Hconf>
          </el-tab-pane>
          <el-tab-pane label="缓存配置" name="hc">
            <nfigu
              :dovervilist="dovervilist"
              :examine="examine"
              ref="nfigu"
            ></nfigu>
          </el-tab-pane>
          <el-tab-pane label="访问控制" name="fw">
            <acontrol
              :dovervilist="dovervilist"
              :examine="examine"
              ref="acontrol"
            ></acontrol>
          </el-tab-pane>
          <el-tab-pane label="文件处理" name="wj">
            <filendling
              :dovervilist="dovervilist"
              :examine="examine"
              ref="filendling"
            ></filendling>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="text-rt mg-tp-30">
      <el-button type="primary" class="confirm" @click="Comave"
        >提交保存</el-button
      >
    </div>
    <!-- //购买证书 -->
    <div class="puricate">
      <el-drawer title="添加域名" :visible.sync="purwer">
        <div class="dawer">
          <div>
            <div class="flex al-start">
              <span class="cho">基本信息</span>
            </div>
            <div>
              <el-form :model="ruleForm" :rules="rules" class="eForm">
                <el-form-item label="加速域名" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                  <p>加速域名为需要使用加速服务的域名，支持泛域名</p>
                </el-form-item>
                <el-form-item label="加速类型" prop="region">
                  <el-select
                    v-model="ruleForm.region"
                    placeholder="请选择活动区域"
                  >
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                  <p>加速域名为需要使用加速服务的域名，支持泛域名</p>
                </el-form-item>
                <el-form-item label="加速范围">
                  <el-radio-group v-model="ruleForm.resource">
                    <el-radio label="中国内地"></el-radio>
                    <el-radio label="全球(不含中国内地)" disabled></el-radio>
                    <el-radio label="全球" disabled></el-radio>
                  </el-radio-group>
                  <p>
                    您当前尚未开通CDN加速境外服务，请先前往开通<a>CDN加速-全球(不含中国内地)</a>服务
                  </p>
                  <p>
                    加速范围包含中国内地时，加速域名请先完成在中国大陆的ICP备案，同时完成公安网备案
                  </p>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div>
            <div class="flex al-start">
              <span class="cho">源站设置</span>
            </div>
            <div>
              <el-form :model="ruleForm" :rules="rules" class="eForm">
                <el-form-item label="源站：" prop="name" class="yuanz">
                  <div class="flex">
                    <el-input v-model="ruleForm.name"></el-input>
                    <span class="wte-nrap">层级</span>
                    <el-select
                      v-model="ruleForm.region"
                      placeholder="请选择活动区域"
                      class="selctcj"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </div>
                  <div>
                    <span style="color: #95a4d8">+新增源站</span>
                    <p>支持IP或域名，最多可添加60个</p>
                  </div>
                </el-form-item>
                <el-form-item label="回源协议：">
                  <span>暂无备选项</span>
                </el-form-item>
                <el-form-item label="源站端口：" class="port">
                  <div class="flex">
                    <div class="flex">
                      <span>http</span>
                      <el-input
                        v-model="ruleForm.http"
                        class="htinput"
                      ></el-input>
                    </div>
                    <div class="flex hps">
                      <span>https</span>
                      <el-input
                        v-model="ruleForm.https"
                        class="htinput"
                        disabled
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="base flex"></div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import batup from "./batup.vue"; //基本设置
import bactting from "./bactting.vue"; //回源配置
import Hconf from "./Hconf.vue"; //HTTPS配置
import nfigu from "./nfigu.vue"; //缓存设置
import acontrol from "./acontrol.vue"; //访问控制
import filendling from "./filendling.vue"; //文件处理
export default {
  props: {
    msg: String,
    dovervilist: Object,
    examine: Boolean,
  },
  components: {
    batup,
    bactting,
    Hconf,
    nfigu,
    acontrol,
    filendling,
  },
  data() {
    return {
      value: "",
      ruleForm: {
        name: "",
        region: "CDN加速",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        http: 80,
        https: 443,
      },
      rules: {},
      purwer: false,
      activeName: "first",
      bactData: {},
      HconfData: {},
      nfiguData: {},
      acontrolData: {},
      filendlingData: {},
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    memberEdit() {
      this.$apis
        .memberEdit({
          isDefaultCache: this.nfiguData.vaswchit ? "1" : "0",
          id: this.userInfo.id,
        })
        .then((res) => {});
    },
    increUpdateMemberDomain() {
      if (!this.HconfData) {
        return;
      }
      if (this.nfiguData.filetype_ttl.length > 0) {
        this.nfiguData.filetype_ttl.map((el) => {
          el.ttl =
            el.timeType == 3
              ? parseInt(el.ttl)
              : el.timeType == 1
              ? parseInt(el.ttl * 60 * 60)
              : el.timeType == 0
              ? parseInt(el.ttl * (24 * 60 * 60))
              : el.timeType == 2
              ? parseInt(el.ttl * 60)
              : "";
        });
      }
      this.$confirm("确定要修改吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.memberEdit();
          this.$apis
            .increUpdateMemberDomain(
              JSON.stringify({
                req_headers: this.bactData.req_headers,
                domain: this.dovervilist.domain,
                origin: this.bactData.origin,
                weight: "",
                req_host: this.bactData.req_host,
                https_status: this.HconfData.httpsstatu,
                cert_name: this.HconfData.certname,
                basic_conf: {
                  use_http2: this.HconfData.use_http2 ? "1" : "0",
                },
                ssl_stapling: this.HconfData.ocspstapling ? "on" : "off",
                https_basic: {
                  https_force: this.HconfData.https_force,
                  http_force: this.HconfData.http_force,
                  force_status: this.HconfData.force_status
                    ? this.HconfData.force_status
                    : "302",
                  origin_protocol: this.bactData.origin_protocol,
                  //  this.HconfData.Jumptype == 1 ? 'https' : 'http'
                },
                filetype_ttl: this.nfiguData.filetype_ttl,
                resp_headers: this.nfiguData.resp_headers,
                ip_black_list: this.acontrolData.ip_black_list,
                ip_white_list: this.acontrolData.ip_white_list,
                black_referer: this.acontrolData.black_referer,
                white_referer: this.acontrolData.white_referer,
                cus_gzip: this.filendlingData.cus_gzip,
              })
            )
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    Comave() {
      this.bactData = this.$refs.bactting.getData();
      this.HconfData = this.$refs.Hconf.getData();
      this.nfiguData = this.$refs.nfigu.getData();
      this.acontrolData = this.$refs.acontrol.getData();
      this.filendlingData = this.$refs.filendling.getData();
      this.$nextTick(() => {
        this.increUpdateMemberDomain();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.eForm {
  margin-top: 20px;

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item__label {
    text-align: left;
    width: 80px;
  }

  a {
    color: #4249f1;
  }

  .el-radio-group {
    display: flex;
    align-items: center;
    margin-top: 13px;

    .el-radio__label {
      font-size: 12px;
    }

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    // margin-top: 5px;
  }

  .el-input__inner {
    width: 300px;
  }
}

.puricate {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22px;

      .unit {
        font-size: 14px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {
    padding: 15px 15px 0px;
  }

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16px;
    }

    span {
      font-size: 13px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15px;
    color: #333333;
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
    padding: 15px;
    margin-bottom: 20px;
  }

  /deep/.el-drawer__body {
    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15px;
      color: #666666;
      font-weight: bold;
    }

    .pitcon {
      border-color: #007bff !important;
      background: url("../../../../../assets/img/xzl.png") no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22px;

          label {
            font-size: 14px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13px;
        }
      }

      .left {
        p {
          font-size: 20px;
          color: #666666;
        }
      }
    }
  }
}

.purcha {
  background-color: #4272d7;
  border: none;
  font-size: 12px !important;
}

.cz {
  padding: 0 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #333;
  }

  span {
    margin-left: 10px;
  }
}

.hedimg {
  width: 25px;
  height: 25px;
}

.recar {
  width: 100%;
  background-color: #fff;

  /deep/.el-tabs__item {
    color: #a5a5a5;
  }

  /deep/.is-active {
    color: #4e4e4e;
  }

  /deep/.el-tabs__active-bar {
    background-color: #717171;
    height: 2px;
  }

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

/deep/.el-table thead {
  color: #666666;
  font-weight: bold;
  font-size: 20px !important;
}

/deep/.el-table__body-wrapper {
  .tabthre {
    width: 15px;
    height: 15px;
  }

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

// /deep/.el-input__inner {

//   width: 200px;
//   height: 30px;
//   line-height: 17px;
//   color: rgba(16, 16, 16, 1);
//   font-size: 12px;
//   border: 1px solid rgba(187, 187, 187, 1);
// }
</style>
