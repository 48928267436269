<template>
  <div class="">
    <div>
      <el-input v-model="mobile" placeholder="手机号" class="sinput"></el-input>
      <el-input v-model="password" placeholder="密码" class="minput" :show-password="showpas">
        <div slot="suffix">
          <img src="@/assets/img/chak.png" alt="" srcset="" class="ck" @click="showpas = !showpas" v-if="showpas">
          <img src="@/assets/img/ckmm.png" alt="" srcset="" class="ck" @click="showpas = !showpas" v-else>
        </div>

      </el-input>
    </div>
    <div class="forget" @click="forgPassw">
      <span>忘记密码</span>
    </div>
    <el-button type="primary" @click="memberLogin">登录</el-button>
    <div class="sigp">
      <span style="color:#888888">没有账号？</span>
      <span style="color:#6C74F7;cursor:pointer;" @click="sighay">立即注册</span>
    </div>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      mobile: "",
      password: "",
      showpas: true,
    }
  },
  methods: {
    //忘记密码
    forgPassw() {
      this.$emit('forgPassw')
    },
    //登录
    memberLogin() {
      let regs = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;// 11位手机
      if (regs.test(this.mobile) && this.password) {
        this.$apis.memberLogin({
          mobile: this.mobile,
          password: this.password,
          type: '1'
        }).then((res) => {
         
          if (res.code == 0) {  
            localStorage.setItem('token', res.authToken)
            localStorage.setItem('userInfo', JSON.stringify(res.data))
         
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            this.$nextTick(()=>{
              this.$router.push('/homage')
            })
           
          } else {
            this.$message({
              message: res.msg,
              type: "error"
            })
          }

        })
      } else {
        this.$message({
          type: "error",
          message: "请输入正确的手机号或密码"
        })
      }


    },
    sighay() {
      this.$emit('sighay')
    },
  }
}
</script>
  
<style scoped lang="scss"> /deep/.el-input-group__append {
   width: 80px;
   height: 50px;
   line-height: 50px;
   border-radius: 0px 8px 8px 0px;
   background-color: rgba(245, 245, 245, 1);
   color: rgba(85, 85, 85, 1);
   font-size: 14px;
   text-align: center;
   border: 1px solid rgba(215, 215, 215, 1);
   position: absolute;
   top: 0;
   right: 0;
 }

 .sinput {
   margin-top: 36px;
 }

 .ck {
   width: 24px;
   height: 24px;
   margin-top: 13px;
 }

 .sigp {
   margin-top: 24px;

   span {
     font-size: 13px;
   }
 }

 .minput {
   margin-top: 22px;
   display: flex;

   /deep/.el-input__clear {
     display: none;
   }
 }

 /deep/.el-input__inner {
   width: 360px;
   height: 50px;
   line-height: 20px;
   border-radius: 8px;
   background-color: rgba(255, 255, 255, 1);
   border: 1px solid rgba(215, 215, 215, 1);
 }

 .forget {
   color: rgba(136, 136, 136, 1);
   font-size: 13px;
   text-align: right;
   margin: 16px 0 34px 0;
   cursor: pointer;
 }

 /deep/.el-button--primary {
   width: 360px;
   height: 56px;
   line-height: 23px;
   border-radius: 8px;
   background-color: rgba(108, 116, 247, 1);
   color: rgba(255, 255, 255, 1);
   font-size: 16px;
   text-align: center;
   font-family: Roboto;
   border: none;
 }
</style>
  