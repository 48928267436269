<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <h3 class="cz flex">
          <img src="@/assets/img/szmx.png" alt="" srcset="" class="hedimg">
          <span> 收支明细</span>

        </h3>
        <div>
          <div class="flex">
            <div class="sjfw">
              <span class="chars">时间范围</span>
              <el-select v-model="timeType" placeholder="请选择" @change="timeTypeChange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="jylx">
              <span class="chars">交易类型</span>
              <el-select v-model="style" placeholder="请选择" @change="styleChange">
                <el-option v-for="item in optionstype" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

          </div>
          <div class="colle">
            <!-- <span class="font-12">当前筛选的交易汇总：无记录</span> -->
            <span class="font-12">收入总额：{{ shouMoney }} </span>
            <span class="font-12">支出总额：{{ zhiMoney }}</span>
          </div>
          <div>
            <el-table :data="BalanceLog" style="width: 100%" empty-text="暂无符合条件的收支记录...">
              <el-table-column prop="createTime" label="交易时间		" width="180">
              </el-table-column>
              <el-table-column prop="style" label="交易类型" width="180" align="center">
                <template slot-scope="scope">
                  {{ scope.row.style == 0 ? '充值' : scope.row.style == 1 ? '下单抵扣' : scope.row.style == 2? '订单退款':'流量扣款' }}
                </template>
              </el-table-column>
              <el-table-column prop="title" label="描述" width="260" align="center">

              </el-table-column>
              <el-table-column label="入账（元）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.status == 0 ? scope.row.money : 0 }}
                </template>
              </el-table-column>
              <el-table-column label="支出（元）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.status == 1 ? scope.row.money : 0 }}
                </template>
              </el-table-column>
              <el-table-column label="详情-数量" align="center">
                <template slot-scope="scope">
                  {{ scope.row.orderTotal }}
                </template>

              </el-table-column>
              <el-table-column label="详情-单价（元）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.marketPrice }}
                </template>
              </el-table-column>
              <el-table-column label="可用余额（元）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.afteramount }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
          :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
          class="flex just-center mg-tp-23">
        </el-pagination>
      </div>
    </div>
    <p class="mg-tp-10">*只保留一年的记录</p>
  </div>
</template>

<script>
export default {

  props: {
    hogefinanna: String
  },
  data() {
    return {
      tableData: [],
      num: 1,
      imageUrl: "",
      style: "",
      timeType: "",
      optionstype: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "0",
          label: "充值"
        },
        {
          value: "1",
          label: "下单抵扣"
        },
        {
          value: "2",
          label: "订单退款"
        },
        {
          value: "3",
          label: "流量扣款"
        },
      ],
      currentpage: 0,
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "今天"
        },
        {
          value: "2",
          label: "昨天"
        },
        {
          value: "3",
          label: "近7天"
        },
        {
          value: "4",
          label: "近30天"
        },
        {
          value: "5",
          label: "本月"
        },
        {
          value: "6",
          label: "上月"
        },
      ],
      userInfo: {},
      offset: 0,
      limit: 10,
      total: 0,
      BalanceLog: [],
      shouMoney: "",
      zhiMoney: "",
    }
  },
  watch:{
    //同级跳转过来
    hogefinanna(newvalue) {
      this.style=newvalue
      this.getBalanceLog()
    },
  },
  mounted() {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    //首页过来筛选
    if (this.hogefinanna) {
    
      this.style = this.hogefinanna
      this.getBalanceLog()
      return
    }
    this.getBalanceLog()

  },
  methods: {
    getBalanceLog() {
      this.$apis.getBalanceLog({
        mid: this.userInfo.id,
        style: this.style,
        timeType: this.timeType,
        offset: this.offset,
        limit: this.limit
      }).then((res) => {
        this.total = res.data.total
        this.BalanceLog = res.data.rows
        this.shouMoney = res.shouMoney
        this.zhiMoney = res.zhiMoney
      })
    },
    handleCurrentChange(val) {
      this.currentpage = val
      this.offset = (val - 1) * this.limit
      this.getBalanceLog()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getBalanceLog()
    },
    styleChange() {
      this.getBalanceLog()
    },
    timeTypeChange() {
      this.getBalanceLog()
    },
      

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

.hedimg {
  width: 25px;
  height: 25px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__inner {
    width: 200px;
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }

}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__inner {

    width: 200px;
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
    border: 1px solid rgba(187, 187, 187, 1);
  }

}
</style>
