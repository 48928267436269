<template>
  <div class="w-100 h-100" id="mainContainer">
    <div class="hoge">
      <div class="flex just-bet al-baseline">
        <h3 class="cz flex">
          <img src="@/assets/img/fapgli.png" alt="" srcset="" class="hedimg" />
          <span> 融合 CDN</span>
        </h3>
        <el-tabs
          v-model="activeName"
          type="card"
          @tab-click="handleClick"
          class="hedtabs"
        >
          <el-tab-pane label="今日" name="1"></el-tab-pane>
          <el-tab-pane label="昨日" name="2"></el-tab-pane>
          <el-tab-pane label="近七日" name="3"></el-tab-pane>
          <el-tab-pane label="近三十日" name="4"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="warinp just-bet">
        <div class="caount">
          <div class="casou flex just-bet mg-bot-30 al-baseline">
            <div class="camain">
              <div class="">
                <h5>当前域名数量</h5>
              </div>
              <div class="amod">
                <span>{{ domainNum }}</span>
                <span class="uni">个</span>
              </div>
            </div>
            <div class="camain">
              <div class="">
                <h5>今日境内流量</h5>
              </div>
              <div class="amod">
                <span>{{ todayFlow }}</span>
                <span class="uni">{{
                  Number(todayFlowunnit) > 1000 && Number(todayFlowunnit)<1000000
                    ? "GB"
                    : Number(todayFlowunnit) > 1000000
                    ? "TB"
                    : "MB"
                }}</span>
              </div>
            </div>
            <div class="camain bandweak">
              <div class="">
                <h5>今日境内带宽峰值</h5>
              </div>
              <div class="amod">
                <span>{{ peakBandwidthData }}</span>
                <span class="uni">Mbps</span>
              </div>
            </div>
            <div class="camain">
              <div class="">
                <h5>今日境内请求数</h5>
              </div>
              <div class="amod">
                <span>{{ requestNum }}</span>
                <span class="uni">次</span>
              </div>
            </div>
            <div class="camain">
              <div class="">
                <h5>今日境外流量</h5>
              </div>
              <div class="amod">
                <span>{{ waiTodayFlow }}</span>
                <span class="uni">MB</span>
              </div>
            </div>
          </div>
          <div class="costren">
            <div class="costmain">
              <div class="flex just-bet">
                <div class="fyqs flex">
                  <span class="bt">用量趋势</span>
                  <!-- <el-select v-model="value" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select> -->
                </div>
                <div class="flex">
                  <el-tabs
                    v-model="acdomes"
                    type="card"
                    @tab-click="acdomesClick"
                    class="tretab"
                  >
                    <el-tab-pane label="中国境内" name="0"></el-tab-pane>
                    <el-tab-pane label="境外" name="1"></el-tab-pane>
                  </el-tabs>
                  <span class="mota curpoin" @click="oData">更多数据</span>
                </div>
              </div>

              <div class="nexp">
                <!-- <span> 你暂时还没产生费用~</span> -->
                <div class="echart" id="mychart"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="cotre">
          <div class="invoice mg-bot-30">
            <div class="invomain">
              <div class="">
                <span>资源包</span>
              </div>
              <div class="amod">
                <span>流量包{{ cdnNum }}个，剩{{ residueFlow }}G</span>
              </div>
              <div class="" @click="Purchase">
                <a class="czjl curpoin">购买资源包</a>
              </div>
            </div>
          </div>

          <div class="prodonsu">
            <div class="prodmain">
              <div class="">
                <span>用量 TOP 10</span>
              </div>
              <!-- //没有数据时候的 -->
              <!-- <div class="byxf flex just-center">
              <label>暂无数据...</label>
            </div> -->
              <div>
                <div
                  class="Usata mg-tp-23"
                  v-for="item in topDomain"
                  :key="item.id"
                >
                  <div class="mg-lt-21">
                    <span>{{ item.rank }}</span>
                    <label>{{ item.domain }}</label>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div slot="title">
        <span>余额预警</span>
      </div>
      <el-divider></el-divider>
      <div class="alert">
        <div slot="" class="alert-info">
          <span
            >开启后，当你的账号可用余额低于你设置的阈值时，将进行通知提醒。可以在
          </span>
          <a  @click="perJump(4)">消息订阅</a>
          中修改通知方式和联系人。
        </div>
        <div class="off">
          <span>开关</span>
          <el-checkbox v-model="checked">接收余额预警通知</el-checkbox>
        </div>
        <div class="whres">
          <span>预警阈值</span>
          <div class="flex-column al-start">
            <el-input-number
              v-model="num"
              @change="handleChange"
              :min="0.01"
              :max="9999999"
              label="描述文字"
              :controls="false"
            ></el-input-number>
            <label for="">范围：0.01 ~ 9999999，允许设置到小数点后两位。</label>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div slot="footer">
        <el-button type="primary" class="cancel" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="dialogVisible = false"
          >保存设置</el-button
        >
      </div>
    </el-dialog>
    <!-- //购买资源包 -->
    <div class="puffi">
      <el-drawer title="商品购买" :visible.sync="pufwer">
        <div class="dawer">
          <div class="flex al-start sp">
            <span class="cho">选择商品</span>
            <div class="list">
              <ul>
                <li
                  v-for="(item, index) in PackageList"
                  :key="item.id"
                  @click="pitcon(item, index)"
                >
                  <div :class="active == index ? 'pitcon commy' : 'commy'">
                    <div class="left">
                      <p>{{ item.resourcesName }}</p>
                    </div>
                    <div class="rgiht flex just-bet">
                      <div class="period">
                        <label
                          >有效期：自购买日起
                          <span class="f-bold">{{ item.validity }}个月</span
                          >内</label
                        >
                      </div>
                      <div class="flex al-baseline">
                        <span class="reaice"
                          ><label>￥</label>{{ item.marketPrice }}</span
                        >
                        <span class="repr"
                          ><label>￥</label>{{ item.productPrice }}</span
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <el-pagination
            @size-change="choSizeChange"
            @current-change="chohandleChange"
            :current-page="chotpage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sourlimit"
            layout=" sizes, prev, pager, next, jumper"
            :total="sourototal"
            class="flex just-center mg-tp-23"
          >
          </el-pagination>
          <div class="putity flex al-start">
            <span>购买数量</span>
            <el-input
              placeholder="请输入内容"
              v-model="purcqu"
              @change="purcquChange"
            >
              <template slot="append">个</template>
            </el-input>
          </div>
          <div class="explain">
            <label>说明</label>
            <span>
              划线价格不表示商品原价，它是表示如果不购买此商品，系统按量计费消耗同等资源预估产生的费用。
            </span>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="base flex">
          <div class="toces">
            <span>总价：</span>
          </div>
          <div class="Prls">
            <span><label class="unit">￥</label>{{ toprices }}</span>
          </div>
          <div class="butto">
            <el-button type="primary" class="cancel" @click="pufwer = false"
              >取消</el-button
            >
            <el-button type="primary" class="acknowle" @click="createOrder"
              >确认订单</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 流量包支付 -->
    <div class="trymentdialog">
      <el-dialog :visible.sync="dackVisible" width="80%">
        <div slot="title">
          <h5>核对和支付</h5>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="prme flex">
            <label>商品名称</label>
            <span>{{ OrderList.productName }}</span>
          </div>
          <div class="prme flex">
            <label>购买数量 </label>
            <span>{{ OrderList.total }} 个</span>
          </div>
          <div class="prme flex nepay">
            <label>需要支付 </label>
            <span class=""
              ><label class="unit">￥</label>{{ OrderList.payPrice }}</span
            >
          </div>
          <div class="prme flex">
            <label>支付方式 </label>
            <div class="maym">
              <ul class="flex">
                <li :class="suffioff ? 'active' : ''" @click="sitchinalance">
                  <span class="">{{ remaititle }} ￥{{ money }}</span>
                </li>
                <li :class="!suffioff ? 'active' : ''" @click="WeChatPay">
                  <span class="">微信支付</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="prme flex scpay al-baseline" v-show="!suffioff">
            <label>扫码支付 </label>
            <div class="flex-column al-baseline">
              <span class="">请使用微信客户端扫码二维码完成支付</span>
              <div id="qrcode" ref="qrcode" class="erwma mg-tp-10"></div>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-divider></el-divider>

          <el-button @click="dackVisible = false" class="cancel"
            >取 消</el-button
          >
          <el-button @click="confiyment" class="mg-rt-22" type="primary"
            >确认支付</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
import * as echarts from "echarts";
import QRCode from "qrcodejs2";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      dackVisible: false,
      dialogVisible: false,
      suffioff: false,
      xData: ["06-13", "03:00", "06:00", "09:00", "12:00", "15:00"], //横坐标
      remaititle: "",
      money: "",
      data2: [23, 24, 18, 25, 27, 28, 25, 25, 25, 25, 25, 25], //数据
      data: [1, 2, 11, 3, 10, 9, 8, 7, 7, 7, 8, 9], //数据
      checked: false,
      activeName: "1",
      acdomes: "0",
      pufwer: false,
      num: 1,
      //   options: [{
      //     value: '1',
      //     label: '流量'
      //   },
      //   {
      //     value: '2',
      //     label: '带宽'
      //   },{
      //     value: '3',
      //     label: '请求数'
      //   },
      // ],
      value: "流量",
      userInfo: {},
      domainNum: "",
      todayFlow: "",
      requestNum: "",
      souroffset: 0,
      sourlimit: 10,
      sourototal: 0,
      chotpage: 0,
      toprices: "0",
      price: "",
      PackageList: [],
      peakBandwidthData: "",
      waiTodayFlow: "",
      cdnNum: "",
      id: "",
      payID: "",
      purcqu: "1",
      active: "0",
      residueFlow: "",
      topDomain: [],
      useFlow: [],
      OrderList: {},
      todayFlowunnit: 0,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // var browerWidth = window.innerWidth; //浏览器可视宽度
    // var baseWidth = 1440; //设计稿宽度
    // var zoomValue = browerWidth / baseWidth; //缩放比例计算
    // document.getElementById("mainContainer").style.transform = "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
    // window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
    //   browerWidth = window.innerWidth;
    //   zoomValue = browerWidth / baseWidth;
    //   document.getElementById("mainContainer").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
    // }
    this.getCdnOverview();
    this.getCdnOverviewFlow();
    this.member();
  },
  methods: {
    perJump(data) {
      this.$router.push({ path: '/personter', query: { perhoew: data } })
    },
    //重新获取用户信息
    member() {
      this.$apis
        .member({
          mid: this.userInfo.id,
        })
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        });
    },
    Purchase() {
      this.pufwer = true;
      this.getResourcePackageList();
    },
    confiyment() {
      if (this.suffioff) {
        this.$apis
          .yuPayOrder({
            orderId: this.payID,
          })
          .then((res) => {
            if (res.code == 0) {
              this.pufwer = false;
              this.dackVisible = false;
              this.$message({
                type: "succcess",
                message: "支付成功",
              });
              this.getResourcePackageList();
            }
          });
      } else {
        this.$apis
          .getOrderById({
            orderId: this.payID,
          })
          .then((res) => {
            if (res.data.status == 0) {
              this.$message({
                type: "succcess",
                message: "请扫码支付",
              });           
            } else if (res.data.status == 1) {
              this.pufwer = false;
              this.dackVisible = false;
              this.$message({
                type: "warning",
                message: "支付成功",
              });
              this.getResourcePackageList();
            }
          });
      }
    },
    sitchinalance() {
      if (
        this.money > this.OrderList.payPrice ||   
        this.money == this.OrderList.payPrice
      ) {
        this.suffioff = true;
      } else {
        return;
      }
    },
    WeChatPay() {
      this.suffioff = false;
      // this.wxPayOrder(this.payID)
    },
    getOrderById(id) {
      this.$apis
        .getOrderById({
          orderId: id,
        })
        .then((res) => {
          this.OrderList = res.data;
          if (
            res.money > this.OrderList.payPrice ||
            res.money == this.OrderList.payPrice
          ) {
            this.suffioff = true;
            this.remaititle = "余额";
          } else {
            this.remaititle = "余额不足";
          }
          this.money = res.money;
          this.dackVisible = true;
          this.pufwer = false;
        });
    },
    wxPayOrder(id) {
      let that = this;
      this.$apis
        .wxPayOrder({
          orderId: id,
        })
        .then((res) => {
          that.codeurl = res.data.package.slice(9);
          const codeHtml = document.getElementById("qrcode");
          codeHtml.innerHTML = "";
          this.$nextTick(() => {
            let qrcode = new QRCode("qrcode", {
              width: 200,
              height: 180,
              text: that.codeurl, // 二维码地址
              colorDark: "#000",
              colorLight: "#fff",
            });
          });
        });
    },
    createOrder() {
      this.$apis
        .createOrder({
          mid: this.userInfo.id,
          productType: 0,
          productId: this.id,
          num: this.purcqu,
        })
        .then((res) => {
          this.getOrderById(res.data);
          this.wxPayOrder(res.data);
          this.payID = res.data;
        });
    },
    getResourcePackageList() {
      this.$apis
        .getResourcePackageList({
          offset: this.souroffset,
          limit: this.sourlimit,
          mid: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.rows.length > 0) {
            this.price = res.data.rows[0].marketPrice;
            this.id = res.data.rows[0].id;
          }
          this.toprices = Number(
            this.purcqu * this.price * this.userInfo.resourcesRatio
          ).toFixed(2);
          this.PackageList = res.data.rows;
          this.sourototal = res.data.total;
        });
    },
    pitcon(data, index) {
      this.active = index;
      this.id = data.id;
      this.price = data.marketPrice;
      this.toprices = Number(
        this.purcqu * this.price * this.userInfo.resourcesRatio
      ).toFixed(2);
    },
    getCdnOverviewFlow() {
      this.$apis
        .getCdnOverviewFlow({
          mid: this.userInfo.id,
          timeType: this.activeName,
          abroad: this.acdomes,
        })
        .then((res) => {
          this.topDomain = res.topDomain ? res.topDomain : [];
          this.useFlow = res.useFlow ? res.useFlow : [];
          if (document.getElementById("mychart")) {
            this.initEcharts();
          }
        });
    },
    purcquChange() {
      this.toprices = Number(
        this.purcqu * this.price * this.userInfo.resourcesRatio
      ).toFixed(2);
    },
    getCdnOverview() {
      this.$apis
        .getCdnOverview({
          mid: this.userInfo.id,
        })
        .then((res) => {
          this.domainNum = res.domainNum;
          this.todayFlow =
            Number(res.todayFlow) > 1000 && res.todayFlow < 1000000
              ? (res.todayFlow / 1000).toFixed(2)
              : res.todayFlow > 1000000
              ? (res.todayFlow / 1000 / 1000).toFixed(2)
              : Number(res.todayFlow).toFixed(2);
          this.todayFlowunnit = Number(res.todayFlow);
          this.requestNum = res.requestNum;
          this.peakBandwidthData = (
            res.peakBandwidthData /
            1024 /
            1024
          ).toFixed(2);
          this.waiTodayFlow = res.waiTodayFlow;
          this.cdnNum = res.cdnNum;
          this.residueFlow = res.residueFlow;
        });
    },
    acdomesClick() {
      this.getCdnOverviewFlow();
    },
    handleClick() {
      this.getCdnOverviewFlow();
    },
    oData() {
      this.$emit("oData", "5");
    },
    handleChange() {},
    chohandleChange(val) {
      this.currentpage = val;
      this.souroffset = (val - 1) * this.sourlimit;
      this.getResourcePackageList();
    },
    choSizeChange(val) {
      this.sourlimit = val;
      this.getResourcePackageList();
    },
    initEcharts() {
      // 基本柱状图
      const option = {
        xAxis: {
          type: "category", // x轴为 时间轴
          data: this.useFlow.dateList,

          axisLabel: {
            show: true,
            color: "##686868",
            interval: "auto", // 显示全部数据，还有auto/>0数字均可
            textStyle: {},
          },
        },
        yAxis: {
          type: "value",
          min: "dataMin",
          max: "dataMax",
          axisLabel: {
            //这种做法就是在y轴的数据的值旁边拼接单位，貌似也挺方便的
            formatter: "{value} MB",
            color: "##686868",
          },
        },
        // dataZoom: [
        //   {                 // 内置于坐标系中，使用户可以在坐标系上通过鼠标拖拽、鼠标滚轮、手指滑动（触屏上）来缩放或漫游坐标系
        //     type: 'inside',

        //     startValue: 0, //从0个柱子开始，也就是最起始的地方
        //     endValue: 9, //到第九个柱子结束
        // }, {
        //   start: 0,
        //   end: 100,                  // handleIcon 手柄的 icon 形状，支持路径字符串
        //   handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        //   handleSize: '60%',        //  控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
        //   handleStyle: {
        //     color: 'pink',
        //     shadowBlur: 3,      // shadowBlur图片阴影模糊值，shadowColor阴影的颜色
        //     shadowColor: 'red',
        //     shadowOffsetX: 9,
        //     shadowOffsetY: 2
        //   },
        //   show: false,
        // }],
        // legend: {
        //   itemWidth: 24,    //图例的宽
        //   itemHeight: 15,   //图例的高
        //   show: true,  //是否显示图例
        //   // left: "8%",  //图例距离左方位置
        //   bottom: "2%",   //图例距离上方位置
        // },
        series: [
          {
            type: "bar", //形状为柱状图
            data: this.useFlow.flowList,
            // name: "收入", // legend属性
            // barCategoryGap: '10%',
            // 调整同一系列中柱状图之间的间隔
            // barGap: 100,
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: false,
              position: "top",
              formatter: "{c}MB",
              color: "#6293ED",
            },
            itemStyle: {
              color: "#5087EC",
            },
            // barGap:'0%',
          },
        ],
      };
      const myChart = echarts.init(document.getElementById("mychart"));9
      myChart.setOption(option, true);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.nexp {
  width: 100%;
}
.sp {
  overflow-y: scroll;
  height: 430px;
}
.warinp {
  display: flex;
}
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px); 
}

/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007bff;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px;
  }

  .number {
    color: #007bff;
    border: 1px solid #dadbdb;
  }
}

.trymentdialog {
  /deep/.el-dialog__footer {
    padding: 0;

    .el-divider {
      margin-bottom: 10px;
    }

    .cancel {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      margin: 0 20px 20px 0;
      padding: 10px 15px;
    }
  }

  .nepay {
    span {
      color: #ed711f !important;
      font-size: 22px !important;
    }
  }

  .scpay {
    img {
      width: 128px;
      height: 128px;
      margin-top: 10px;
    }
  }

  .prme {
    font-size: 14px;
    color: #666666;
    margin-bottom: 20px;

    .maym {
      .active {
        border-color: #007bff !important;
        background: url("../../../assets/img/xzl.png") no-repeat top right;
        background-size: 20px 20px;
      }

      li {
        list-style: none;
        padding: 10px;
        border: 1px solid #e4e9f0;
        list-style: none;
        margin-right: 10px;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        display: inline-block;
      }
    }

    .unit {
      font-size: 14px !important;
    }

    label {
      flex: 0 0 16.666667%;
    }

    span {
      flex: 0 0 83.333333%;
    }
  }

  /deep/.el-dialog__body {
    padding: 0 20px 0 20px;
  }

  /deep/.el-dialog__header {
    .el-divider {
      margin: 20px 0 10px 0;
    }

    h5 {
      font-size: 15px !important;
      color: #333333;
      font-weight: 400 !important;
    }
  }
}

/deep/.el-select {
  margin-left: 20px;

  .el-input__inner {
    height: 30px;
    width: 106px;
  }

  .el-select__caret {
    line-height: 1;
  }
}

.puffi {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22px;

      .unit {
        font-size: 14px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {
    padding: 15px 15px 0px;
  }

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16px;
    }

    span {
      font-size: 13px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15px;
    color: #333333;
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
    padding: 15px;
    margin-bottom: 20px;
  }

  /deep/.el-drawer__body {
    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15px;
      color: #666666;
    }

    .list {
      width: 100%;

      ul {
        padding-right: 10px;
      }

      li {
        list-style: none;
      }
    }

    .pitcon {
      border-color: #007bff !important;
      background: url("../../../assets/img/xzl.png") no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22px;

          label {
            font-size: 14px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13px;
        }
      }

      .left {
        p {
          font-size: 20px;
          color: #666666;
        }
      }
    }
  }
}

/deep/.tretab {
  margin-right: 15px;

  .is-active {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }

  .el-tabs__nav {
    border-color: #17a2b8;
    border-radius: 3px;
  }

  .el-tabs__item {
    width: 65px;
    height: 27px;
    line-height: 27px;
    padding: 0;
    color: #17a2b8;
    border-color: #17a2b8;
    text-align: center;
  }

  .el-tabs__header {
    border: none;
    margin: 0;
  }
}

/deep/.hedtabs {
  margin-left: 20px;

  .is-active {
    background-color: #6c757d;
    color: #d9e2d3 !important;
  }

  .el-tabs__header {
    border: none;
    margin: 0;
  }

  .el-tabs__item {
    color: #ababb0;
    border-color: #afb3b6;
    height: 27px;
    line-height: 27px;
  }

  .el-tabs__nav {
    border-color: #afb3b6;
  }
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  margin-bottom: 25px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }

  .hedimg {
    width: 25px;
    height: 25px;
  }
}

.whres {
  margin-top: 15px;

  span {
    margin-right: 47px;
  }

  display: flex;

  /deep/.el-input__inner {
    width: 120px;
    background-color: #e9ecef;
    opacity: 1;
    border-radius: 2px;
    height: 30px;
    text-align: left;
  }

  label {
    color: #999;
    font-size: 12px;
  }
}

.off {
  font-size: 12px;
  color: #666;
  margin-top: 15px;

  span {
    margin-right: 80px;
  }

  /deep/.el-checkbox__label {
    color: #666;

    font-size: 14px;
  }
}

.cancel {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

/deep/.el-dialog__footer {
  // padding-top: 0;
}

/deep/.el-dialog__header {
  font-size: 13px;
}

/deep/.el-dialog__body {
  .alert {
    padding: 10px;
  }

  padding: 0;

  .el-divider {
    margin: 10px 0 10px 0;
  }
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 5px;

  a {
    color: #0056b3;
    text-decoration: none;
  }
}

.hoge {
  padding: 13px 41px 205px 41px;
}

.cotre {
  width: calc(40% - 20px);
  // height: 100%;

  .invoice {
    padding: 40px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    height: 100px;
    .invomain {
      //   padding: 40px;

      a {
        text-decoration: none;
        color: #1977ff;
      }

      span {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }

      .amod {
        margin: 15px 0 0 0;
        display: flex;
        align-items: baseline;

        .apvoic {
          margin-left: 40px;
        }

        label {
          color: #adadad;
          font-weight: bold;
          margin-right: 6px;
          font-size: 80%;
        }

        span {
          color: #999;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .toptanc {
        width: 65px;
        height: 27px;
        border-radius: 5px;
        background-color: rgba(33, 196, 205, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        text-align: center;
        display: block;
        line-height: 27px;
        margin-right: 9px;
      }

      .jiluyj {
        font-size: 14px;
        margin-left: 25px;
      }
    }
  }

  .prodonsu {
    height: calc(100% - 290px);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 40px;

    .prodmain {
      .Usata {
        /deep/.el-divider {
          margin: 20px 0px;
        }

        span {
          color: #9ea39f;
          margin-right: 20px;
        }

        font-size: 15px;
        font-weight: bold;
        color: #9ea39f;
      }

      .byxf {
        margin-top: 260.5px;
      }

      span {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

.caount {
  width: 60%;
  float: left;
  // margin-top: 26px;
  .bandweak {
    max-width: 28% !important;
    text-align: center;
  }

  .costren {
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    min-height: 500px;
    padding: 40px;

    .mota {
      color: #007bff;
      font-size: 13px;
    }

    .echart {
      width: 100%;
      height: 470px;
    }

    .costmain {
    }

    .fyqs {
      .bt {
        color: rgba(16, 16, 16, 1);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .casou {
    padding: 40px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    height: 100px;
    .camain {
      h5 {
        font-size: 12px;
        font-weight: bold;
        margin: 0 0 15px 0;
      }
    }

    .jiluyj {
      margin-left: 19px;
      font-size: 14px;

      .czjl {
        margin-right: 9px;
      }
    }

    a {
      text-decoration: none;
      color: #007bff;
    }

    span {
      color: rgba(16, 16, 16, 1);
      font-size: 12px;
      font-weight: bold;
    }

    .amod {
      margin: 15px 0 0 0;

      .uni {
        font-size: 13px;
      }

      span {
        font-size: 28px;
        color: #666666;
        font-weight: 400;
      }
    }

    .toptanc {
      width: 65px;
      height: 27px;
      border-radius: 5px;
      background-color: rgba(49, 152, 245, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 27px;
      margin-right: 9px;
    }

    .deduecord {
      width: 65px;
      height: 27px;
      border-radius: 5px;
      background-color: rgba(148, 148, 148, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 27px;
      margin-right: 9px;
    }
  }
}

.expewz {
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  font-weight: bold;
}
</style>
  