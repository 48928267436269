import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Router from './router/index';
import '../src/assets/scss/variable.scss'
import apis from './utils/requst';
import './utils/rem'

// import "amfe-flexible/index"
Vue.prototype.$apis = apis;
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router:Router
}).$mount('#app') 
