<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <div class="flex just-bet">
          <h3 class="cz flex">
            <img src="@/assets/img/zsgm.jpg" alt="" srcset="" class="hedimg">
            <span> 证书购买</span>

          </h3>
          <div>
            <el-button type="primary" class="purcha" @click="Purcificate">+ 购买证书</el-button>
          </div>
        </div>

        <div class="">


          <div>
            <el-table :data="PackageByMidList" style="width: 100%" empty-text="未找到证书...">
              <el-table-column prop="id" label="证书ID" width="124" align="center">
              </el-table-column>
              <el-table-column prop="encryptionType" label="加密标准" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.encryptionType == 0 ? '国际标准' : '国密标准' }}
                </template>
              </el-table-column>
              <el-table-column prop="sslBrand" label="证书种类" width="200" align="center">
                <template slot-scope="scope">

                  {{ scope.row.sslType == 1 ? '企业型OV' :
                    scope.row.sslType == 2 ? '域名型DV' :
                      scope.row.sslType == 3 ? '增强型EV' :
                        scope.row.sslType == 4 ? '增强型专业版EV Pro' :  scope.row.sslType == 5?'企业型(Pro OV)':"" }}
                </template>
              </el-table-column>
              <el-table-column prop="sslBrand" label="证书品牌" width="200">
                <template slot-scope="scope">

                  {{ scope.row.sslBrand == 1 ? 'DigiCert' :
                    scope.row.sslBrand == 2 ? 'GeoTrust ' :
                      scope.row.sslBrand == 3 ? 'CFCA(中国金融中心)' :
                        scope.row.sslBrand == 4 ? 'GlobalSign' : '' }}
                </template>
              </el-table-column>
              <el-table-column prop="doaminType" label="证书类型" width="100">
                <template slot-scope="scope">

                  {{ scope.row.doaminType == 1 ? '单域名' :
                    scope.row.doaminType == 2 ? '多域名 ' :
                      scope.row.doaminType == 3 ? '通配域名' : '' }}
                </template>
              </el-table-column>
              <el-table-column prop="validity" label="时长(年)" width="100" align="center">
              </el-table-column>
              <el-table-column prop="sslDomain" label="绑定域名" width="200" align="center">
              </el-table-column>
              <el-table-column prop="endTime" label="到期时间" width="200" >
              </el-table-column>
              <!-- <el-table-column prop="sslUrl" label="证书下载地址" width="100" align="center">
              </el-table-column> -->
              <el-table-column prop="address" label="操作" align="center">
                <template slot-scope="scope">
                  <div class="flex just-center" v-show="scope.row.sslUrl">
                    <el-button type="primary" class="purcha" @click="Downtificate(scope.row.sslUrl)">下载证书</el-button>
                  </div>

                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
            class="flex just-center  mg-tp-23">
          </el-pagination>
        </div>


      </div>

    </div>

    <!-- //发票信息设置 -->

    <!-- 证书购买 -->
    <div class="trymentdialog">
      <el-dialog :visible.sync="dackVisible" width="50%">
        <div slot="title">
          <h5>购买证书</h5>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="prme ">
            <div class="flex">

              <label>加密标准</label>
              <el-menu :default-active="enstan" class="el-menu-demo" mode="horizontal" @select="enstanSelect">
                <el-menu-item :index="String(item.id)" v-for="item in enstandard" :key="item.id">{{ item.name
                }}</el-menu-item>
              </el-menu>
            </div>
            <p>支持RSA或ECC算法,适用于谷歌、360、火狐等主流浏览器，日常业务需要SSL证书请选择这个加密标准。</p>

          </div>
          <div class="prme ">
            <div class="flex">

              <label>证书种类</label>
              <el-menu :default-active="Tycerti" class="el-menu-demo" mode="horizontal" @select="TycertiSelect">
                <el-menu-item :index="String(item.id)" v-for="item in Tycertificate" :key="item.id">{{ item.name
                }}</el-menu-item>
                <!-- disabled -->
              </el-menu>
            </div>
          </div>
          <div class="prme ">
            <div class="flex">
              <label>证书品牌</label>
              <!-- <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false"
                @close="handleClose(tag)">
                {{ tag }}
              </el-tag>
              <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
                @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button> -->
              <el-menu :default-active="Cerb" class="el-menu-demo" mode="horizontal" @select="CerbSelect">
                <el-menu-item v-for="item in Cerbrand" :index="String(item.id)" :key="item.id">{{ item.name
                }}</el-menu-item>
              </el-menu>

            </div>
            <p>如需指定证书品牌,请点击<a>选择证书品牌</a>,如未指定品牌,默认使用系统推荐的品牌(不同品牌的价格不同)</p>
          </div>
          <div class="prme ">
            <div class="flex">
              <label>域名类型</label>
              <el-menu :default-active="dot" class="el-menu-demo" mode="horizontal" @select="dotSelect">
                <el-menu-item :index="String(item.id)" v-for="item in dotype" :key="item.id">{{ item.name
                }}</el-menu-item>

                <!-- disabled -->
              </el-menu>

            </div>
            <div>
              <p>仅支持绑定一个二级域名或者子域名，例如tencent.com、cloud.tencent.com、dnspod.cloud.tencent.com的其中之一</p>
              <p style="margin-top: 0;">如需要绑定同级的所有子域名，例如*tencent.com，请购买泛域名证书。</p>
            </div>


          </div>
          <div class="prme ">
            <div class="flex">
              <label>时长</label>
              <el-menu :default-active="dur" class="el-menu-demo" mode="horizontal" @select="dureSelect">
                <el-menu-item :index="String(item.id)" v-for="item in duration" :key="item.id">{{ item.name
                }}</el-menu-item>

                <!-- disabled -->
              </el-menu>

            </div>

          </div>
          <div class="prme ">
            <div class="flex al-baseline">
              <label>证书数量</label>
              <div class="">
                <div class="flex-column al-baseline">

                  <div class="order flex">
                    <div class="flex ">
                      <el-input-number v-model="addoname" @change="handleChange" :min="0" :max="100"
                        label="描述文字"></el-input-number>
                      <small class="fw"> (订购数量范围1-100) </small>
                    </div>


                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="prme domme">
            <div class="flex">
              <label>域名</label>
              <div class="standa">
                <div class="flex">
                  <el-input v-model="sslDomain" placeholder="请输入域名" @change="sslChange"></el-input>
                  <small class="color-7F wte-nrap mg-lt-10">(多个用“；”隔开，例：域名1；域名2)</small>
                </div>

              </div>
            </div>

          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <div>
            <div class="flex purces">
              <span class="upri"></span>
              <span>购买须知</span>
            </div>
            <div class="mg-tp-23 ">
              <span class="isst">签发说明</span>
              <span class="color-999">证书签发流程:1、购买证书 2、在SSL证书控制台绑定域名并提交其他资料 3、等待审核 4、签发成功。<a>查看签发指南<img
                    src="../../../assets/img/weisz.png" class="iudeimg" />。</a></span>
            </div>
            <div class="mg-tp-23 ">
              <span class="isst">多年期说明</span>
              <span
                class="color-999">多年期证书包含多张一年期证书,购买后会先下发第一张证书，等第一张证书临近到期前，系统会自动申请第二张证书。（多年期证书一次性收取多年费用，如后续不再使用证书不支持部分退款）</span>
            </div>
          </div>

          <div class="mg-tp-30 ">
            <el-checkbox v-model="checked">我以阅读并同意 <a href="https://upload.znidc.net/files/SSLzsfw.html" target="_blank"
                rel="noopener noreferrer">《SSL证书服务申明》</a> </el-checkbox>
          </div>
          <div class="flex just-bet ">
            <div class="expens">
              <span>费用：</span>
              <label class="unit">￥</label>
              <span class="sz">{{ marketPrice }}</span>
              <span class="discoun">￥{{ productPrice }}
              </span>
            </div>
            <el-button @click="createOrder" type="primary" :disabled="forbidd">立即购买</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
    <div class="trymentdialog">
      <el-dialog :visible.sync="reconvis" width="80%">
        <div slot="title">
          <h5>核对和支付</h5>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="prme flex">
            <label>商品名称</label>
            <span>{{ sslCertList.productName }}</span>
          </div>
          <div class="prme flex">
            <label>购买数量
            </label>
            <span>{{ sslCertList.total }} 个</span>
          </div>
          <div class="prme flex nepay ">
            <label>需要支付
            </label>
            <span class=""><label class="unit">￥</label>{{ sslCertList.payPrice }}</span>
          </div>
          <div class="prme flex ">
            <label>支付方式
            </label>
            <div class="maym">
              <ul class="flex">
                <li :class="suffioff ? 'active' : ''" @click="sitchinalance">
                  <span class="">{{ remaititle }} ￥{{ money }}</span>

                </li>
                <li :class="!suffioff ? 'active' : ''" @click="WeChatPay">
                  <span class="">微信支付</span>
                </li>
              </ul>


            </div>

          </div>
          <div class="prme flex  scpay al-baseline" v-show="!suffioff">
            <label>扫码支付
            </label>
            <div class="flex-column al-baseline">
              <span class="">请使用微信客户端扫码二维码完成支付</span>
              <div id="qrcode" ref="qrcode" class="erwma mg-tp-10">
              </div>

            </div>

          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-divider></el-divider>
          <div class="text-rt">
            <el-button @click="reconvis = false" class="cancel">取 消</el-button>
            <el-button @click="confiyment" class="mg-rt-22" type="primary">确认支付</el-button>
          </div>

        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {

  props: {
    msg: String
  },
  data() {
    return {
      checked: false,
      dackVisible: false,
      reconvis: false,
      forbidd: false,
      suffioff: false,
      addoname: 1,
      remaititle: "余额",
      money: "",
      enstandard: [],
      enstan: "",
      Tycertificate: [],
      Tycerti: "",
      Cerbrand: [],//证书品牌有的
      Cerb: "",
      dotype: [],
      dot: "",
      duration: [],
      dur: "",
      sslDomain: "",
      sslList: [],
      value: '',
      PackageByMidList: [],
      userInfo: {},
      offset: 0,
      limit: 10,
      total: 0,
      currentpage: 0,
      type: "6",
      id: "",
      productId: "",
      sslCertList: {},
      payID: "",
      domainArr: [],
      errDomainArr: [],
      marketPrice: "",
      productPrice: "",
      unitprice: 0,
      verification:false,
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getSslPackageByMid()
    this.getSslPackage()

  },
  created() {

  },

  methods: {
    sslChange() {
      let Domainlength = this.sslDomain.split(";").length;
      if(Domainlength>this.addoname) {
        this.addoname=Domainlength
        this.marketPrice =( Number(this.unitprice) * (Number(this.userInfo.sslRatio)) * Number(this.addoname)).toFixed(2)
      }
    
      if (this.addoname == Domainlength) {
        const arr = this.sslDomain.split(";");
        this.domainArr = [];
        this.errDomainArr = [];
        arr.forEach((domain) => {
          domain = domain.trim();
          if (domain !== "") {
            this.checkDomain(domain)
              ? this.domainArr.push(domain)
              : this.errDomainArr.push(domain);
          }
        })
       this.verification=true
      } else {
        this.$message({
          type: "error",
          message: `请填写${this.addoname}` + '个域名'
        })
        return
      }

    },
    //验证域名
    checkDomain(url) {
      var strRegex = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
      var re = new RegExp(strRegex);
      return re.test(url);
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    confiyment() {
      if (this.suffioff) {
        this.$apis.yuPayOrder({
          orderId: this.payID
        }).then((res) => {
          if (res.code == 0) {
            this.reconvis = false
            // this.getCheckSslPackage()
            this.getSslPackageByMid()
          }
        })
      } else {
        this.$apis.getOrderById({
          orderId: this.payID
        }).then((res) => {
          if (res.data.status == 0) {
            this.$message({
              type: "warning",
              message: '请扫码支付'
            });
          } else if (res.data.status == 1) {
            this.reconvis = false
            // this.getCheckSslPackage()
          }
        })
      }

    },
    WeChatPay() {
      this.suffioff = false
      // this.wxPayOrder(this.payID)
    },
    sitchinalance() {
      if (this.money > this.sslCertList.payPrice||this.money==this.sslCertList.payPrice) {
        this.suffioff = true
      } else {
        return
      }

    },
    CerbSelect(value) {
      this.Cerb = value
      this.type = 3
      this.id = value
      this.dot = ""
      this.dur = ""
      this.$nextTick(() => {
        this.getSslTypeList()
      })

    },
    dotSelect(value) {
      this.dot = value
      this.type = 4
      this.id = value
      this.dur = ""
      this.$nextTick(() => {
        this.getSslTypeList()
      })
    },
    dureSelect(value) {
      this.type = 5
      this.id = value
      this.dur = value
      this.$nextTick(() => {
        this.getSslTypeList()
      })
    },
    enstanSelect(value) {
      this.type = 1
      this.id = value
      this.enstan = value
      this.Cerb = ""
      this.dot = ""
      this.dur = ""
      this.Tycerti = ""
      this.$nextTick(() => {
        this.getSslTypeList()
      })
    },
    TycertiSelect(value) {
      this.type = 2
      this.id = value
      this.Tycerti = value
      // this.Tycerti = ""
      this.Cerb = ""
      this.dot = ""
      this.dur = ""
      this.$nextTick(() => {
        this.getSslTypeList()
      })
    },


    getSslTypeList() {
      this.$apis.getSslTypeList({
        type: this.type,
        id: this.id,
        mid: this.userInfo.id,
        encryptionType: this.enstan,
        sslType: this.Tycerti,
        sslBrand: this.Cerb,
        domainType: this.dot,
        validity: this.dur,
      }).then((res) => {
        this.enstandard = res.data.encryptionTypeList ? res.data.encryptionTypeList : this.enstandard
        this.Tycertificate = res.data.sslTypeList ? res.data.sslTypeList : this.Tycertificate
        this.dotype = res.data.domainTypeList ? res.data.domainTypeList : this.dotype
        this.duration = res.data.validityList ? res.data.validityList : this.duration
        this.Cerbrand = res.data.sslBrandList ? res.data.sslBrandList : this.Cerbrand
        this.marketPrice = (Number(res.data.ssl.marketPrice) * Number(res.sslRatio) * Number(this.addoname)).toFixed(2)
        this.productPrice = res.data.ssl.productPrice
        this.unitprice = res.data.ssl.marketPrice//单价
        this.productId = res.data.ssl.id
        if (this.id) {
          this.enstandard = res.data.encryptionTypeList ? String(res.data.encryptionTypeList[0].id) : this.enstandard
          this.Tycerti = res.data.sslTypeList ? String(res.data.sslTypeList[0].id) : this.Tycerti
          this.Cerb = res.data.sslBrandList ? String(res.data.sslBrandList[0].id) : this.Cerb
          this.dot = res.data.domainTypeList ? String(res.data.domainTypeList[0].id) : this.dot
          this.dur = res.data.validityList ? String(res.data.validityList[0].id) : this.dur
        }
        // this.getCheckSslPackage()
      })
    },
    Purcificate() {
      this.dackVisible = true
      this.getSslPackage()
    },
    getSslPackage() {
      // this.dackVisible = true
      this.sslDomain = ""
      this.addoname = 1
     
      this.$apis.getSslPackage({
        mid: this.userInfo.id
      }).then((res) => {
        this.enstan = String(res.data.encryptionType)
        this.Tycerti = String(res.data.sslType)
        this.Cerb = String(res.data.sslBrand)
        this.dot = String(res.data.domainType)
        this.dur = String(res.data.validity)
        this.enstandard = res.sslList.encryptionTypeList
        this.dotype = res.sslList.domainTypeList
        this.Cerbrand = res.sslList.sslBrandList
        this.Tycertificate = res.sslList.sslTypeList
        this.duration = res.sslList.validityList
        this.marketPrice = (Number(res.data.marketPrice) * (Number(res.sslRatio)) * Number(this.addoname)).toFixed(2)
        this.unitprice = res.data.marketPrice//单价
        this.productPrice = res.data.productPrice
        this.productId = res.data.id
        // this.getCheckSslPackage()
      })
    },
    Downtificate(url) {
      let a = document.createElement('a')
      a.target="_blank"
      a.href = url
      a.click();
    },
    handleChange() {
      let Domainlength = this.sslDomain.split(";").length;
      if(this.addoname<=Domainlength) {
        this.addoname=String(Domainlength) 
      }
      this.marketPrice = (Number(this.unitprice) * (Number(this.userInfo.sslRatio)) * Number(this.addoname)).toFixed(2)
    },
    handleCurrentChange(val) {
      this.currentpage = val
      this.offset = (val - 1) * this.limit
      this.getSslPackageByMid()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getSslPackageByMid()
    },
    //查询列表
    getSslPackageByMid() {
      this.$apis.getSslPackageByMid({
        mid: this.userInfo.id,
        offset: this.offset,
        limit: this.limit
      }).then((res) => {
        this.PackageByMidList = res.data.rows
        this.total = res.data.total
      })
    },
    getOrderById(id) {
      this.$apis.getOrderById({
        orderId: id
      }).then((res) => {
        this.sslCertList = res.data
        if (res.money > this.sslCertList.payPrice||res.money==this.sslCertList.payPrice) {
          this.suffioff = true
          this.remaititle = '余额'
        } else {
          this.remaititle = '余额不足'
        }
        this.money = res.money
        this.reconvis = true
        this.dackVisible = false
      })
    },
    //查询证书品牌

    createOrder() {
      if( !this.verification) {
        this.$message({
          type: "error",
          message:`请填写${this.addoname}` + '个域名'
        })
      }
      if (this.errDomainArr.length > 0) {
        return this.$message({
          type: "error",
          message: "请输入正确域名"
        })
      }
      if (this.checked) {
        if (this.domainArr.length > 0) {
          this.$apis.createOrder({
            mid: this.userInfo.id,
            productType: "1",
            productId: this.productId,
            num: this.addoname,
            sslDomain: this.domainArr.length > 1 ? this.domainArr.join(',') : this.domainArr[0]
          }).then((res) => {
            this.dackVisible = false
            this.reconvis = true
            this.payID = res.data
            this.getOrderById(res.data)
            this.wxPayOrder(res.data)

          })
        } else {
          this.$message({
            type: "error",
            message: "请填写域名"
          })
        }

      } else {
        this.$message({
          type: "error",
          message: "请同意服务申明"
        })
      }

    },
    //支付二维码
    wxPayOrder(id) {
      let that = this
      this.$apis.wxPayOrder({
        orderId: id
      }).then((res) => {
        that.codeurl = res.data.package.slice(9);
        const codeHtml = document.getElementById("qrcode");
        codeHtml.innerHTML = ""
        this.$nextTick(() => {
          let qrcode = new QRCode("qrcode", {
            width: 200,
            height: 180,
            text: that.codeurl, // 二维码地址
            colorDark: "#000",
            colorLight: "#fff",
          });
        })

      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  overflow: scroll;
  overflow-x: hidden;

  .el-tag {
    background-color: #F1F2F5;
    color: #101010;
    border: none;
    flex: 0 !important
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}

/deep/.el-menu-item {
  height: 25px !important;
  line-height: 25px !important;
  background-color: #F1F2F5;
  color: #101010;
}

/deep/.is-active {
  background-color: #0052D9 !important;
  color: #fff !important;
  border: none !important;
}

/deep/.el-menu-demo {
  border: none;
}

/deep/.el-pager {
  font-size: 12px;


  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

.trymentdialog {
  /deep/.el-dialog__footer {
    text-align: left;
    padding: 20px;

    a {
      color: #3E7BE1;

    }

    .expens {
      // font-size: 30px;
      color: #999;

      .discoun {
        margin-left: 5px;
        text-decoration: line-through
      }

      .sz {
        font-size: 23px;
        font-weight: bold !important;
        color: #E55169 !important;
      }
    }

    .unit {
      color: #E55169;
      font-size: 18px !important;
    }

    .isst {
      padding: 5px 10px;
      background-color: #EDEFF3;
      margin-right: 10px;
      color: #85878a;
    }

    .iudeimg {
      width: 10px;
      height: 10px;
      margin-left: 5px;
    }

    .purces {
      .upri {
        height: 20px;
        width: 3px;
        background-color: #3E7BE1;
        display: block;
        margin: 0;
      }

      span {
        font-size: 23px;
        font-weight: bold;
        margin-left: 8px;
      }
    }

    .el-divider {
      margin-bottom: 10px;
    }

    .cancel {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      // margin: 0 20px 20px 0;
      // padding: 10px 15px;
    }
  }


  .nepay {

    span {
      color: #ed711f !important;
      font-size: 22Px !important;
    }
  }

  .scpay {
    img {
      width: 128px;
      height: 128px;
      margin-top: 10px;
    }
  }

  .domme {
    /deep/.el-input__inner {
      height: 30px !important;
    }
  }

  .prme {
    font-size: 12Px;
    color: #666666;
    margin-bottom: 12px;

    .order {
      background-color: rgba(241, 242, 245, 1);
      padding: 0px 9px 0px 9px;
    }

    .fw {
      margin-left: 10px;
    }

    .active {
      // background-color: #0052D9 !important;
      // color: #fff !important;
    }

    .standa {
      .inti {
        position: relative;
        left: 0;
      }

      .seke {
        padding: 3px 4px;
        background-color: #FF7200;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        right: 0;
        top: -10px;
      }
    }

    .maym {

      .active {
        border-color: #007bff !important;
        background: url('../../../assets/img/xzl.png') no-repeat top right;
        background-size: 20px 20px;
      }

      li {
        list-style: none;
        padding: 9px 30px 9px 30px;
        border: 1px solid #e4e9f0;
        list-style: none;
        margin-right: 10px;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        display: inline-block;

      }
    }

    .unit {
      font-size: 14Px !important;
    }

    label {
      flex: 0 0 8.666667%;
    }

    span {
      flex: 0 0 83.333333%;
    }

    p {
      color: #c2c0c0;
      font-size: 12px;
      margin-top: 0;
      margin-left: 80px;
    }

    a {
      color: #3E7BE1;
    }

    /deep/.el-input-number {
      width: 80px !important;

      .el-input__inner {
        border: none;
        width: 80px;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent !important;
      }

      .el-input-number__decrease {
        top: 12px !important;
        width: 20px !important;
        height: 20px !important;
        line-height: 20px !important;
      }

      .el-input-number__increase {
        top: 12px !important;
        width: 20px !important;
        height: 20px !important;
        line-height: 20px !important;
      }
    }
  }

  /deep/.el-dialog__body {
    padding: 0 20px 0 20px;
  }

  /deep/.el-dialog__header {
    .el-divider {
      margin: 20px 0 10px 0;
    }

    h5 {
      font-size: 14Px !important;
      color: #333333;
      font-weight: 400 !important;
    }
  }
}

.puffi {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13Px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22Px;

      .unit {
        font-size: 14Px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {
    padding: 15px 15px 0px;
  }

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16Px;
    }

    span {
      font-size: 13Px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16Px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15Px;
    color: #333333;
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
    padding: 15px;
    margin-bottom: 20px;
  }

  /deep/.el-drawer__body {


    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15Px;
      color: #666666;
    }

    .list {
      width: 100%;

      ul {
        padding-right: 10px;
        height: 620Px;
        overflow-y: scroll;
      }

      li {
        list-style: none;
      }
    }

    .pitcon {
      border-color: #007bff !important;
      background: url('../../../assets/img/xzl.png') no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22Px;

          label {
            font-size: 14Px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13Px;

        }
      }

      .left {
        p {
          font-size: 20Px;
          color: #666666;
        }
      }
    }
  }
}

/deep/.el-progress {
  // width: 230px !important;
  // margin-left: 20px;
}

/deep/.el-progress-bar__innerText {
  color: #fff !important;
}

.dag {
  margin-top: 18px;

  p {
    font-size: 14px;
    letter-spacing: 0;
    color: #666666;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}

.purcha {
  background: #4272d7;
  border: none;
  line-height: 0px;
  font-size: 14px;
  padding: 13px 15px;
  border-radius: 0;
  margin-left: 10px;
}

.selmain {
  margin: 20px 0 10px 0;

  /deep/.el-input__inner {
    height: 30px;
    // width: 300px;
  }

  /deep/.el-input__icon {
    line-height: 40px;
  }

  span {
    font-size: 14Px;
  }

  color: #666666;
}

.hedimg {
  width: 25px;
  height: 25px;
}







.deion {
  color: #004085;
  background-color: #CCE5FF;
  border-color: #b8daff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 400;

  h4 {
    margin: 0;
  }
}

.alert {
  span {
    width: 56px;
    height: 50px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin-right: 54px;
  }
}

.appbton {
  padding: 0;
  width: 59px;
  height: 30px;
  line-height: 0;
  border-radius: 4px;
  background-color: rgba(95, 165, 242, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
}

.tabdelt {
  width: 32px;
  height: 34px;
}

.tabjzst {
  width: 25px;
  height: 25px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;

    h3 {
      margin: 0;
    }
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 800;
  }

  .has-gutter {
    color: #666666;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  // margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
    font-weight: bold;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}
</style>
