<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <el-form ref="form" :model="form" class="btrFrom">
          <el-form-item label="范围">
            <el-select
              v-model="form.productType"
              placeholder="请选择加速类型"
              clearable
            >
              <el-option label="CDN加速" value="008"></el-option>
            </el-select>
            <el-select
              v-model="form.domain"
              placeholder="请选择域名"
              clearable
              multiple
              collapse-tags
              class="domaiselct"
            >
              <el-option
                v-for="item in domainoptions"
                :key="item.domain"
                :label="item.domain"
                :value="item.domain"
              >
              </el-option>
            </el-select>
            <el-select v-model="form.abroad" placeholder="请选择区域" clearable>
              <el-option label="中国内地" value="0"></el-option>
              <el-option label="全球不含中国内地" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="协议">
            <el-select
              v-model="form.applicationLayerProtocol"
              placeholder="请选择协议类型"
              clearable
            >
              <el-option label="http" value="http"></el-option>
              <el-option label="https" value="https"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <div class="flex">
              <el-select
                v-model="form.interval"
                placeholder="请选择时间"
                clearable
              >
                <el-option
                  v-for="item in timeSeelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-tabs
                v-model="form.timeType"
                type="card"
                @tab-click="handletime"
                class="tretab"
              >
                <el-tab-pane label="今天" name="1"></el-tab-pane>
                <el-tab-pane label="昨天" name="2"></el-tab-pane>
                <el-tab-pane label="近七天" name="3"></el-tab-pane>
                <el-tab-pane label="近三十天" name="4"></el-tab-pane>
                <el-tab-pane
                  label="自定义"
                  name="5"
                  v-if="customShow"
                ></el-tab-pane>
              </el-tabs>
              <el-date-picker
                v-if="!customShow"
                v-model="time"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                ref="picker"
                :picker-options="pickerOptions"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
              <el-button type="primary" class="inquire" @click="inQuire"
                >查询</el-button
              >
            </div>
          </el-form-item>
          <el-form-item class="bndffic">
            <div class="flex">
              <el-tabs v-model="form.isFlow" type="card" class="tretab">
                <el-tab-pane label="所有状态码" name="1"></el-tab-pane>
                <el-tab-pane label="2XXX" name="2"></el-tab-pane>
                <el-tab-pane label="3XXX" name="3"></el-tab-pane>
                <el-tab-pane label="4XXX" name="4"></el-tab-pane>
                <el-tab-pane label="5XXX" name="5"></el-tab-pane>
              </el-tabs>
            </div>
          </el-form-item>
          <div class="particu flex">
            <div>
              <span>总状态码量：{{ statusCodeTotal }}次</span>
            </div>
            <div>
              <span v-if="form.isFlow !== '1'"
                >{{ form.isFlow == "1" ? "" : form.isFlow + "XX" }}状态码量：{{
                  codeTotal
                }}次</span
              >
            </div>
          </div>
        </el-form>
        <div class="nexp">
          <!-- <span> 你暂时还没产生费用~</span> -->
          <span class="unit">单位:次</span>
          <div class="echart" id="stodechart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { formatDate, formatting } from "../../../../../utils/Date";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      dialogVisible: false,
      time: [],
      customShow: true,
      timeSeelect: [
        {
          value: "5m",
          label: "5分钟",
        },
        {
          value: "1h",
          label: "1小时",
        },
      ],
      statusCodeTotal: "",
      codeTotal: "",
      pickerOptions: {
        disabledDate(time) {
          // let startDate = startyear + '-' + (new Date().getMonth() + 1) + '-' +
          //   new Date().getDate();//限制12个月,限制到天
          let curDate = new Date().getTime();
          let three = 180 * 24 * 3600 * 1000;
          let threeMonths = curDate + three;
          let threeMo = curDate - three;
          return time.getTime() > threeMonths || time.getTime() < threeMo;
        },
      },
      checked: false,
      activeName: "first",
      acdomes: "first1",
      echatmes: "bandwidt",
      num: 1,
      totalFlow: "",
      value: "流量",
      form: {
        productType: "",
        domain: [],
        abroad: "",
        applicationLayerProtocol: "",
        timeType: "1",
        start: "",
        end: "",
        isFlow: "1",
        interval: "5m",
      },
      domainoptions: [],
      userInfo: {},
      xAxisData: [],
      YAyisData: [],
      eachrsseries: [],
      legedata: [],
      http_status_code_proportion: [],
      proportion: [],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.httpStatusCodeData();
    this.getMemberDomainList();
  },
  methods: {
    inQuire() {
      if (!this.time && this.form.timeType == 5) {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
      } else {
        this.httpStatusCodeData();
      }
    },
    getMemberDomainList() {
      this.$apis
        .getMemberDomainList({
          mid: this.userInfo.id,
        })
        .then((res) => {
          this.domainoptions = res.data;
        });
    },
    httpStatusCodeData() {
      this.$apis
        .httpStatusCodeData({
          mid: this.userInfo.id,
          productType: this.form.productType,
          domain: this.form.domain.join(","),
          abroad: this.form.abroad,
          applicationLayerProtocol: this.form.applicationLayerProtocol,
          timeType: this.form.timeType,
          start: this.time[0],
          end: this.time[1],
          isFlow: this.form.isFlow,
          interval: this.form.interval,
        })
        .then((res) => {
          this.eachrsseries = []; //清空eachrt数据
          this.YAyisData = [];

          if (this.form.isFlow == 1) {
            this.xAxisData = [];
            res.dataTime.forEach((el) => {
              this.xAxisData.push(formatDate(el));
            });
          } else {
            this.xAxisData = [];
            res.dataTime.forEach((el) => {
              this.xAxisData.push(formatDate(el));
            });
          }

          if (this.form.isFlow == 2) {
            this.eachrsseries = [];
            let color = ["#358ae2", "#3d73f5", "#d53df5", "#cb415b", "#2f20f6"];
            res.data.forEach((el, index) => {
              let twos = [];
              this.legedata.push(String(el.code));
              el.codeList.forEach((el) => {
                twos.push(el.http_status_code_num);
              });
              this.eachrsseries.push({
                name: String(el.code),
                type: "line",
                data: twos,
                showSymbol: false,
                smooth: false,
                lineStyle: {
                  color: color[index],
                },
              });
            });
          } else if (this.form.isFlow == 3) {
            this.eachrsseries = [];

            let color = ["#358ae2", "#3d73f5", "#d53df5", "#cb415b", "#2f20f6"];
            res.data.forEach((el, index) => {
              this.legedata.push(String(el.code));
              let threes = [];
              el.codeList.forEach((el) => {
                threes.push(el.http_status_code_num);
              });
              this.eachrsseries.push({
                name: String(el.code),
                type: "line",
                data: threes,
                showSymbol: false,
                smooth: false,
                lineStyle: {
                  color: color[index],
                },
              });
            });
          } else if (this.form.isFlow == 4) {
            this.eachrsseries = [];
            let color = ["#358ae2", "#3d73f5", "#d53df5", "#cb415b", "#2f20f6"];

            res.data.forEach((el, index) => {
              let fours = [];
              this.legedata.push(String(el.code));
              el.codeList.forEach((el) => {
                fours.push(el.http_status_code_num);
              });
              this.eachrsseries.push({
                name: String(el.code),
                type: "line",
                data: fours,
                showSymbol: false,
                smooth: false,
                lineStyle: {
                  color: color[index],
                },
              });
            });
          } else if (this.form.isFlow == 5) {
            this.eachrsseries = [];
            let color = ["#358ae2", "#3d73f5", "#d53df5", "#cb415b", "#2f20f6"];

            res.data.forEach((el, index) => {
              this.legedata.push(String(el.code));
              let fives = [];
              el.codeList.forEach((el) => {
                fives.push(el.http_status_code_num);
              });
              this.eachrsseries.push({
                name: el.code,
                type: "line",
                data: fives,
                showSymbol: false,
                smooth: false,
                lineStyle: {
                  color: color[index],
                },
              });
            });
          } else if (this.form.isFlow == 1) {
            for (let i = 0; i <= 3; i++) {
              if (i == 0) {
                let twos = [];
                let proportion = [];
                this.legedata.push("2XX");

                res.two.forEach((el) => {
                  twos.push(el.http_status_code_num);
                  proportion.push(el.http_status_code_proportion);
                });
                this.eachrsseries.push({
                  name: "2XX",
                  type: "line",
                  data: twos,
                  showSymbol: false,
                  smooth: false,
                  lineStyle: {
                    color: "#358ae2",
                  },
                  listdea: proportion,
                });
              } else if (i == 1) {
                let threes = [];
                this.legedata.push("3XX");
                res.three.forEach((el) => {
                  threes.push(el.http_status_code_num);
                  this.proportion.push(el.http_status_code_proportion);
                });
                this.eachrsseries.push({
                  name: "3XX",
                  type: "line",
                  data: threes,
                  showSymbol: false,
                  smooth: false,
                  lineStyle: {
                    color: "#3d73f5",
                  },
                });
              } else if (i == 2) {
                let fours = [];
                this.legedata.push("4XX");
                res.four.forEach((el) => {
                  fours.push(el.http_status_code_num);
                  this.proportion.push(el.http_status_code_proportion);
                });

                this.eachrsseries.push({
                  name: "4XX",
                  type: "line",
                  data: fours,
                  showSymbol: false,
                  smooth: false,
                  lineStyle: {
                    color: "#3f46bc",
                  },
                });
              } else if (i == 3) {
                let fives = [];
                this.legedata.push("5XX");
                res.five.forEach((el) => {
                  fives.push(el.http_status_code_num);
                  this.proportion.push(el.http_status_code_proportion);
                });
                this.eachrsseries.push({
                  name: "5XX",
                  type: "line",
                  data: fives,
                  showSymbol: false,
                  smooth: false,
                  lineStyle: {
                    color: "#42abe2",
                  },
                });
              }
            }
          }
          this.statusCodeTotal = res.statusCodeTotal;
          this.codeTotal = res.codeTotal;
          this.$nextTick(() => {
            this.initEcharts();
          });
        });
    },
    handletime() {
      if (this.form.timeType == 1 || this.form.timeType == 2) {
        this.timeSeelect = [];
        this.timeSeelect.push(
          {
            value: "5m",
            label: "5分钟",
          },
          {
            value: "1h",
            label: "1小时",
          }
        );
        this.form.interval = this.timeSeelect[0].value;
      } else {
        this.timeSeelect = [];
        this.timeSeelect.push(
          {
            value: "1h",
            label: "1小时",
          },
          {
            value: "24h",
            label: "1天",
          }
        );
        this.form.interval = this.timeSeelect[0].value;
      }
      if (this.form.timeType == "5") {
        this.customShow = false;
        this.time = [];
        const end = new Date();
        const start = new Date();
        // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.time.push(
          String(formatting(start) + " 00:00:00"),
          String(formatting(end) + " 23:59:59")
        );
      } else {
        this.customShow = true;
        this.time = "";
      }
    },

    initEcharts() {
      const myChart = echarts.init(document.getElementById("stodechart"));
      let that = this;
      // this.eachrsseries=[]
      // 基本柱状图
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function (params) {
            var str = "";
            //首先要看看params是怎样的数据结构，里面有什么内容;
            //对params进行操作
            params.forEach((item, index) => {
              str += item.seriesName + ":" + item.value + "<br/>";
            });
            return str;
          },
          // valueFormatter: function (value) {
          //   return '状态码量:' + value + '次';
          // },
          // axisPointer: {
          //   type: 'none'
          // }
        },
        color: ["#65A6E9", "#FA924D"],
        legend: {
          show: true,
          data: this.legedata,
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "7%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: false },
            // prettier-ignore
            data: this.xAxisData.map(function (str) {
            return str.replace(' ', '\n');
          }),
          },
        ],

        dataZoom: [
          {
            // 内置于坐标系中，使用户可以在坐标系上通过鼠标拖拽、鼠标滚轮、手指滑动（触屏上）来缩放或漫游坐标系
            type: "inside",

            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100, // handleIcon 手柄的 icon 形状，支持路径字符串
            handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "80%", //  控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
            handleStyle: {
              color: "pink",
              shadowBlur: 3, // shadowBlur图片阴影模糊值，shadowColor阴影的颜色
              shadowColor: "#E7EFFF",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
            show: true,
          },
        ],
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
          },
          min: "dataMin",
          max: "dataMax",
          axisLabel: {
            //这种做法就是在y轴的数据的值旁边拼接单位，貌似也挺方便的
            formatter: "{value}",
            // color:'##686868'
          },
        },

        series: this.eachrsseries,
      };

      myChart.setOption(option, true);

      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-date-editor {
  width: 370px !important;
}

/deep/.el-tabs__nav-scroll {
  padding: 0 !important;
}

.nexp {
  position: relative;

  .unit {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #9e9fa3;
    font-size: 12px;
  }

  .echart {
    width: 1100px;
    height: 380px;
    margin-right: 20px;
  }
}

.btrFrom {
  /deep/.el-input__suffix {
    right: 15px !important;
  }

  .particu {
    span {
      color: #7e7e7f;
      font-size: 18px;
      margin-right: 130px;
    }

    label {
      font-size: 12px;
    }
  }

  /deep/.el-tabs__nav .is-active {
    color: #fff !important;
    background-color: #1684fc !important;
    border: none !important;
  }

  .inquire {
    border-radius: 4px;
    background-color: rgba(22, 132, 252, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    padding: 8px 10px;
    margin-left: 10px;
    border: none;
  }

  .bndffic {
    /deep/.el-tabs__item {
      // width: 40px !important;
      // height: 20px !important;
    }
  }

  /deep/.el-tabs__nav {
    border: none;
    border-radius: 3px;
  }

  /deep/.el-tabs__nav .el-tabs__item {
    width: 80px;
    height: 30px;
    line-height: 30px;
    padding: 0 !important;
    color: #232323;
    border-color: #3695fc;
    text-align: center;
    border: 1px solid #eaeaea;
    // border: none;
  }

  /deep/.el-tabs__header {
    border: none;
    margin: 0;
  }

  // /deep/.el-select__caret {
  //   line-height: 50px;
  // }

  /deep/.el-input__inner {
    // width: 120px;
    // height: 25px !important;
    margin-right: 10px;
  }

  /deep/.el-form-item__label {
    font-size: 16px !important;
    font-weight: bold;
    text-align: left;
    width: 80px;
  }
}

/deep/.el-form-item {
  margin-bottom: 10px !important;
}
</style>
