<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <div class="flex just-bet">
          <h3 class="cz flex">
            <img src="@/assets/img/rzxz.png" alt="" srcset="" class="hedimg" />
            <span> 融合CDN流量包</span>
          </h3>
          <div>
            <el-checkbox v-model="isShow" @change="getPackageByMid"
              >显示已过期、备用的流量包</el-checkbox
            >
            <el-button type="primary" class="purcha" @click="Purchakage"
              >+ 购买流量包</el-button
            >
          </div>
        </div>

        <div class="">
          <div>
            <el-table
              :data="PackageByMidList"
              style="width: 100%"
              empty-text="未找到日志..."
            >
              <el-table-column
                prop="productName"
                label="资源包"
                width="200"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="ordenum"
                label="类型"
                width="200"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.orderType == 0 ? "赠送免费额度" : "购买额度" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="ordenum"
                label="使用情况"
                width="300"
                align="center"
              >
                <template slot-scope="scope">
                  <div>
                    <el-progress
                      :text-inside="true"
                      :stroke-width="20"
                      :percentage="
                        (scope.row.useCapacity / scope.row.capacity).toFixed(
                          2
                        ) * 100
                      "
                      :format="format(scope.row)"
                      text-color="#000000"
                    ></el-progress>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="createTime"
                label="生效时间"
                width="150"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="endTime"
                label="到期时间"
                width="150"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="ordenum" label="状态" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.status == 0
                      ? "待支付"
                      : scope.row.status == 1
                      ? "完成"
                      : scope.row.status == 2
                      ? "退款中"
                      : scope.row.status == 3
                      ? "退款完成"
                      : ""
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout=" sizes, prev, pager, next, jumper"
            :total="total"
            class="flex just-center mg-tp-23"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="dag">
      <p>
        *免费额度需要先通过<a
          @click="$router.push({ path: '/personter', query: { perhoew: '2' } })"
          >实名认证</a
        >才会生效
      </p>
      <p>
        *有可用流量包时，系统计费会优先抵扣流量包，超出部分在继续按照流量价格进行计费。
      </p>
      <p>*每天上午9:30左右对上一日流量消耗进行一次抵扣。</p>
    </div>
    <!-- //发票信息设置 -->

    <!-- //购买流量包 -->
    <div class="puffi">
      <el-drawer title="商品购买" :visible.sync="pufwer">
        <div class="dawer">
          <div class="flex al-start sp">
            <span class="cho">选择商品</span>
            <div class="list">
              <ul>
                <li
                  v-for="(item, index) in PackageList"
                  :key="item.id"
                  @click="pitcon(item, index)"
                >
                  <div :class="active == index ? 'pitcon commy' : 'commy'">
                    <div class="left">
                      <p>{{ item.resourcesName }}</p>
                    </div>
                    <div class="rgiht flex just-bet">
                      <div class="period">
                        <label
                          >有效期：自购买日起
                          <span class="f-bold">{{ item.validity }}个月</span
                          >内</label
                        >
                      </div>
                      <div class="flex al-baseline">
                        <span class="reaice"
                          ><label>￥</label>{{ item.marketPrice }}</span
                        >
                        <span class="repr"
                          ><label>￥</label>{{ item.productPrice }}</span
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <el-pagination
            @size-change="choSizeChange"
            @current-change="chohandleChange"
            :current-page="chotpage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sourlimit"
            layout=" sizes, prev, pager, next, jumper"
            :total="sourototal"
            class="flex just-center mg-tp-23"
          >
          </el-pagination>
          <div class="putity flex al-start">
            <span>购买数量</span>
            <el-input
              placeholder="请输入内容"
              v-model="purcqu"
              @change="purcquChange"
            >
              <template slot="append">个</template>
            </el-input>
          </div>
          <div class="explain">
            <label>说明</label>
            <span>
              划线价格不表示商品原价，它是表示如果不购买此商品，系统按量计费消耗同等资源预估产生的费用。
            </span>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="base flex">
          <div class="toces">
            <span>总价：</span>
          </div>
          <div class="Prls">
            <span><label class="unit">￥</label>{{ toprices }}</span>
          </div>
          <div class="butto">
            <el-button type="primary" class="cancel" @click="pufwer = false"
              >取消</el-button
            >
            <el-button type="primary" class="acknowle" @click="createOrder"
              >确认订单</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 流量包支付 -->
    <div class="trymentdialog">
      <el-dialog :visible.sync="dackVisible" width="80%">
        <div slot="title">
          <h5>核对和支付</h5>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="prme flex">
            <label>商品名称</label>
            <span>{{ OrderList.productName }}</span>
          </div>
          <div class="prme flex">
            <label>购买数量 </label>
            <span>{{ OrderList.total }} 个</span>
          </div>
          <div class="prme flex nepay">
            <label>需要支付 </label>
            <span class=""
              ><label class="unit">￥</label>{{ OrderList.payPrice }}</span
            >
          </div>
          <div class="prme flex">
            <label>支付方式 </label>
            <div class="maym">
              <ul class="flex">
                <li :class="suffioff ? 'active' : ''" @click="sitchinalance">
                  <span class="">{{ remaititle }} ￥{{ money }}</span>
                </li>
                <li :class="!suffioff ? 'active' : ''" @click="WeChatPay">
                  <span class="">微信支付</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="prme flex scpay al-baseline" v-show="!suffioff">
            <label>扫码支付 </label>
            <div class="flex-column al-baseline">
              <span class="">请使用微信客户端扫码二维码完成支付</span>
              <div id="qrcode" ref="qrcode" class="erwma mg-tp-10"></div>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-divider></el-divider>

          <el-button @click="dackVisible = false" class="cancel"
            >取 消</el-button
          >
          <el-button @click="confiyment" class="mg-rt-22" type="primary"
            >确认支付</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  components: { QRCode },
  props: {
    msg: String,
  },
  data() {
    return {
      isShow: false,
      pufwer: false,
      dackVisible: false,
      suffioff: false,
      remaititle: "余额",
      money: "",
      option: [],
      qrcode: "",
      userInfo: {},
      currentpage: 0,
      chotpage: 0,
      offset: 0,
      limit: 10,

      sourototal: 0,
      total: 0,
      souroffset: 0,
      sourlimit: 10,
      PackageByMidList: [],
      PackageList: [],
      OrderList: {},
      active: "0",
      toprices: "0",
      purcqu: "1",
      price: "",
      id: "",
      payID: "",
      percentage: "",
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getPackageByMid();
    this.member();
  },
  methods: {
    //重新获取用户信息
    member() {
      this.$apis
        .member({
          mid: this.userInfo.id,
        })
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        });
    },
    confiyment() {
      if (this.suffioff) {
        this.$apis
          .yuPayOrder({
            orderId: this.payID,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "支付成功",
              });
              this.pufwer = false;
              this.dackVisible = false;
              this.getPackageByMid();
            }
          });
      } else {
        this.$apis
          .getOrderById({
            orderId: this.payID,
          })
          .then((res) => {
            if (res.data.status == 0) {
              this.$message({
                type: "warning",
                message: "请扫码支付",
              });
            } else if (res.data.status == 1) {
              this.pufwer = false;
              this.dackVisible = false;
              this.getPackageByMid();
            }
          });
      }
    },
    WeChatPay() {
      this.suffioff = false;
      // this.wxPayOrder(this.payID)
    },
    Purchakage() {
      this.pufwer = true;
      this.getResourcePackageList();
    },
    sitchinalance() {
      if (
        this.money > this.OrderList.payPrice ||
        this.money == this.OrderList.payPrice
      ) {
        this.suffioff = true;
      } else {
        return;
      }
    },
    chohandleChange(val) {
      this.currentpage = val;
      this.souroffset = (val - 1) * this.sourlimit;
      this.getResourcePackageList();
    },
    choSizeChange(val) {
      this.sourlimit = val;
      this.getResourcePackageList();
    },
    wxPayOrder(id) {
      let that = this;
      this.$apis
        .wxPayOrder({
          orderId: id,
        })
        .then((res) => {
          that.codeurl = res.data.package.slice(9);
          const codeHtml = document.getElementById("qrcode");
          codeHtml.innerHTML = "";
          this.$nextTick(() => {
            let qrcode = new QRCode("qrcode", {
              width: 200,
              height: 180,
              text: that.codeurl, // 二维码地址
              colorDark: "#000",
              colorLight: "#fff",
            });
          });
        });
    },
    getOrderById(id) {
      this.$apis
        .getOrderById({
          orderId: id,
        })
        .then((res) => {
          this.OrderList = res.data;
          if (
            res.money > this.OrderList.payPrice ||
            res.money == this.OrderList.payPrice
          ) {
            this.suffioff = true;
            this.remaititle = "余额";
          } else {
            this.remaititle = "余额不足";
          }
          this.money = res.money;
          this.dackVisible = true;
          this.pufwer = false;
        });
    },
    createOrder() {
      this.$apis
        .createOrder({
          mid: this.userInfo.id,
          productType: 0,
          productId: this.id,
          num: this.purcqu,
        })
        .then((res) => {
          this.getOrderById(res.data);
          this.wxPayOrder(res.data);
          this.payID = res.data;
        });
    },
    purcquChange() {
      this.toprices = Number(
        this.purcqu * this.price * this.userInfo.resourcesRatio
      ).toFixed(2);
    },
    pitcon(data, index) {
      this.active = index;
      this.id = data.id;
      this.price = data.marketPrice;
      this.toprices = Number(
        this.purcqu * this.price * this.userInfo.resourcesRatio
      ).toFixed(2);
    },
    getResourcePackageList() {
      this.$apis
        .getResourcePackageList({
          offset: this.souroffset,
          limit: this.sourlimit,
          mid: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.rows.length > 0) {
            this.price = res.data.rows[0].marketPrice;
            this.id = res.data.rows[0].id;
          }
          this.toprices = Number(
            this.purcqu * this.price * res.resourcesRatio
          ).toFixed(2);
          this.PackageList = res.data.rows;
          this.sourototal = res.data.total;
        });
    },
    handleClose() {},
    handleCurrentChange(val) {
      this.currentpage = val;
      this.offset = (val - 1) * this.limit;
      this.getPackageByMid();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getPackageByMid();
    },
    format(data) {
      return () => {
        return `已使用${data.useCapacity}G/共${data.capacity}G`;
      };
    },
    getPackageByMid() {
      this.$apis
        .getPackageByMid({
          mid: this.userInfo.id,
          isShow: this.isShow ? 1 : 0,
          offset: this.offset,
          limit: this.limit,
        })
        .then((res) => {
          this.PackageByMidList = res.data.rows;
          this.total = res.data.total;
        });
    },
    handleAvatarSuccess() {},
    beforeAvatarUpload() {},
    handleChange(value) {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
.sp {
  overflow-y: scroll;
  height: 320px;
}
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007bff;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px;
  }

  .number {
    color: #007bff;
    border: 1px solid #dadbdb;
  }
}

.trymentdialog {
  /deep/.el-dialog__footer {
    padding: 0;

    .el-divider {
      margin-bottom: 10px;
    }

    .cancel {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      margin: 0 20px 20px 0;
      padding: 10px 15px;
    }
  }

  .nepay {
    span {
      color: #ed711f !important;
      font-size: 22px !important;
    }
  }

  .scpay {
    img {
      width: 128px;
      height: 128px;
      margin-top: 10px;
    }
  }

  .prme {
    font-size: 14px;
    color: #666666;
    margin-bottom: 20px;

    .maym {
      .active {
        border-color: #007bff !important;
        background: url("../../../assets/img/xzl.png") no-repeat top right;
        background-size: 20px 20px;
      }

      li {
        list-style: none;
        padding: 10px;
        border: 1px solid #e4e9f0;
        list-style: none;
        margin-right: 10px;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        display: inline-block;
      }
    }

    .unit {
      font-size: 14px !important;
    }

    label {
      flex: 0 0 16.666667%;
    }

    span {
      flex: 0 0 83.333333%;
    }
  }

  /deep/.el-dialog__body {
    padding: 0 20px 0 20px;
  }

  /deep/.el-dialog__header {
    .el-divider {
      margin: 20px 0 10px 0;
    }

    h5 {
      font-size: 15px !important;
      color: #333333;
      font-weight: 400 !important;
    }
  }
}

.puffi {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22px;

      .unit {
        font-size: 14px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {
    padding: 15px 15px 0px;
  }

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16px;
    }

    span {
      font-size: 13px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15px;
    color: #333333;
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
    padding: 15px;
    margin-bottom: 20px;
  }

  /deep/.el-drawer__body {
    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15px;
      color: #666666;
    }

    .list {
      width: 100%;

      ul {
        padding-right: 10px;
      }

      li {
        list-style: none;
      }
    }

    .pitcon {
      border-color: #007bff !important;
      background: url("../../../assets/img/xzl.png") no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22px;

          label {
            font-size: 14px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13px;
        }
      }

      .left {
        p {
          font-size: 20px;
          color: #666666;
        }
      }
    }
  }
}

/deep/.el-progress {
  // width: 230px !important;
  // margin-left: 20px;
}

.dag {
  margin-top: 18px;

  p {
    font-size: 14px;
    letter-spacing: 0;
    color: #666666;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}

.purcha {
  background: #4272d7;
  border: none;
  line-height: 0px;
  font-size: 14px;
  padding: 13px 15px;
  border-radius: 0;
  margin-left: 10px;
}

.selmain {
  margin: 20px 0 10px 0;

  /deep/.el-input__inner {
    height: 30px;
    // width: 300px;
  }

  span {
    font-size: 14px;
  }

  color: #666666;
}

.hedimg {
  width: 25px;
  height: 25px;
}

.deion {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 400;

  h4 {
    margin: 0;
  }
}

.alert {
  span {
    width: 56px;
    height: 50px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin-right: 54px;
  }
}

.appbton {
  padding: 0;
  width: 59px;
  height: 30px;
  line-height: 0;
  border-radius: 4px;
  background-color: rgba(95, 165, 242, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
}

.tabdelt {
  width: 32px;
  height: 34px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;

    h3 {
      margin: 0;
    }
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 800;
  }

  .has-gutter {
    color: #666666;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  // margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}
</style>
