<template>
  <div class="w-100 h-100">
   
    <div class="recar">
      <div class="flex just-bet cz">
      <h3 class=" flex">
        <img src="@/assets/img/tjifexwxz.png" alt="" srcset="" class="hedimg">
        <span> 统计分析</span>

      </h3>
    </div>

      <div class="reacrmain">
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="带宽流量" name="first">
      <btraffic></btraffic>
    </el-tab-pane>
    <el-tab-pane label="回源统计" name="second">
      <batisetting></batisetting>
    </el-tab-pane>
    <el-tab-pane label="请求数" name="third">
      <numbquests></numbquests>
    </el-tab-pane>
    <el-tab-pane label="命中率" name="mzl">
      <hitrate></hitrate>
    </el-tab-pane>
    <el-tab-pane label="状态码" name="fw">
      <statcode></statcode>
    </el-tab-pane>
    <el-tab-pane label="PV/UV" name="wj">
      <puvu></puvu>
    </el-tab-pane>
    <el-tab-pane label="热门URL" name="rm">
      <popularURL></popularURL>
    </el-tab-pane>
    <!-- <el-tab-pane label="热门URL(回源)" name="rmhy">
      <popembers></popembers>
    </el-tab-pane> -->
    <el-tab-pane label="热门Referer" name="Refer">
      <popularefer></popularefer>
    </el-tab-pane>
    <el-tab-pane label="TOP客户端IP" name="TOPkhd">
      <tOcliddress></tOcliddress>
    </el-tab-pane>
  </el-tabs>
      </div>
    </div>
   
  </div>
</template>

<script>
import btraffic from './statisysis/components/btraffic.vue'
import batisetting from './statisysis/components/batisetting.vue'
import numbquests from './statisysis/components/numbquests.vue'
import hitrate from './statisysis/components/hitrate.vue'
import statcode from './statisysis/components/statcode.vue'
import puvu from './statisysis/components/puvu.vue'
import popularURL from './statisysis/components/popularURL.vue'
import popembers from './statisysis/components/popembers.vue'
import popularefer from './statisysis/components/popularefer.vue'
import tOcliddress from './statisysis/components/tOcliddress.vue'
export default {

  props: {
    msg: String
  },
  components:{
    btraffic,
    batisetting,
    numbquests,
    hitrate,
    statcode,
    puvu,
    popularURL,
    popembers,
    popularefer,
    tOcliddress
  },
  data() {
    return {
    
      activeName:"first",
    }
  },
  mounted() {
  },
  methods: {
    handleClick() {

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.eForm {
  margin-top: 20px;
  .port {
    span {
      margin-right: 7px;
    }
  }
  .hps{
    margin-left: 80px;
  }
  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }
  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }
  .el-form-item__content {
    margin-left: 80px ;
  }
  .el-form-item__label {
    text-align: left;
    width: 80px;
  }
  a {
    color: #4249F1 ;
  }
 

  .el-radio-group {
    display: flex;
    align-items: center;
    margin-top: 13px;

    .el-radio__label {
      font-size: 12Px;
    }

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    // margin-top: 5px;
  }

  .el-input__inner {
    width: 300px;
  }
}

.cz {
  padding: 0 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #333;
  }

  span {
    margin-left: 10px;
  }
}


.hedimg {
  width: 25px;
  height: 25px;
}

.recar {
  width: 100%;
  background-color: #fff;
  /deep/.el-tabs__item {
    color: #A5A5A5;
  }
  /deep/.is-active {
    color: #3695FC ;
   
  }
  /deep/.el-tabs__active-bar {
    background-color: #3393FC ;
    height: 2px;
  }
  /deep/.el-tabs__nav-scroll {
    // padding-left: 10px;
  }
  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }

}

/deep/.el-table thead {
  color: #666666;
  font-weight: bold;
  font-size: 20px !important;
}

/deep/.el-table__body-wrapper {
  .tabthre {
    width: 15px;
    height: 15px;
  }

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

/deep/.el-input__inner {

  width: 200px;
  color: rgba(16, 16, 16, 1);
  font-size: 12px;
  border: 1px solid rgba(187, 187, 187, 1);
}


</style>
