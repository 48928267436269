<template>
  <div class="w-100 h-100">



    <div class="enterBox ">
      <div class="main draggable-block">
        <div class="heard flex just-bet">
          <div clang="hel">
            <img src="../../assets//img/logo.png" alt="" srcset="" class="logo">
          </div>
          <div class="hedr curpoin">
            <div class="flex mg-lt-21">
              <img src="../../assets/img/wd.png" alt="" srcset="" class="costimg">
              <a href="http://cloud.znidc.net/" target="_blank"> <span>文档</span> </a>
             
            </div>
            <div class="flex mg-lt-21" @click="$router.push('/expensnter')">
              <img src="../../assets/img/fyong.png" alt="" srcset="" class="costimg">
              <span>费用</span>
            </div>
            <div class="flex mg-lt-21" @click="$router.push('/personter?perhoew=3')">
              <img src="../../assets/img/xiaoxi.png" alt="" srcset="" class="costimg">
              <span>消息</span>
            </div>
            <div class="flex mg-lt-21 telne">
              <img src="../../assets/img/yonghu.png" alt="" srcset="" class="costimg">
              <span>{{ Tgmber }}****{{ Laomber }}</span>
            </div>
            <div class="">
              <el-dropdown @visible-change="member" @command="ropdcomma">
                <img src="../../assets/img/xiala.png" alt="" srcset="" class="costimg">
                <el-dropdown-menu slot="dropdown" class="personame " :divided="true">
                  <div class="flex mg-bot-10 dritem">
                    <div class="shm">
                      <img src="../../assets/img/shim.png" alt="" srcset="">
                    </div>
                    <div class="flex-column mg-lt-10 font-14 al-start">
                      <span class="color-10 ">{{ userInfo.nickname }}</span>
                      <span class="color-93 ">{{ Tgmber }}****{{ Laomber }}</span>
                      <span class="color-93">余额：{{ userInfo.money }}</span>
                    </div>
                  </div>
                  <el-divider></el-divider>
                  <el-dropdown-item command="1" @click="persCen">
                    <div class="flex dritem">
                      <img src="../../assets/img/grzx.png" alt="" srcset="" class="imgs">
                      <span class="mg-lt-10">个人中心</span>

                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item command="2" @click="goExpen">
                    <div class="flex dritem">
                      <img src="../../assets/img/st-coinCopy 2@3x.png" alt="" srcset="" class="imgs">
                      <span class="mg-lt-10">费用中心</span>

                    </div>
                  </el-dropdown-item>
                  <el-divider></el-divider>
                  <el-dropdown-item command="3" @click="logout">
                    <div class="flex dritem">
                      <img src="../../assets/img/tcdl.png" alt="" srcset="" class="imgs">
                      <span class="mg-lt-10">退出登录</span>

                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>

              </el-dropdown>


            </div>

          </div>
        </div>
        <div class="enrbody">

         
          <div >
            <div class="welco">
            <span class="">欢迎来到融合CDN，{{ Tgmber }}****{{ Laomber }}</span>
          </div>
            <div class="flex">
              <div class="maiart">

                <div class="prodvice">
                  <p class="cp">产品和服务</p>
                  <div class="prbox flex ">
                    <div class="flex proditem curpoin" @click="goSlertif">
                      <img src="../../assets/img/zhes.png" alt="" srcset="">
                      <div class="flex-column certi al-baseline just-bet">
                        <p>SSL证书</p>
                        <p class="wgm">{{ homdate.totalSslNum == 0 ? '未购买证书' : homdate.totalSslNum }}</p>
                      </div>
                    </div>
                    <div class="flex proditem curpoin" @click="goFusecdn">
                      <img src="../../assets/img/ruhe.png" alt="" srcset="">
                      <div class="flex-column  certi al-baseline just-bet">
                        <p>融合CDN</p>
                        <p class="wgm">昨日流量 {{ homdate.yesterdayFlow }}</p>
                      </div>
                    </div>
                    <div class="flex proditem curpoin" @click="persCen">
                      <img src="../../assets/img/user.png" alt="" srcset="">
                      <div class="flex-column  certi al-baseline just-bet">
                        <div>
                          <p>个人中心</p>
                        </div>

                        <p class="wgm" v-if="userInfo.isReal == 0">未实名</p>
                        <p class="wgm" v-else>已实名</p>
                      </div>
                    </div>
                    <div class="flex proditem">
                      <img src="../../assets/img/fyzx.png" alt="" srcset="">
                      <div class="flex-column al-baseline certi curpoin just-bet" @click="goExpen">
                        <p>费用中心</p>
                        <p class="wgm">本月消费 {{ homdate.monthMoney }}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="autonym ">
                <div class="flex al-start aubox">
                  <div>
                    <img src="../../assets/img/shim.png" alt="" srcset="">
                  </div>
                  <div class="rt">
                    <div class="flex sm">
                      <span class="font-14">{{ Tgmber }}****{{ Laomber }}</span>
                      <span class="nnamed" v-if="userInfo.isReal == 0">未实名</span>
                      <span class="nnamed" v-else>已实名</span>
                    </div>
                    <div>
                      <span class="font-12">登录账号 {{ Tgmber }}****{{ Laomber }} </span>
                      <span class="font-12">Uid {{ userInfo.id }}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="flex midd">
            <div class="costren">
              <div class="costmain">
                <div class="fyqs flex just-bet">
                  <span class="bt">费用趋势</span>
                  <span class="gd curpoin" @click="moreData">更多数据</span>
                </div>
                <div class="nexp">
                  <!-- <span> 你暂时还没产生费用~</span> -->
                  <div class="echart" id="mychart"></div>
                </div>
              </div>
            </div>

            <div class="costrenetai">
              <div class="costmain">
                <div class="fyqs flex just-bet">
                  <span class="bt f-bold">资源包</span>
                </div>
                <div class="inbox">
                  <div class="inetai flex">
                    <div class="flex initem">
                      <img src="../../assets/img/fyrh.png" alt="" srcset="">
                      <span class="font-12">融合CDN</span>
                    </div>
                    <div class="numb">
                      <span>流量包{{ homdate.cdnNum }}个,剩余{{ homdate.residueFlow }}G</span>
                    </div>
                  </div>
                  <div class="inetai flex">
                    <div class="flex initem">
                      <img src="../../assets/img/fyzs.png" alt="" srcset="">
                      <span class="font-12">SLL证书</span>
                    </div>
                    <div class="numb">
                      <span>未过期数{{ homdate.sslNum }}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="reminder">
              <div class="reinmain">
                <div class="rein">
                  <span class="bt f-bold">提醒事项</span>
                </div>
                <div class="remain">
                  <div class="flex just-bet">
                    <div class="flex">
                      <div>
                        <img src="../../assets/img/Rectwrenz.png" alt="" srcset="" class="line"
                          v-if="userInfo.isReal == 0">
                        <img src="../../assets/img/rels.png" alt="" srcset="" class="line" v-else>

                      </div>
                      <div class="marwor">
                        <p v-if="userInfo.isReal == 0">未实名认证</p>
                        <p v-else>已实名认证</p>
                        <label v-if="userInfo.isReal == 0">您尚未实名认证,部分服务不可用、无法使用免费资源包</label>
                      </div>
                    </div>

                    <div class="ck flex wte-nrap" v-if="userInfo.isReal == 0">
                      <a @click="perJump(2)">查看</a>
                      <img src="../../assets/img/xyib.png" alt="" srcset="" class="xyb">
                    </div>
                    <div class="ck flex" v-else>
                      <!-- <a href="">查看</a> -->
                      <img src="../../assets/img/zehngq.png" alt="" srcset="" class="zhengq">
                    </div>
                  </div>
                  <el-divider margin="0 "></el-divider>
                  <div class="flex just-bet">
                    <div class="flex">
                      <div>
                        <img src="../../assets/img/rels.png" alt="" srcset="" class="line" v-if="!homdate.moneyWarning">
                        <img src="../../assets/img/Rectwrenz.png" alt="" srcset="" class="line"
                          v-if="homdate.moneyWarning">
                      </div>
                      <div class="marwor">
                        <p>安全警告</p>
                        <label v-if="!homdate.moneyWarning">目前没有安全告警信息</label>
                        <label v-if="homdate.moneyWarning">低于设置的余额预警值{{ userInfo.moneyWarning }}</label>
                      </div>
                    </div>
                    <div class="ck flex wte-nrap" v-if="homdate.moneyWarning">
                      <a @click="moreData()">查看</a>
                      <img src="../../assets/img/xyib.png" alt="" srcset="" class="xyb">
                    </div>
                    <div class="ck flex" v-if="!homdate.moneyWarning">
                      <!-- <a href="">查看</a> -->
                      <img src="../../assets/img/zehngq.png" alt="" srcset="" class="zhengq">
                    </div>
                  </div>
                  <el-divider margin="0 "></el-divider>
                  <div class="flex just-bet">
                    <div class="flex ">
                      <div>
                        <img src="../../assets/img/rels.png" alt="" srcset="" class="line" v-if="homdate.countNoPay == 0">
                        <img src="../../assets/img/Rectwrenz.png" alt="" srcset="" class="line" v-else>

                      </div>
                      <div class="marwor" v-if="homdate.countNoPay == 0">
                        <p>待支付订单</p>
                        <label>你没有待支付订单</label>
                      </div>
                      <div class="marwor" v-else>
                        <p>待支付订单</p>
                        <label>当前有{{ homdate.countNoPay }} 个</label>
                      </div>
                    </div>

                    <div class="ck flex" v-if="homdate.countNoPay == 0">
                      <img src="../../assets/img/zehngq.png" alt="" srcset="" class="zhengq">
                    </div>
                    <div class="ck flex wte-nrap" v-else>
                      <a @click="Ordpai(3, 0)">查看</a>
                      <img src="../../assets/img/xyib.png" alt="" srcset="" class="xyb">
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="flex">
            <div class="uicess">
              <div class="uicemain">
                <div class="cp">
                  <p>快捷入口</p>
                </div>
                <div class="flex skbox">
                  <div class="flex">
                    <img src="../../assets/img/Sllzs.png" alt="" srcset="">
                    <span class="font-12">SSL证书</span>
                  </div>

                  <div class="skip font-12 wte-nrap" style="width: 215px;">
                    <a @click="goSlertif" class="curpoin">购买证书</a>
                    <a class="curpoin" @click="goSlertif">下载证书</a>
                    <a class="curpoin"></a>
                    <a class="curpoin"></a>
                  </div>
                </div>
                <div class="flex skbox ">
                  <div class="flex">
                    <img src="../../assets/img/fyrh.png" alt="" srcset="">
                    <span class="font-12">融合CDN</span>
                  </div>

                  <div class="skip overvie font-12 wte-nrap curpoin">
                    <a @click="cdnJump(1)">概览</a>
                    <a @click="cdnJump(2)">域名管理</a>
                    <a @click="cdnJump(3)">证书管理</a>
                    <a @click="cdnJump(4)">刷新预热</a>
                    <a @click="cdnJump(5)">统计分析</a>
                    <a @click="cdnJump(6)">日志下载</a>
                    <a @click="cdnJump(7)">资源包管理</a>
                  </div>
                </div>
                <div class="flex skbox">
                  <div class="flex">
                    <img src="../../assets/img/userzx.png" alt="" srcset="">
                    <div>
                      <span class="font-12">个人中心</span>
                    </div>

                  </div>

                  <div class="skip accofor font-12 wte-nrap curpoin">
                    <a @click="perJump(1)">账号信息</a>
                    <a @click="perJump(2)">实名认证</a>
                    <a @click="perJump(3)">消息公告</a>
                    <a @click="perJump(4)">订阅通知</a>
                    <a @click="perJump(5)">重置密码</a>
                  </div>
                </div>
                <div class="flex skbox">
                  <div class="flex">
                    <img src="../../assets/img/fsy.png" alt="" srcset="">
                    <span class="font-12">费用中心</span>
                  </div>

                  <div class="skip expene font-12 wte-nrap curpoin">
                    <a @click="expenJum(1)">概览</a>
                    <a @click="expenJum(5)">财务管理</a>
                    <a @click="expenJum(3)">订单管理</a>
                    <a @click="expenJum(4)">发票管理</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="ewnnounce">
              <div class="ewnnmain">
                <div class="cp">
                  <p>消息公告</p>
                </div>
                <div class="tibox">
                  <div class="font-12 Timy flex" v-for=" item in NoticeRedList" :key="item.id">
                    <span class="sj">{{ item.createTime.split(' ')[0] }}</span>
                    <span class="hd ">{{ item.content }}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog title="欢迎来到融合CDN" :visible.sync="isPop" width="30%" class="welec" :show-close="false">
        <div>
          <p>您好，{{ Tgmber }}****{{ Laomber }}</p>
          <p> 这里是融合CDN 。在你正式开始使用我们的产品之前，我们建议你先完成下面
            这些事情。</p>
          <p> 1.实名认证，未实名认证的用户没有免费测试额度，为了避免出现刚开始测试就
            欠费的情况，建议你先<span style="color: #1977FF;">实名认证</span>。请放心，这只是为了满足法律要求，不会泄露任何
            隐私，我们支持支付宝快速验证，不需要上传任何隐私资料（查看我们的 <a href="https://upload.znidc.net/files/ysxy.html" target="_blank"><span
                style="color: #1977FF;">隐私政策</span></a>）。
          </p>
          <p>
            2.关联微信，为了不错过重要的提醒消息，你可以在 用户中心 关联我们的微信服
            务号。</p>
          <p> 3.免费体验，完成实名认证后，可以获得一定的免费额度，你可以体验下我们的
            各个产品，或者进行开发测试等。</p>
          <p> 4.联系我们，如果需要获得解决方案，或者有任何疑问，欢迎电话联系我们：
            0731-********（9:00 - 18:00）
          </p>
        </div>
        <div class="logo">
          <img src="../../assets/img/logo.png" alt="" srcset="">
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="close">关闭</el-button>
        </span>
      </el-dialog>
      <!-- //忘记密码end -->
      <!-- //购买资源包 -->
      <div class="puffi">
        <el-drawer title="商品购买" :visible.sync="pufwer">
          <div class="dawer">
            <div class="flex al-start">
              <span class="cho">选择商品</span>
              <div class="list">
                <ul>
                  <li>
                    <div class="commy pitcon">
                      <div class="left">
                        <p>CDN 200 GB 流量</p>

                      </div>
                      <div class="rgiht flex just-bet">
                        <div class="period ">
                          <label>有效期：自购买日起 <span class="f-bold">12个月</span>内</label>
                        </div>
                        <div class="flex al-baseline">
                          <span class="reaice"><label>￥</label>21</span>
                          <span class="repr"><label>￥</label>22</span>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="commy ">
                      <div class="left">
                        <p>CDN 200 GB 流量</p>

                      </div>
                      <div class="rgiht flex just-bet">
                        <div class="period ">
                          <label>有效期：自购买日起 <span class="f-bold">12个月</span>内</label>
                        </div>
                        <div class="flex al-baseline">
                          <span class="reaice"><label>￥</label>21</span>
                          <span class="repr"><label>￥</label>22</span>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="commy ">
                      <div class="left">
                        <p>CDN 200 GB 流量</p>

                      </div>
                      <div class="rgiht flex just-bet">
                        <div class="period ">
                          <label>有效期：自购买日起 <span class="f-bold">12个月</span>内</label>
                        </div>
                        <div class="flex al-baseline">
                          <span class="reaice"><label>￥</label>21</span>
                          <span class="repr"><label>￥</label>22</span>
                        </div>

                      </div>
                    </div>
                  </li>
                </ul>


              </div>

            </div>
            <div class="putity flex al-start">
              <span>购买数量</span>
              <el-input placeholder="请输入内容" v-model="input2">
                <template slot="append">个</template>
              </el-input>
            </div>
            <div class="explain">
              <label>说明</label>
              <span>
                划线价格不表示商品原价，它是表示如果不购买此商品，系统按量计费消耗同等资源预估产生的费用。
              </span>
            </div>

          </div>
          <el-divider></el-divider>
          <div class="base flex ">
            <div class="toces">
              <span>总价：</span>
            </div>
            <div class="Prls">
              <span><label class="unit">￥</label>21.00</span>
            </div>
            <div class="butto">
              <el-button type="primary" class="cancel" @click="pufwer = false">取消</el-button>
              <el-button type="primary" class="acknowle" @click="confiOrder">确认订单</el-button>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>

  </div>
</template>

<script>

import * as echarts from "echarts";
export default {

  props: {
    msg: String
  },
  data() {
    return {
      pufwer: false,
      isPop: false,

      homdate: {},
      userInfo: {},
      NoticeRedList: [],
      input2: "",
      Tgmber: "",
      Laomber: "",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.Tgmber = this.userInfo.mobile.slice(0, 3)
    this.Laomber = this.userInfo.mobile.slice(7, 11)
    this.isPop = this.userInfo.isPop == 0 ? true : false

    this.homeProductService()
    this.getNoticeRedList()
  },
  methods: {
    ropdcomma(value) {
      if (value == "1") {
        this.$router.push('/personter')
      } else if (value == "2") {
        this.$router.push('/expensnter')
      } else if (value == 3) {
        this.logout()
      }
    },
    logout() {
      this.$apis.logout({
        mid: this.userInfo.id
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push('/register')
        }
      })
    },
    confiOrder() {

    },
    getNoticeRedList() {
      this.$apis.getNoticeRedList({
        mid: this.userInfo.id,
        offset: 0,
        limit: 5,
      }).then((res) => {
        this.NoticeRedList = res.data.rows
      })
    },
    //首页
    homeProductService() {
      this.$apis.homeProductService({
        mid: this.userInfo.id
      }).then((res) => {
        this.homdate = res
        this.homdate.yesterdayFlow = this.homdate.yesterdayFlow<1000?this.homdate.yesterdayFlow+'B':this.homdate.yesterdayFlow>1000&&this.homdate.yesterdayFlow<100000?
        (this.homdate.yesterdayFlow/1000).toFixed(2)+'KB':this.homdate.yesterdayFlow>100000&&this.homdate.yesterdayFlow<1000000000?
        (this.homdate.yesterdayFlow/1000/1000).toFixed(2)+'MB':this.homdate.yesterdayFlow>1000000000?(this.homdate.yesterdayFlow/1000/1000/1000).toFixed(2)+'GB':""
        this.$nextTick(()=>{
          this.initEcharts()
        })
      
      })
    },
    //重新获取用户信息
    member() {
      this.$apis.member({
        mid: this.userInfo.id
      }).then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      })
    },
    close() {
      this.$apis.closePop({
        mid: this.userInfo.id
      }).then(() => {
        this.isPop = false
        this.member()
      })
    },
    moreData() {
      this.$router.push('/expensnter')
    },
    //待支付跳管理首页
    Ordpai(data, index) {
      this.$router.push({ path: '/expensnter', query: { expenhoew: data, status: index } })
    },
    expenJum(data) {
      this.$router.push({ path: '/expensnter', query: { expenhoew: data } })
    },
    perJump(data) {
      this.$router.push({ path: '/personter', query: { perhoew: data } })
    },
    //带参数跳转
    cdnJump(data) {
      this.$router.push({ path: '/fusecdn', query: { cdnhoew: data } })
    },
    goFusecdn() {
      this.$router.push('/fusecdn')
    },
    goSlertif() {
      this.$router.push('/sslertificate')
    },
    initEcharts() {
      // 基本柱状图
      const option = {
        xAxis: {
          data: this.homdate.countMothMoney.month,
          axisLabel: {
            color: '##686868'
          },
        },
        yAxis: {
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
          axisLabel: {
            //这种做法就是在y轴的数据的值旁边拼接单位，貌似也挺方便的
            formatter: "{value} 元",
            color: '##686868'
          },
        },
        // legend: {
        //   itemWidth: 24,    //图例的宽
        //   itemHeight: 15,   //图例的高
        //   show: true,  //是否显示图例
        //   // left: "8%",  //图例距离左方位置
        //   bottom: "2%",   //图例距离上方位置
        // },
        series: [
          {
            type: "bar", //形状为柱状图
            data: this.homdate.countMothMoney.moneyList,
            // name: "收入", // legend属性
            // barCategoryGap: '10%',
            // 调整同一系列中柱状图之间的间隔
            // barGap: 100,
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: true,
              position: "top",
              formatter: '{c}元',
              color: '#6293ED'
            },
            itemStyle: {
              color: '#5087EC'
            },
            // barGap:'0%',

          },

          // {
          //   type: "bar", //形状为柱状图
          //   data: this.data2,
          //   // barGap: 100,
          //   // barCategoryGap: '70%',
          //   name: "支出", // legend属性
          //   itemStyle: {
          //     color: '#68BBC4'
          //   },
          //   label: {
          //     // 柱状图上方文本标签，默认展示数值信息
          //     show: true,
          //     // position: "top",
          //     position: ['-10%', '-80%'],
          //     formatter: '{c}k'
          //   }
          // }
        ]
      };
      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    persCen() {
      this.$router.push('/personter')
    },
    goExpen() {
      this.$router.push('/expensnter')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.personame {
  width: 220px;

  //  padding: 0 10px;
  .dritem {
    padding: 0 10px;
  }

  .el-menu-item {
    padding: 0;
  }

  .imgs {
    width: 24px;
    height: 24px;
  }

  .el-divider {
    margin: 10px 0;
  }

  .shm {

    img {
      width: 50px;
      height: 50px;
    }
  }
}

a {
  text-decoration: none;
}

.welec {
  font-size: 16px;

  p {
    margin-bottom: 10px;
  }

  /deep/.el-dialog__body {
    padding-top: 21px;
    padding-bottom: 27px;
  }

  /deep/.el-dialog__header {
    padding-bottom: 0;
  }

  /deep/.el-dialog__footer {
    padding-top: 0;
  }
}

.echart {
  width: 520px;
  height: 240px;
  margin-right: 20px;
}

.enterBox {
  width: 100%;
  height: 100%;
}

.draggable-block {
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  transition: 0.3s;
}

.main {
  width: 100%;
  height: 100%;
  background-color: #E5E5E5;
   
  .enrbody {
    width: 100%;
    // height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 39px;
    // margin: 39px 0 156px 0;
    padding: 39px 0 156px 0;
    background-color: #E5E5E5;
    // justify-content: center;
  }

  .welco {
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: left;
    margin: 0 0 21px 0;
  }

  .el-header {

    span {
      margin-left: 10px;
    }

  }

  .ewnnounce {
    width: 359px;
    height: 279px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    .ewnnmain {
      padding: 12px 0 0 22px;

      .tibox {
        margin-top: 20px;
      }

      p {
        margin: 0;
      }

      .Timy {
        margin-bottom: 10px;
      }

      .sj {
        color: #CBCCCD;
      }

      .hd {
        color: #9FA3A8;
        margin-left: 10px;
        white-space: nowrap;
        width: 65%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .cp {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        font-weight: bold;
        // padding: 12px 0 0 22px;
      }
    }
  }

  .uicess {
    width: 818px;
    height: 279px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-right: 19px;

    .uicemain {
      .skbox {
        padding: 30px 0 0 33px;

        .overvie {
          width: 527px;
          margin-left: 42px;
        }

        .accofor {
          width: 298px;
          margin-left: 46px;
        }

        .expene {
          width: 272px;
          margin-left: 48px;
        }
      }

      p {
        margin: 0;
      }

      .cp {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        font-weight: bold;
        padding: 12px 0 0 22px;
      }

      img {
        width: 22px;
        height: 22px;
      }

      .skip {
        margin-left: 49px;
        display: flex;
        justify-content: space-between;

      }

      span {
        color: #686868;
        margin-left: 6px;
      }

      a {
        text-decoration: none;
        color: #1977FF !important;
        margin-right: 20px;
      }
    }
  }

  .midd {
    margin: 18px 0 16px 0;
  }

  .reminder {
    width: 359px;
    height: 279px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    /deep/.el-divider {
      margin: 10px 0;
    }

    .remain {
      margin-top: 20px;
    }

    .marwor {
      margin-left: 10px;

      p {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      label {
        color: #aaa;
        font-size: 12Px;
        display: block;
        margin-bottom: 9px;
      }

    }

    .ck {
      font-size: 13px;
      margin-left: 40px;

      .zhengq {
        width: 15px;
        height: 15px;
      }

      .xyb {
        width: 15px;
        height: 10px;
        margin-left: 6px;
      }

      a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
      }
    }

    .line {
      width: 4px;
      height: 40px;
    }

    .reinmain {
      padding: 11px 25px 0 22px;

      .rein {
        span {
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
        }
      }
    }
  }

  .costrenetai {
    width: 286px;
    height: 279px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin: 0 19px 0 15px;

    .costmain {
      padding: 11px 25px 0 22px;

      .bt {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        font-weight: bold;
      }

      .inbox {
        margin-top: 32px;

        .inetai {
          margin-bottom: 40px;

          .numb {
            color: rgba(104, 104, 104, 1);
            font-size: 12px;
            margin-left: 20px;
          }

          .initem {
            img {
              width: 22px;
              height: 22px;
            }

            span {
              color: rgba(104, 104, 104, 1);
              margin-left: 7px;
            }
          }
        }
      }

    }
  }

  .costren {
    width: 513px;
    height: 279px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    .costmain {
      padding: 11px 25px 0 22px;

      .inetai {
        .numb {
          color: rgba(104, 104, 104, 1);
          font-size: 12px;
        }

        .initem {
          img {
            width: 22px;
            height: 22px;
          }

          span {
            color: rgba(104, 104, 104, 1);
            margin-left: 7px;
          }
        }
      }

      .nexp {

        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        // margin-top: 88px;
      }
    }

    .fyqs {
      .bt {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        font-weight: bold;
      }

      .gd {
        color: rgba(25, 119, 255, 1);
        font-size: 12px;
        text-align: left;
        font-weight: bold;
      }
    }
  }

  .maiart {
    margin-right: 19px;
  }

  .autonym {
    width: 359px;
    height: 144px;
    line-height: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    .aubox {
      padding: 41px 0 0 22px;
    }

    img {
      width: 57px;
      height: 57px;
    }

    span {
      color: #101010;
    }

    .nnamed {
      width: 72px;
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      background-color: rgba(251, 112, 97, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      display: block;
      text-align: center;
      margin-left: 18px;
    }

    .sm {
      margin-bottom: 6px;
    }
  }

  .prodvice {
    width: 818px;
    height: 144px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    .cp {
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-weight: bold;
      padding: 12px 0 0 22px;
    }

    .prbox {
      margin-top: 19px;
      margin-left: 46px;

      .proditem {
        margin-right: 56px;
        align-items: initial;

        img {
          width: 41px;
          height: 41px;
        }

        .certi {
          margin-left: 10px;

        }
      }
    }

    .wgm {
      color: rgba(104, 104, 104, 1);
      font-size: 14px;
    }

    p {
      margin: 0;
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
    }

  }

  .heard {
    height: 6%;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(187, 187, 187, 1);
    padding: 0 28px 0 18px;

    .logo {
      width: 126Px;
      height: 28Px;
    }

    .costimg {
      width: 20px;
      height: 20px;
    }

    .hedr {
      display: flex;

      align-items: center;

      .telne {
        margin-left: 6px;
      }

      span {
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}

/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);

  p {
    color: #101010;
    font-size: 16px;
  }

  .el-dialog__title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
  }

  .logo {
    text-align: right;
    margin-top: 40px;

    img {
      width: 235px;
      height: 52px;
    }

  }

}

/deep/.el-button--default {
  width: 85px;
  height: 33px;
  border-radius: 4px;
  background-color: rgba(164, 173, 179, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
  font-family: Microsoft Yahei;
  line-height: 0;
}

.puffi {
  .base {
    justify-content: flex-end;
    padding: 15px;

    .toces {
      font-size: 13Px;
      color: #666666;
    }

    .Prls {
      color: #ed711f;
      font-size: 22Px;

      .unit {
        font-size: 14Px !important;
      }
    }

    .butto {
      margin-left: 20px;

      .cancel {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        padding: 10px 20px;
      }

      .acknowle {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 10px 20px;
      }
    }
  }

  .dawer {
    padding: 15px 15px 0px;
  }

  .explain {
    margin-top: 20px;

    label {
      color: #666666;
      font-size: 16Px;
    }

    span {
      font-size: 13Px;
      color: #666666;
      margin-left: 55px;
    }
  }

  .putity {
    color: #666666;
    font-size: 16Px;
    margin-top: 20px;

    /deep/.el-input {
      width: 17%;
      margin-left: 30px;
    }

    /deep/.el-input__inner {
      width: 80px;
    }
  }

  /deep/.el-drawer {
    width: 747px !important;
  }

  /deep/.el-drawer__header {
    font-size: 15Px;
    color: #333333;
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
    padding: 15px;
    margin-bottom: 20px;
  }

  /deep/.el-drawer__body {


    .cho {
      width: 100px;
      padding: 0 15px 0 0;
      font-size: 15Px;
      color: #666666;
    }

    .list {
      width: 100%;

      ul {
        padding-right: 10px;
        height: 620Px;
        overflow-y: scroll;
      }

      li {
        list-style: none;
      }
    }

    .pitcon {
      border-color: #007bff !important;
      background: url('../../assets/img/xzl.png') no-repeat top right;
      background-size: 20px 20px;
    }

    .commy {
      // height: 80px;
      padding: 15px;
      border: 1px solid #e4e9f0;
      list-style: none;
      margin-bottom: 10px;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      border-color: #e4e9f0;

      .rgiht {
        margin-top: 2px;

        .repr {
          font-size: 12px;
          color: #999;
          text-decoration: line-through;
        }

        .reaice {
          color: #ed711f;
          font-size: 22Px;

          label {
            font-size: 14Px !important;
          }
        }

        .period {
          color: #8d99a6;
          font-size: 13Px;

        }
      }

      .left {
        p {
          font-size: 20Px;
          color: #666666;
        }
      }
    }
  }
}</style>
