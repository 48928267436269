<template>
  <div class="w-100 h-100">
    <el-container class="w-100 h-100">
      <el-aside>
        <el-menu :default-openeds="['2', '3', '5']" @select="handleSelect" :default-active="defaulct">
          <div class="flex font-14 hogo">
            <img src="../../assets/img/logo.png" alt="" srcset="" class="lgo">
           

          </div>
          <el-menu-item index="9">
            <div @click="goHomg" class="curpoin">
              <span>首页</span>
            </div>

          </el-menu-item>
          <el-menu-item index="1">
            <div class="flex">
              <i class="image-wrapper"></i>
              <span slot="title">概览</span>
            </div>

          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <div class="flex">
                <i class="image-smrz"></i>
                <span slot="title">财务管理</span>
              </div>
            </template>
            <el-menu-item-group>
              <el-menu-item index="5">充值</el-menu-item>
              <el-menu-item index="6">收支明细</el-menu-item>
              <el-menu-item index="7">费用账单</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="3">
            <div class="flex">
              <i class="image-xxgg"></i>
              <span slot="title">订单管理</span>
            </div>

          </el-menu-item>
          <el-menu-item index="4">
            <div class="flex">
              <i class="image-dltz"></i>
              <span slot="title">发票管理</span>
            </div>

          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header class="flex just-bet">
          <div class="flex">

            <div class="flex sl curpoin" @click="$router.push('/sslertificate')">
              <img src="../../assets/img/SSLgezs.png" alt="" srcset="" class="heimg">
              <span>SSL证书</span>
            </div>
            <div class="flex curpoin" @click="$router.push('/fusecdn')">
              <img src="../../assets/img/ruhe.png" alt="" srcset="" class="heimg">
              <span>融合CDN</span>
            </div>
          </div>

          <div class="hedr curpoin">
            <div class="flex mg-lt-21 curpoin">
              <img src="../../assets/img/wd.png" alt="" srcset="" class="costimg">
              <a href="http://cloud.znidc.net/" target="_blank"> <span>文档</span> </a>
            </div>
            <div class="flex mg-lt-21" @click="$router.push('/expensnter')">
              <img src="../../assets/img/fyong.png" alt="" srcset="" class="costimg">
              <span>费用</span>
            </div>
            <div class="flex mg-lt-21" @click="$router.push('/personter?perhoew=3')">
              <img src="../../assets/img/xiaoxi.png" alt="" srcset="" class="costimg">
              <span>消息</span>
            </div>
            <div class="flex mg-lt-21 telne">
              <img src="../../assets/img/yonghu.png" alt="" srcset="" class="costimg">
              <span>{{ Tgmber }}****{{ Laomber }}</span>
            </div>
            <div class="">
              <el-dropdown @visible-change="member" @command="ropdcomma">
                <img src="../../assets/img/xiala.png" alt="" srcset="" class="costimg mg-tp-30">
                <el-dropdown-menu slot="dropdown" class="personame " :divided="true">
                  <div class="flex mg-bot-10 dritem">
                    <div class="shm">
                      <img src="../../assets/img/shim.png" alt="" srcset="">
                    </div>
                    <div class="flex-column mg-lt-10 font-14 al-start">
                      <span class="color-10 ">{{ userInfo.nickname }}</span>
                      <span class="color-93 ">{{ Tgmber }}****{{ Laomber }}</span>
                      <span class="color-93">余额：{{ userInfo.money }}</span>
                    </div>
                  </div>
                  <el-divider></el-divider>
                  <el-dropdown-item command="1" >
                    <div class="flex dritem">
                      <img src="../../assets/img/grzx.png" alt="" srcset="" class="imgs">
                      <span class="mg-lt-10">个人中心</span>

                    </div>
                  </el-dropdown-item>
                  <el-divider></el-divider>
                  <el-dropdown-item command="3">
                    <div class="flex dritem">
                      <img src="../../assets/img/tcdl.png" alt="" srcset="" class="imgs">
                      <span class="mg-lt-10">退出登录</span>

                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>

              </el-dropdown>
            </div>

          </div>
        </el-header>

        <el-main>
          <!-- //首页 -->
          <hoge v-if="accforshow == 1" @record="record"></hoge>
          <!-- //财务管理 -->
          <finannagem v-if="accforshow == 5 || accforshow == 6 || accforshow == 7" :finashow="accforshow"
            :hogefinanna="hogefinanna" @record="record"></finannagem>
          <!-- //订单管理 -->
          <ordnageme v-if="accforshow == 3" :hogeproduct="hogeproduct"></ordnageme>
          <!-- //发票管理 -->
          <invanageme v-if="accforshow == 4"></invanageme>
          <!-- //重置密码 -->
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>

<script>
import hoge from '../expensnter/components/hoge.vue'
import finannagem from '../expensnter/components/finannagem/index.vue'
import ordnageme from '../expensnter/components/ordnageme.vue'
import invanageme from '../expensnter/components/invanageme.vue'
export default {
  components: {
    hoge,
    finannagem,
    ordnageme,
    invanageme,
  },
  props: {
    msg: String
  },

  data() {
    return {
      accforshow: '1',
      finanoff: false,
      defaulct: "1",
      Tgmber: "",
      Laomber: "",
      hogeproduct: "",
      homgstatus: "",//首页跳过默认
      hogefinanna: "",//首页跳过默认类型
      userInfo:{},
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.Tgmber = this.userInfo.mobile.slice(0, 3)
    this.Laomber = this.userInfo.mobile.slice(7, 11)
    //从最外层首页跳转进来
    if (this.$route.query.expenhoew) {
      this.accforshow = this.$route.query.expenhoew
      this.defaulct = this.$route.query.expenhoew
    }
  },
  methods: {
    ropdcomma(value) {
      if(value=="1") {
        this.$router.push('/personter')
      } else if(value=="2") {
        this.$router.push('/expensnter')
      } else if(value==3){
        this.logout()
      }
    },
    //重新获取用户信息
    member() {
      this.$apis.member({
        mid: this.userInfo.id
      }).then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      })
    },
    logout() {
      this.$apis.logout({
        mid: this.userInfo.id
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push('/register')
        }
      })
    },
    record(data, index) {
      this.accforshow = String(data)
      this.defaulct = String(data)
      //CDN订单管理跳订单列表需要有筛选参数
      if (data == 3) {
        this.hogeproduct = index
      }
      if (data == 6) {
        this.hogefinanna = String(index)
      }
    },
    handleSelect(key) {
      this.accforshow = key
      this.defaulct = key
      if (key == 3) {
        this.hogeproduct = ""
      }
      if (key == 6 || key == 5 || key == 7) {
        this.hogefinanna = ""
      }
    },
    goHomg() {
      this.$router.push('/homage')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
  text-decoration: none;
}

.personame {
  width: 220px;

  //  padding: 0 10px;
  .dritem {
    padding: 0 10px;
  }

  .el-menu-item {
    padding: 0;
  }

  .imgs {
    width: 24px;
    height: 24px;
  }

  .el-divider {
    margin: 10px 0;
  }

  .shm {

    img {
      width: 50px;
      height: 50px;
    }
  }
}

.costimg {
  width: 20px;
  height: 20px;
}

.image-wrapper {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/fyzxsywxz.png") no-repeat left;
  background-size: 18px 18px;
}

/deep/ .is-active .image-wrapper {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/fyzxsyxz.png") no-repeat left !important;
  background-size: 18px 18px !important;
}

/deep/.el-menu-item-group {
  background-color: #f7f2f2 !important;
  font-size: 12px;
}

.image-smrz {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/cwglwxz.png") no-repeat left;
  background-size: 18px 18px;
}

/deep/.is-active .el-submenu__title {
  color: #409EFF !important;
}

// /deep/.is-opened .el-submenu__title {
//   color: #409EFF !important;
// }
/deep/ .is-active .image-smrz {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/cwglxz.png") no-repeat left !important;
  background-size: 18px 18px !important;

}

//  /deep/.is-opened .image-smrz {
//   position: relative;
//   width: 26px;
//   height:40px;
//   background: url("../../assets/img/cwglxz.png") no-repeat left !important;
//   background-size: 18px 18px !important;

//  }
.image-xxgg {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/ddglwxz.png") no-repeat left;
  background-size: 18px 18px;
}

/deep/ .is-active .image-xxgg {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/ddglxz.png") no-repeat left !important;
  background-size: 18px 18px !important;
}

.image-dltz {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/fpglwxz.png") no-repeat left;
  background-size: 18px 18px;
}

/deep/ .is-active .image-dltz {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/fpglxz.png") no-repeat left !important;
  background-size: 18px 18px !important;
}

.image-czmm {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/czmmwxz.png") no-repeat left;
  background-size: 18px 18px;
}

/deep/ .is-active .image-czmm {
  position: relative;
  width: 26px;
  height: 40px;
  background: url("../../assets/img/czmmxz.png") no-repeat left !important;
  background-size: 18px 18px !important;
}

// .el-icon-my-export{
//     font-size: 16px;
// }
// .el-icon-my-export:before{
//     content: "\e611";
// }
.menimg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

p {
  margin: 0;
}

.hogo {
  padding: 10px 0 8px 17px;
  background-color: #F5F5F5;
  justify-content: center;
}

.el-divider {
  margin: 10px 0;
}

.el-main {
  padding: 14px 9px;

  .mainbox {
    //  height: 373px !important;
    background-color: #fff;
    padding: 22px 17px 1px 17px;
  }

  .jb {
    font-size: 15px;
    color: #333333;
  }

  .essen {
    display: flex;
    align-items: inherit;
    margin-bottom: 20px;

    .labe {
      font-size: 14px;
      color: #666;
      // margin-right: 120px;
      width: 170px;
    }

    .usid {
      span {
        font-size: 16px;
        font-weight: bold;
        color: #71706F;
      }

      p {
        color: #A1A1A1;
        margin-top: 6px;
      }
    }
  }

  .reg {
    font-size: 80%;
    color: #666;
    margin: 35px 0 50px 0;
  }

  .acco {
    img {
      width: 24px;
      height: 24px;
    }

    span {
      margin: 0;
      font-size: 20px;
      margin-left: 12px;
    }
  }
}

/deep/.el-submenu__title {
  font-size: 16px;
}

.el-container {
  background-color: #E5E5E5;
}

// /deep/.el-submenu__icon-arrow {
//   display: none;
// }
/deep/.el-menu-item {
  font-size: 16px;
}

// /deep/.el-submenu__title:hover {
//   background-color: #E6F7FF  !important;
//   color: #4BA1FD !important;

// }
.lgo {
  width: 126px;
  height: 28px;
  margin-right: 10px;
}

.el-header {
  background-color: #F5F5F5;
  color: #101010;
  line-height: 60px;
  font-size: 14px;

  span {
    margin-left: 10px;
  }

  .heimg {
    width: 30px;
    height: 30px;
  }

  .sl {
    margin-right: 51px;
  }

  .hedr {
    display: flex;

    align-items: center;


    span {
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
    }
  }
}

.el-aside {
  color: #333;
  width: 242px !important;
  background-color: #FFFFFf !important;

  .asimg {}
}
</style>
