<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <h3 class="cz">￥账户充值</h3>
        <span>你的当前余额为：￥{{ money }}，上月消费￥{{ lastMonth }}，本月消费￥{{ thisMonth }}。<a @click="Vistory">查看充值记录</a></span>
        <div class="manner">
          <span class="xscz">线上充值</span>
          <el-divider></el-divider>
          <div class="modme flex">
            <span>支付方式</span>
            <div>
              <!-- <img src="@/assets/img/alipay.png" alt="支付宝" srcset=""> -->
              <img src="@/assets/img/wechatpay.png" alt="微信" srcset="">
            </div>
          </div>
          <div class="modme flex">
            <span>充值金额</span>
            <div>
              <el-input-number v-model="recharmoney" controls-position="right" :min="1" :max="200"
                :controls="false"></el-input-number>

              <span class="unit">元</span>
              <el-button type="primary" class="confarge" @click="wxRecharge">确认充值</el-button>
            </div>
          </div>
          <div class="modme flex al-baseline " v-show="payshow">
            <span>支付二维码</span>
            <div class="flex-column" style="align-items: baseline;">
              <span class="mot wte-nrap">请用微信客户端扫描二维码充值</span>
              <div>
                <div class="">
                  <div id="qrcode" ref="qrcode" class="erwma">
                  </div>
                  <span class="text-cer exo">￥{{ recharmoney }}</span>
                </div>

                <!-- <img src="@/assets/img/erweima.png" alt="" srcset="" class="erwma"> -->

              </div>

            </div>


          </div>
        </div>
        <div class="offance">
          <span class="xscz">线下汇款</span>
          <el-divider></el-divider>
          <div class="modme flex">
            <span>开户行</span>
            <div class="f-bold font-16">
              <span>{{ bankName }}</span>
            </div>
          </div>
          <div class="modme flex">
            <span>户名</span>
            <div class="f-bold font-16">
              <span>{{ bankCompany }}</span>
            </div>
          </div>
          <div class="modme flex al-baseline ">
            <span>充值金额</span>
            <div class="f-bold font-16">
              <el-input v-model="form.money" placeholder="输入充值金额" :min="1" :max="200" @change="moneyChange"></el-input>
            </div>


          </div>
          <div class="modme flex al-baseline ">
            <span>账号</span>
            <div class="f-bold font-16">
              <span>{{ bankNo }}</span>
            </div>


          </div>
          <div class="modme flex  al-start">
            <span>转账截图</span>

            <div class="font-12 color-999 flex-column  al-baseline">

              <div class=" flex">
                <div class="uplod">
                  <el-upload class="avatar-uploader" action="https://admin.znidc.net/wxapi/upload/file"
                    :show-file-list="false" :on-success="uploadFilerech">
                    <img v-if="form.voucher" :src="form.voucher" class="avatar">
                    <div v-else class="uplbox">
                      <div class="uplhnimg">
                        <img src="@/assets/img/upsc.png" alt="" srcset="">
                      </div>

                    </div>

                  </el-upload>
                  <p class="fron">汇款截图</p>
                </div>
                <label class="legin">1.为了您的合法权益，转账时请备注您的账号（Uid 或注册手机号）。<br />
                  2.银行转账会需要至少 3 个工作日到账，期间请耐心等待，如遇到问题请联系销售或者技术支持，反馈问题或者咨询进度。<br />
                  3.企业客户汇款时，请通过公司的银行账户汇款，并保持和开具发票的公司名称一致，这样可以确保您能顺利地开具发票。</label>
              </div>

            </div>

          </div>
          <div class="Btn" @click="offLineRecharge">
            <el-button type="primary" :disabled="btndied">提交</el-button>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  components: { QRCode },
  props: {
    msg: String
  },
  data() {
    return {
      payshow: false,//支付盒子
      btndied: false,
      codeurl: "",//回调url
      qrcode: "",
      userInfo: {},
      time: "",
      form: {
        money: "",
        bankNo: "",
        bankCompany: "",
        bankName: "",
        voucher: "",
      },
      lastMonth: "",
      money: "",
      recharmoney: "",
      thisMonth: "",
      bankCompany: "",
      bankName: "",
      bankNo: "",
      outTradeNo: "",//支付ID
      topIn: "",
      xRechaset: "",
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getBalanceTotal()
    this.getRechargeConfig()
  },
  beforeDestroy() {
    clearInterval(this.time)
    clearInterval(this.topIn)
    clearInterval(this.xRechaset)
  },
  methods: {
    Vistory() {
      this.$emit('Vstory', 6, '0') //跳转到收支明细
    },
    moneyChange() {
      if (this.form.money < 0) {
        this.form.money = 1
      }
    },
    getRechargeConfig() {
      this.$apis.getRechargeConfig().then((res) => {
        this.bankCompany = res.data.bankCompany
        this.bankName = res.data.bankName
        this.bankNo = res.data.bankNo
      })
    },
    //本月消费
    getBalanceTotal() {
      this.$apis.getBalanceTotal({
        mid: this.userInfo.id
      }).then((res) => {
        this.thisMonth = res.data.thisMonth
        this.money = res.data.money
        this.lastMonth = res.data.lastMonth
      })
    },
    offLineRecharge() {
      if (!this.btndied) {
        this.$apis.offLineRecharge({
          money: this.form.money,
          voucher: this.form.voucher,
          mid: this.userInfo.id
        }).then((res) => {

          if (res.code == 0) {
            this.btndied = true
            this.$message({
              type: "success",
              message: "提交成功，一分钟后可以再次提交"
            })
            this.form = {
              money: "",
              bankNo: "",
              bankCompany: "",
              bankName: "",
              voucher: "",
            },
              this.time = setInterval(() => {
                this.btndied = false
              }, 60000)
          } else {
            this.$message({
              type: "success",
              message: res.msg
            })
          }


        })
      }

    },
    getRechargeStatus() {
      this.$apis.getRechargeStatus({
        outTradeNo: this.outTradeNo
      }).then((res) => {
        if (res.code == 0) {
          if (res.data == 1) {
            this.$message({
              type: "success",
              message: '充值成功'
            })
            clearInterval(this.topIn) 
            clearInterval(this.xRechaset) 
            this.Vistory()
          } else {
            this.topIn = setInterval(() => {
              this.getRechargeStatus()
            }, 15000)
          }
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }

      })
    },
    wxRecharge() {
      let that = this

      this.$apis.wxRecharge({
        mid: this.userInfo.id,
        money: this.recharmoney
      }).then((res) => {
        const codeHtml = document.getElementById("qrcode");
        codeHtml.innerHTML = ""
        that.codeurl = res.data.package.slice(9);
        this.outTradeNo = res.outTradeNo
        this.$nextTick(() => {
          let qrcode = new QRCode("qrcode", {
            width: 200,
            height: 180,
            text: that.codeurl, // 二维码地址
            colorDark: "#000",
            colorLight: "#fff",
          })

          this.payshow = true
          this.xRechaset = setTimeout(() => {
            this.getRechargeStatus()
          }, 15000)
        })



      })
    },
    uploadFilerech(item) {
      this.form.voucher = item.data
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.confarge {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background-color: rgba(40, 110, 250, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  line-height: 0;
  margin-left: 20px;
  border: none;
  padding: 0 !important;
}

p {
  margin: 10px 0;
}

.offance {}

.manner {
  margin-top: 35px;


}

.recar {
  width: 100%;
  height: 100%;
}

.xscz {
  font-size: 15px;
  color: #333;
}

/deep/.el-input__inner {
  height: 30px !important;
}

.reacrmain {
  padding: 0 20px;
  padding-top: 10px;
  padding-bottom: 14px;
  // height: 962px;
  background-color: #fff;

  .fron {

    color: rgba(16, 16, 16, 1) !important;
    font-size: 14px !important;
    text-align: center;
  }

  .Btn {
    text-align: left;
    margin-top: 20px;
    margin-left: 150px;

    /deep/.el-button--primary {

      width: 138px;
      height: 41px;
      border-radius: 4px;
      background-color: rgba(125, 189, 247, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: center;
      font-family: Microsoft Yahei;
      border: none;
      margin-top: 20px;
    }
  }

  .uplod {
    margin-right: 11px;
    width: 167px;

    .uplbox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #E8E8E8;
      width: 167px;
      height: 100px;

      .uplhnimg {
        width: 60px;
        height: 60px;
        line-height: 70px;
        background-color: rgba(0, 0, 0, 0.4);
        text-align: center;
        border-radius: 50%;

        img {
          width: 24px;
          height: 24px;
          margin: 0 !important;
        }
      }
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 167px;
      height: 100px;
      display: block;
    }

  }

  /deep/.el-input__inner {
    text-align: left;
  }

  .cz {
    font-weight: 400;
    font-size: 24px;
    color: #333;
    margin-bottom: 35px;
    margin-top: 0;
  }

  a {
    text-decoration: none;
    color: #007BFF;
  }

  .modme {
    margin-bottom: 20px;

    .legin {
      line-height: 20px;
      margin-bottom: 40px;
    }

    .exo {
      width: 100%;
      text-align: center;
      font-family: Arial;
      font-weight: bold;
      color: orange;
      font-size: 24px;
      margin-top: 5px;
      display: block;
    }

    .mot {
      font-size: 12px;
      color: #999;

    }

    .erwma {

      margin: 10px 0;
    }

    .unit {
      -ms-flex-align: center;
      align-items: center;
      padding: 8px 13px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }

    img {
      width: 80px;
      height: 25px;
      margin-right: 15px;
    }

    span {
      max-width: 180px;
      width: 140px;
      font-size: 14px;
    }
  }
}
</style>
