<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <h3 class="cz flex">
          <img src="@/assets/img/sxyurwxz.png" alt="" srcset="" class="hedimg">
          <span> 刷新预热</span>

        </h3>
        <div class="">
          <div class="deion">
            <span>刷新缓存</span>
            <el-divider></el-divider>       
          </div>
          <div class="selmain">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择操作">
                <div class="flex essen">
                  <div class=" usid">
                    <el-button :class="form.taskType == 0 ? 'pers' : ''" @click="form.taskType = 0">
                      <div class="flex">
                        <span class="gr">URL刷新</span>
                      </div>
                    </el-button>
                    <el-button :class="form.taskType == 1 ? 'pers' : ''" @click="form.taskType = 1">
                      <div class="flex">
                        <span class="gr">目录刷新</span>
                      </div>
                    </el-button>
                    <el-button :class="form.taskType == 2 ? 'pers' : ''" @click="form.taskType = 2">
                      <div class="flex">
                        <span class="gr">URL预热</span>
                      </div>
                    </el-button>
                  </div>

                </div>
                <div v-show="form.taskType == 0">
                  <p>如需进行整站刷新，请选择目录刷新，然后填入结尾包含/的首页地址进行刷新即可。</p>
                  <p>由于系统无法知道你源站的目录结构，所以预热仅支持 URL 预热，无法预热目录。</p>
                  <p>每日刷新 URL 上限 1000，刷新目录上限 20，预热上限 1000。刷新任务生效时间大约为 5 分钟。</p>
                </div>
                <div v-show="form.taskType == 1">
                  <p>CDN节点的缓存不定时更新，当您的源站内容更新后，需要用户获取到最新的资源，可以通过提交刷新任务;</p>
                  <p>大批量的刷新推送可能会引发高并发回源，如果源站出口带宽较小，建议分多次小批量操作;</p>
                  <p>每条URL一行（回车换行）一次最多50行，并注意区分URL中的字母的大小写，本功能自动推送子目录，无需填写下级目录，刷新任务一般5~10分钟生效。</p>
                  <p> 域名默认不区分协议缓存，目录推送需统一提交http协议目录才能生效；如域名配置区分协议缓存，需按实际刷新需求分别提交目录刷新。</p>
                </div>
                <div v-show="form.taskType == 2">
                  <p>预取功能可以将指定的资源主动预热到CDN节点上，用户首次访问即可直接命中缓存；</p>
                  <p>大批量的文件预取可能会引发高并发回源，如果源站出口带宽较小，建议分多次小批量操作;</p>
                  <p>每条URL一行（回车换行）一次最多50行，并注意区分URL中的字母的大小写，预取任务一般5~30分钟生效。</p>
                </div>
              </el-form-item>
              <el-form-item label="URL" class="certifica">
                <el-input type="textarea" v-model="form.url"
                  placeholder="请在此输入需要操作的目录或 URL(需要http://或https:/N)，一行一个" @change="urlChange"></el-input>
                <div >
                  <el-button type="primary" class="submit" @click="addRefresh">提交
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>

          <div>
            <el-table :data="RefreshList" style="width: 100%" empty-text="暂无记录( 只保留最近 30 天的记录)...">
              <el-table-column prop="url" label="URL">
              </el-table-column>
              <el-table-column prop="createTime" label="操作时间" align="center">
              </el-table-column>
              <el-table-column prop="produme" label="状态" align="center">
                <template slot-scope="scope">
                  {{ scope.row.status == 'processing' ? '进行中' : scope.row.status == 'completed' ? '成功' : '进行中' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentpage"
            :page-sizes="[10, 20, 30, 40]" :page-size="limit" layout=" sizes, prev, pager, next, jumper" :total="total"
            class="flex just-center  mg-tp-23">
          </el-pagination>
        </div>


      </div>
    </div>
    <!-- //发票信息设置 -->

    <!-- //添加地址 -->

  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
      form: {
        url: "",
        taskType: "0",
      },
      currentpage: 0,

      tableData: [

      ],

      userInfo: {},
      offset: 0,
      limit: 10,
      total: 0,
      RefreshList: [],
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getRefreshList()
  },
  methods: {
    getRefreshList() {
      this.$apis.getRefreshList({
        mid: this.userInfo.id,
        offset: this.offset,
        limit: this.limit
      }).then((res) => {
        this.RefreshList = res.data.rows
        this.total = res.data.total
      })
    },
    addRefresh() {
      this.$apis.addRefresh({
        mid: this.userInfo.id,
        taskType: this.form.taskType,
        url: this.form.url
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "添加成功"
          })
          this.getRefreshList()
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
      })
    },
    urlChange() {
      let arr = this.form.url.split('\n')
      let arrmain = []
      let arrerror = []
      arr.forEach((el)=>{
        const url = this.VeriURL(el)
        if(url) {
          arrmain.push(url)
        } else {
          arrerror.push(el)
        }
      })
      if(arrerror.length>0) {
        this.$message({
          type:"error",
          message:"请输入正确域名"
        })
        return
      }
      this.form.url = arrmain.join('\n')
    },
    VeriURL(url) {
      const urlregex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      if(!urlregex.test(url)) {
       return ''
      } else {
        return url
      }
    },
    handleCurrentChange(val) {
      this.currentpage = val
      this.offset = (val - 1) * this.limit
      this.getRefreshList()
    },
    handleSizeChange(val) {
      this.limit = val
      this.getRefreshList()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-pager {
  font-size: 12px;

  .active {
    color: #fff !important;
    background-color: #007BFF;
    border: none !important;
  }

  li {
    padding: 0 10px;
    min-width: 30px
  }

  .number {
    color: #007BFF;
    border: 1px solid #dadbdb;
  }
}

/deep/.el-form-item__content {
  margin-left: 120px !important;
}

/deep/.el-form-item__label {
  text-align: left;
}

.submit {
  padding: 5px 10px;
  background-color: #4272D7;
  border: none;
}

.certifica {
  /deep/.el-form-item__label {
    line-height: 10px;
  }
}

/deep/.el-textarea__inner {
  height: 150px;
}

/deep/.el-form {
  p {
    line-height: 15px;
    font-size: 12px;
    color: #C0BAC2;
  }
}

.pers {
  background: url('../../../assets/img/xzl.png') no-repeat right top;
  background-size: 20px 20px;
  border-color: #007bff !important;
}

.essen {
  display: flex;
  align-items: inherit;

  .labe {
    font-size: 14px;
    color: #666;
    // margin-right: 120px;
    width: 170px;
    margin-top: 10Px;
  }

  .usid {
    /deep/.el-button {
      padding: 10px;
    }

    span {
      color: rgba(102, 102, 102, 1);
      font-size: 12px;
    }

    p {
      color: #A1A1A1;
      margin-top: 6px;
    }
  }
}

.selmain {
  margin: 20px 0 10px 0;

  /deep/.el-input__inner {
    height: 30px;
    // width: 300px;
  }

  span {
    font-size: 14Px;
  }

  color: #666666;
}

.hedimg {
  width: 25px;
  height: 25px;
}

.deion {

  font-size: 12px;
  font-weight: 400;

  /deep/.el-divider {
    margin: 10px 0;
  }

  h4 {
    margin: 0;
  }
}






.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 800;
  }


}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}



.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
  }
}







// /deep/.el-input__inner {

//   width: 200px;
//   height: 30px;
//   line-height: 17px;
//   color: rgba(16, 16, 16, 1);
//   font-size: 12px;
//   border: 1px solid rgba(187, 187, 187, 1);
// }
</style>
