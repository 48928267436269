<template>
  <div class="w-100 h-100">


    <div class="recar">
      <div class="reacrmain">

        <div>
          <div class="flex">
            <div class="sjfw">
              <el-select v-model="value" placeholder="请选择加速类型" clearable >
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sjfw">
              <el-select v-model="typeval" placeholder="请选择域名" clearable >
                <el-option v-for="item in optionstype" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sjfw">
              <el-select v-model="status" placeholder="请选择区域" clearable >
                <el-option v-for="item in optionsarea" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sjfw">
              <el-input v-model="input" placeholder="请输入状态码"></el-input>
            </div>
            <div class="flex flow sjfw">
              <el-tabs v-model="flux" type="card" @tab-click="handleClick" class="tretab">
                <el-tab-pane label="流量优先" name="trafiority"></el-tab-pane>
                <el-tab-pane label="访问次数优先" name="trafiority2"></el-tab-pane>
              </el-tabs>
            </div>
            <div class="flex flow">
              <el-tabs v-model="acdomes" type="card" @tab-click="handletime" class="tretab">
                <el-tab-pane label="今天" name="first1"></el-tab-pane>
                <el-tab-pane label="昨天" name="second2"></el-tab-pane>
                <el-tab-pane label="自定义" name="second3"></el-tab-pane>
              </el-tabs>
              <el-date-picker v-model="value1" type="date" :clearable=false :editable=false ref="picker"  :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
              <el-button type="primary" class="inquire">查询</el-button>
            </div>
          </div>
          <div>
            <el-table :data="tableData" style="width: 100%" empty-text="暂无数据...">
              <el-table-column prop="serial" label="排行" width="80">
              </el-table-column>
              <el-table-column label="URL" width="770">

              </el-table-column>
              <el-table-column prop="Acceler" width="140" label="流量">
              </el-table-column>
              <el-table-column prop="Acceler" label="流量占比(%)" width="140">
              </el-table-column>
              <el-table-column prop="Accel" label="访问次数" width="190">
              </el-table-column>
              <el-table-column label="访问占比(%)" width="140">

              </el-table-column>

            </el-table>
          </div>
        </div>

      </div>
    </div>
    <!-- //购买证书 -->

  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
      value: '',
      value1: "",
      acdomes: "first1",
      flux: "trafiority",
      ruleForm: {
        name: '',
        region: 'CDN加速',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        http: 80,
        https: 443
      },
      typeval: "",
      status: "",
      purwer: false,
      tableData: [

      ],
      num: 1,
      input: "",
      optionsarea: [{
        value: '1',
        label: '中国内地'
      },
      {
        value: '2',
        label: '全球不含中国内地'
      },
      ],
      optionstype: [{
        value: '1',
        label: 'cdn3.znidc.net'
      },
      {
        value: '2',
        label: 'cdn.znidc.net'
      },
      {
        value: '3',
        label: 'cdn2.znidc.net'
      },
      {
        value: '4',
        label: 'down.ggzuhao.com'
      },

      ],
      options: [{
        value: '1',
        label: 'CDN加速'
      },

      ],
    }
  },
  mounted() {
  },
  methods: {
    comPile() {
      this.$emit('comPile')
    },
    handletime() {
      if (this.acdomes == 'second3') {
        this.$refs.picker.focus()
      }
    },
    handleAvatarSuccess() {

    },
    beforeAvatarUpload() {

    },
    handleChange(value) {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/deep/.el-table {
  overflow-x: scroll;
}
/deep/.el-date-editor {
  position: absolute;
  z-index: 1;
  right: -50px;
  border: none;

  .el-input__icon {
    display: none;
  }

  .el-range-separator {
    display: none !important;
  }

  .el-input__inner {
    border: none;
    color: #fff;
    width: 0;
    background-color: transparent;
  }

  .el-input__prefix {
    display: none;
  }
}

.inquire {
  border-radius: 4px;
  background-color: rgba(22, 132, 252, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  padding: 5px 10px;
  margin-left: 20px;
  border: none;
}

/deep/.el-tabs {
  margin-left: 20px;
}

/deep/.el-tabs__nav-scroll {
  padding: 0 !important;
}

/deep/.el-tabs__item {
  width: 70px !important;
  height: 30px !important;
}

.flow /deep/.el-tabs__nav .is-active {
  color: #fff !important;
  background-color: #1684FC !important;
  border: none !important;
}

/deep/.el-tabs__nav {
  border: none;
  border-radius: 3px;
}

.flow /deep/.el-tabs__nav .el-tabs__item {
  width: 65px;
  height: 25px;
  line-height: 30px;
  padding: 0 !important;
  color: #000000;
  border-color: #3695FC;
  text-align: center;
  border: 1px solid #EAEAEA;
  // border: none;
}

/deep/.el-tabs__header {
  border: none;
  margin: 0;
}

/deep/.eForm {

  margin-top: 20px;

  .port {
    span {
      margin-right: 7px;
    }
  }

  .hps {
    margin-left: 80px;
  }

  .htinput {
    .el-input__inner {
      width: 80px !important;
    }
  }

  .yuanz {
    .selctcj {
      margin-left: 3px;
    }
  }

  .el-form-item__content {
    margin-left: 80px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-form-item__label {
    text-align: left;
    width: 80px;
  }

  a {
    color: #4249F1;
  }

  // .el-input__icon {
  //   line-height: 50px;
  // }

  .el-radio-group {
    display: flex;
    align-items: center;
    margin-top: 13px;

    .el-radio__label {
      font-size: 12Px;
    }

    .el-radio {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    // margin-top: 5px;
  }


}

/deep/.el-input__inner {
  width: 150px !important;
  border-radius: 0;
}



.shuxian {
  width: 1px;
  height: 10px;
  background-color: #666666;
  display: inline-block;
  margin: 0 3px;
}

.tabimg {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}


.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 400;
  }

}

/deep/.el-table thead {
  color: #666666;
  font-weight: bold;
  font-size: 20px !important;
}

/deep/.el-table__body-wrapper {
  .tabthre {
    width: 15px;
    height: 15px;
  }

  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}





.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 10px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }

  /deep/.el-input__suffix {
    top: 6px !important;
  }
}



.el-button {
  padding-top: 10px;
  padding-bottom: 10px;
}</style>
