<template>
  <div class="">
    <div class="main">
      <div class="forpa">
        <span>忘记密码</span>
      </div>
      <div class="Baogin" @click="Bagin">
        <span>返回登录</span>
      </div>

      <div>
        <el-steps :active="active" finish-status="success">
          <el-step class="elone"></el-step>
          <el-step class="eltow"></el-step>
          <el-step class="elthr"></el-step>
        </el-steps>

      </div>
      <div class="proc">
        <span :style="active==0?'color:#05040F':''">验证手机号</span>
        <span :style="active==1?'color:#05040F':''">设置新密码</span>
        <span :style="active==2?'color:#05040F':''">重置成功</span>
      </div>
      <!-- //输入手机号和验证码 -->
      <div class="inputbox" v-show="active==0">
        <div>
          <el-input placeholder="请输入手机号" v-model="mobile">
            <div slot="prefix">
              <img src="../../../assets/img/shouji.png" alt="" srcset="" class="sjinput">
            </div>
          </el-input>
        </div>
        <div class="yzmipt">
          <el-input placeholder="输入验证码" v-model="code">
            <div slot="prefix">
              <img src="../../../assets/img/duanxin.png" alt="" srcset="" class="sjinput">
            </div>
            <div slot="suffix" class="Encode curesor" @click="sendPassWordVerifyCode">
              <span>获取验证码</span>
            </div>
          </el-input>
        </div>
        <el-button type="primary" @click="veriFep">下一步</el-button>
      </div>
      <!-- //输入密码 -->
      <div class="inputbox" v-show="active==1">
        <div>
          <el-input placeholder="请输入新密码" v-model="password" show-password>
            <div slot="prefix">
              <img src="../../../assets/img/xinmima.png" alt="" srcset="" class="sjinput">
            </div>
          </el-input>
        </div>
        <div class="yzmipt">
          <el-input placeholder="请再次输入密码" v-model="newpassword" show-password>
            <div slot="prefix">
              <img src="../../../assets/img/xinmima.png" alt="" srcset="" class="sjinput">
            </div>
          </el-input>
        </div>
        <el-button type="primary" @click="forgetPassword">下一步</el-button>
      </div>
      <div class="inputbox" v-show="active==2">
        <div>
          <img src="../../../assets/img/zhongzhiwanc.png" alt="" srcset="" class="chongzhi">
        </div>
        <el-button type="primary" @click="Enssw">完成</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      active: 0,
      input1:"",
      mobile:"",
      code:"",
      password:"",
      newpassword:"",
    }
  },
  methods:{
    forgetPassword() {
      if(this.password!==this.newpassword) {
        this.$message({
          message:'2次输入密码不一致',
            type:"warning"
        })
        return
      }
      this.$apis.forgetPassword({
        mobile:this.mobile,
        code :this.code,
        password:this.password,
      }).then((res)=>{
        if(res.code==0) {
          this.active=2
        } else {
          this.$message({
          message:res.msg,
            type:"error"
        })
        }
   
      })
    },
    sendPassWordVerifyCode() {
      this.$apis.sendPassWordVerifyCode({
        mobile:this.mobile
      }).then((res)=>{
          if(res.code==0) {
            this.$message({
              type:"success",
              message:"获取验证码成功"
            })
          } else {
            this.$message({
              type:"error",
              message:res.msg
            })
          }
      })
    },
    veriFep() {
      if(this.mobile&&this.code) {
          this.active=1
      } else {
        this.$message({
          message:'请输入手机号或者验证码',
            type:"warning"
        })
      }
    },
    Enssw() {
      this.$emit('Bagin')
    },
    Bagin() {
      this.$emit('Bagin')
    },
  }
}
</script>
  
<style scoped lang="scss"> 
.curesor {
  cursor: pointer;
}
.main {
   width: 670px;
   height: 412px;
   border-radius: 10px;
   background-color: rgba(255, 255, 255, 1);
   text-align: center;
   box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
   padding: 17px 20px 0 19px;
   /deep/.el-steps {
    padding: 0 20px;
    margin-top: 17px;
   }
  .inputbox {
    margin-top: 31px;
    .yzmipt {
      margin-top: 23px;
    }
    .sjinput {
     margin-top: 13px;
   }

   .Encode {
     margin-top: 10px;
     color: rgba(24, 145, 255, 1);
     font-size: 14px;
   }
  }
  .chongzhi {
    width: 83px;
    height: 83px;
    margin-top: 45px;
  }
  .proc {
    display: flex;
    justify-content: space-between;
    color: #B3BAC1;
   margin-top: 10px;
  }
  /deep/.el-button--primary {
width: 383px;
height: 40px;
border-radius: 8px;
background-color: rgba(108, 116, 247, 1);
color: rgba(255, 255, 255, 1);
font-size: 16px;
text-align: center;
border: none;
margin-top: 23px;
  }
   /deep/ .el-input__inner {
     width: 378px;
     height: 40px;
     line-height: 20px;
     border-radius: 8px;
     background-color: rgba(255, 255, 255, 1);
     color: #333;
     font-size: 14px;
     font-family: Roboto;
     border: 1px solid rgba(220, 224, 231, 1);
   }

   /deep/.el-input {

     width: 378px;
   }

   .forpa {
     color: rgba(16, 16, 16, 1);
     font-size: 18px;
     text-align: left;
   }

   .Baogin {
     text-align: right;
     color: rgba(24, 145, 255, 1);
     font-size: 14px;
     cursor: pointer;
   }

   .elone {
     text-align: left;
   }

   .eltow {
     text-align: initial;
   }

   .elthr {
     text-align: initial;
   }

   /deep/.el-step__icon-inner {
     display: none;
   }
   /deep/.is-success .is-text {
    background-color: #6C74F7;
    border: none;
   }
   /deep/.is-process .is-text {
     background-color: #6C74F7;
     border: none;
   }

   /deep/.is-process .el-step__line {
     background-color: #6C74F7;
   }
   /deep/.is-success  {
    border-color: #6C74F7;
   }
   /deep/.is-success .el-step__line {
    background-color: #6C74F7;
   }
 }</style>
  