<template>
  <div class="w-100 h-100">
    <div class="recar">
      <div class="reacrmain">
        <div class="flex just-bet">
          <h3 class="cz flex">
            <img src="@/assets/img/zsglwxzd.png" alt="" srcset="" class="hedimg">
            <span> 证书管理</span>

          </h3>
         
        </div>

        <div class="">


          <div>
            <el-table :data="tableData" style="width: 100%" empty-text="还没有上传证书...">
              <el-table-column prop="ordenum" label="ID" width="200" align="center">
              </el-table-column>
              <el-table-column prop="product" label="备注名" width="300" align="center">
              </el-table-column>
              <el-table-column prop="produme" label="通用名称" width="300" align="center">
              </el-table-column>
              <el-table-column prop="type" label="类型" width="200" align="center">
              </el-table-column>
              <el-table-column prop="orcreattime" label="到期时间" width="200" align="center">
              </el-table-column>
              <el-table-column prop="ortiontime" label="绑定域名数" width="200" align="center">
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <div>
            <el-button type="primary" class="purcha" @click="dackVisible = true">下载证书</el-button>
          </div>
              </el-table-column>
            </el-table>
          </div>

        </div>


      </div>

    </div>
   
  </div>
</template>

<script>
export default {

  props: {
    msg: String
  },
  data() {
    return {
    
      vopt: '',
      value: '',
      tableData:[]



    }
  },
  mounted() {
  },
  methods: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


/deep/.el-table {
  overflow-x: scroll;
}

/deep/.el-progress {
  // width: 230px !important;
  // margin-left: 20px;
}

/deep/.el-progress-bar__innerText {
  color: #fff !important;
}

.dag {
  margin-top: 18px;

  p {
    font-size: 14px;
    letter-spacing: 0;
    color: #666666;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}

.purcha {
  background: #4272d7;
  border: none;
  line-height: 0px;
  font-size: 14px;
  padding: 13px 15px;
  border-radius: 0;
  margin-left: 10px;
}

.selmain {
  margin: 20px 0 10px 0;

  /deep/.el-input__inner {
    height: 30px;
    // width: 300px;
  }

  /deep/.el-input__icon {
    line-height: 40px;
  }

  span {
    font-size: 14Px;
  }

  color: #666666;
}

.hedimg {
  width: 25px;
  height: 25px;
}







.deion {
  color: #004085;
  background-color: #CCE5FF;
  border-color: #b8daff;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 400;

  h4 {
    margin: 0;
  }
}

.alert {
  span {
    width: 56px;
    height: 50px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin-right: 54px;
  }
}

.appbton {
  padding: 0;
  width: 59px;
  height: 30px;
  line-height: 0;
  border-radius: 4px;
  background-color: rgba(95, 165, 242, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
}

.tabdelt {
  width: 32px;
  height: 34px;
}

.tabjzst {
  width: 25px;
  height: 25px;
}

.recar {
  width: 100%;
  background-color: #fff;

  .reacrmain {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 40px;

    h3 {
      margin: 0;
    }
  }

  /deep/.el-table__empty-text {
    font-size: 12px;
  }
}

/deep/.el-table__header-wrapper {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ccc;

  .cell {
    font-size: 12px;
    font-weight: 800;
  }

  .has-gutter {
    color: #666666;
  }
}

/deep/.el-table__body-wrapper {
  .cell {
    font-size: 12px;
    font-weight: 400;
  }
}

.colle {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 20px 15px 15px 15px;
}

.cz {
  font-weight: 400;
  font-size: 24px;
  color: #333;
  // margin-bottom: 35px;
  margin-top: 0;

  span {
    margin-left: 10px;
    font-weight: bold;
  }
}

.chars {
  margin-right: 15px;
}

.jylx {
  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}

.sjfw {
  margin-right: 20px;

  span {
    color: rgba(16, 16, 16, 1);
    font-size: 12px;
  }
}</style>
